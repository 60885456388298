import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { Card, Col, Container, FormGroup, Nav, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import { mapShippingZoneResponseToTable } from "../../../utils/mapper";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteShippingZone,
  getAllSellers,
  getCurrentSellerId,
  getShippingZoneData,
  selectShippingZones,
  syncShippingZone,
} from "../store/shippingZoneSlice";
import Select from "react-select";
import { Form } from "react-bootstrap";
import PreLoader from "../../../share-components/ui/preLoader";

const dataMenu = [
  {
    type: 0,
    text: "Seller",
    link: "",
  },
  {
    type: 1,
    text: "Seller Shipping Zone",
    link: "",
  },
];

const tableColumns = [
  {
    name: "Zone Name",
    selector: (row) => row.name,
    sortable: true,
    left: true,
  },
  {
    name: "Seller Name",
    selector: (row) => row.seller_name,
    sortable: true,
    left: true,
  },
  {
    name: "Country Count",
    selector: (row) => row.country_count,
    sortable: true,
    left: true,
  },
];

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 50,
    border: "none",
  }),
};

const ShippingZone = () => {
  const currentUser = useSelector(({ authReducer }) => authReducer.data.user);
  const dispatch = useDispatch();
  let history = useHistory();

  const [seller, setSeller] = useState([]);
  const [name, setName] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [sellerVisibility, setSellerVisibility] = useState(true);
  const [sellerName, setSellerName] = useState([]);
  const [loading, setLoading] = useState(true);
  const shippingZone = useSelector(selectShippingZones);

  useEffect(() => {
    const data = {
      sellerName,
      name,
    };
    dispatch(getCurrentSellerId(currentUser.id)).then((res) => {
      if (!res.error) setLoading(false);
      setSeller(res.payload);
      dispatch(getShippingZoneData(data));
    });
    dispatch(getAllSellers()).then((result) => {
      if (!result.error) setLoading(false);
      setSellerList(result.payload);
      setSellerVisibility(true);
    });
  }, [dispatch, sellerName, name]);

  const onAdd = () => {
    history.push("shipping-zone/add");
  };

  const onEdit = (row) => {
    history.push("shipping-zone/" + row.id + "/edit");
  };

  const onDelete = (row) => {
    const data = {
      id: row.id,
      seller_id: seller,
    };
    dispatch(deleteShippingZone(data)).then((res) => {
      if (!res.errors) dispatch(getShippingZoneData(seller));
    });
  };

  const onSync = () => {
    setLoading(true);
    dispatch(syncShippingZone()).then((result) => {
      if (!result.error) {
        setLoading(false);
        dispatch(getShippingZoneData({ sellerName, name }));
      }
    });
  };

  return (
    <Fragment>
      <Container fluid={true} className="product-wrapper">
        {loading ? (
          <>
            <PreLoader></PreLoader>
          </>
        ) : (
          <>
            <Breadcrumb data={dataMenu} title="Shipping Zone" />
            <div className="product-grid">
              <div className="feature-products">
                <Row>
                  <Col sm="12">
                    {currentUser.permission.includes("Create Shipping Zone") ? (
                      <Card>
                        <Row>
                          <Col sm="6">
                            <Nav tabs className="border-tab"></Nav>
                          </Col>
                          {/*<Label className="form-label">Seller Name</Label>*/}
                          <Col sm="6">
                            <div className="float-sm-right p-3">
                              <a
                                className="btn btn-sm btn-success"
                                onClick={onSync}
                              >
                                {" "}
                                <i className="fa fa-refresh"></i>{" "}
                                &nbsp;&nbsp;Sync Shipping Zone
                              </a>
                            </div>
                            {/* <div className="float-sm-right p-3">
                                                <a className="btn btn-sm btn-primary"
                                                   onClick={onAdd}
                                                > <i className="fa fa-plus"></i> &nbsp;&nbsp;Add Shipping Zone</a>
                                            </div> */}
                          </Col>
                        </Row>
                      </Card>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" xl="3">
                    <Select
                      className="select2-filter"
                      getOptionLabel={(seller) =>
                        `${seller.name} - ${seller.shop_name}`
                      }
                      getOptionValue={(seller) => seller.id}
                      isClearable
                      onChange={(e) => {
                        setSellerName(e ? e.id : null);
                      }}
                      styles={customStyles}
                      options={sellerList}
                    />
                  </Col>
                  <Col sm="12" xl="9">
                    <Form>
                      <FormGroup>
                        <input
                          onChange={(e) => setName(e.target.value)}
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Search By Name"
                        />
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <div className="table-responsive product-table mt-4">
                      <CommonDataTable
                        headerColumns={tableColumns}
                        gridData={mapShippingZoneResponseToTable(shippingZone)}
                        pagination
                        onEdit={
                          currentUser.permission.includes(
                            "Update Shipping Zone"
                          )
                            ? onEdit
                            : ""
                        }
                        onDelete={
                          currentUser.permission.includes(
                            "Delete Shipping Zone"
                          )
                            ? onDelete
                            : ""
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </>
        )}
      </Container>
    </Fragment>
  );
};

export default ShippingZone;
