import React, {useEffect, useState} from 'react';
import {Col, Input, Nav, NavItem, NavLink, Row} from "reactstrap";
import Accordion from 'react-bootstrap/Accordion';
import Select from "react-select";
import {getSubCategories, setProductFilter, setProductSubCategory} from "../../store/prodcutSlice";
import {useDispatch} from "react-redux";
import InputRange from 'react-input-range';
import CustomInputRange from "../../../../share-components/ui/customInputRange";
import ScrollArea from 'react-scrollbar';
import {CheckCircle, Delete, Info, MoreHorizontal, RefreshCcw, Target} from "react-feather";
import {DateRange} from "../../../../constant";
import DateRangePicker from "../../../../share-components/ui/DateRange";
import moment from "moment/moment";
import {convertDateFormat, dateFormat} from "../../../../utils/common";
import DatePicker from '../../../../share-components/ui/DateRangePicker';
import "../../../../assets/scss/components/accordian.scss"


function ProductFilter(props) {
    const dispatch = useDispatch();
    const {productState, customStyles,clearSelectAll} = props;
    const productSettings = productState.productIndexSettings;




    document.addEventListener("mousedown", (event) => {
        const concernedElement = document.querySelector(".customizer-contain");
            if(concernedElement) {
                if (!concernedElement.contains(event.target)) {
                    document.querySelector(".customizer-contain").classList.remove('open');
                }
            }

    });


    const [dateRange, setDateRange] = useState([convertDateFormat( productState.filter.holiday_mode_date_range?.startDate),convertDateFormat(productState.filter.holiday_mode_date_range?.endDate)]);;

    return (
        <>
            <div style={{height: '1000'}}>
                <ScrollArea
                    horizontal={false}
                    vertical={true}
                >
                     <Row>
                         <Col md="12">
                             <Nav tabs className="border-tab">
                                 <NavItem><NavLink
                                     className={productState.filter.variant_type==="" ? "active" : ""}
                                     onClick={() => {
                                         dispatch(setProductFilter({
                                             ...productState.filter,        // dispatch(setProductFilter({
                                             variant_type: "",
                                         }));
                                         clearSelectAll();
                                     }}> ALL</NavLink></NavItem>
                                 <NavItem><NavLink
                                     className={productState.filter.variant_type?.id === 1 ? "active" : ""}
                                     onClick={() => {
                                         dispatch(setProductFilter({
                                             ...productState.filter,        // dispatch(setProductFilter({
                                             variant_type: {id:1,name:"Pack"}
                                         }));
                                         clearSelectAll();
                                     }}> Pack </NavLink></NavItem>
                                 <NavItem><NavLink
                                     className={productState.filter.variant_type?.id === 2 ? "active" : ""}
                                     onClick={() => {
                                         dispatch(setProductFilter({
                                             ...productState.filter,        // dispatch(setProductFilter({
                                             variant_type: {id:2,name:"Single"}
                                         }));
                                         clearSelectAll();
                                     }}> Single </NavLink></NavItem>
                             </Nav>
                         </Col>
                     </Row>

    <Accordion style={{marginBottom:'60px'}} defaultActiveKey={['0']} alwaysOpen  className="custom-accordion">
        <Accordion.Item eventKey="0">
            <Accordion.Header>General</Accordion.Header>
            <Accordion.Body>
          
            <Row  style={{marginTop:-10}}>
                <Col md="12">
                    {/*<div className="product-filter pb-0">*/}
                    {/*    <h6 className="f-w-600">Category*/}
                    {/*    </h6>*/}
                    {/*</div>*/}
                    <Select getOptionLabel={(option) => option.name}
                            className="select2-filter"
                            menuPortalTarget={document.body}
                            styles={{menuPortal: base => ({...base, zIndex: 100})}}
                            value={productState.filter.category}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => {
                                dispatch(setProductFilter({
                                    ...productState.filter,
                                    category: e,
                                    subCategory: "",
                                    styles: ""
                                }));
                                clearSelectAll();
                            }}
                            isClearable={true}
                            placeholder="Select Categories"
                        // styles={customStyles}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            options={productSettings.categories.filter(function (value, index) {
                                return value.parent_id == 0
                            })}/>
                </Col>
            </Row>
            {productState.filter.category && <Row className="mt-3">
                <Col md="12">
                    {/*<div className="product-filter pb-0">*/}
                    {/*    <h6 className="f-w-600">Sub Category*/}
                    {/*    </h6>*/}
                    {/*</div>*/}
                    <Select getOptionLabel={(option) => option.name}
                            className="select2-filter"
                            menuPortalTarget={document.body}
                            styles={{menuPortal: base => ({...base, zIndex: 100})}}
                            value={productState.filter.subCategory}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => {
                                dispatch(setProductFilter({
                                    ...productState.filter,
                                    subCategory: e,
                                    styles: ""
                                }));
                                clearSelectAll();
                            }}
                            isClearable={true}
                            placeholder="Select Sub Categories"
                        // styles={customStyles}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            options={productSettings.categories.filter(function (value, index) {
                                return value.parent_id == productState.filter.category?.id
                            })}/>
                </Col>
            </Row>}
            {/* {productState.filter.subCategory && <Row className="mt-3"> */}
            <Row className="mt-3">
                <Col md="12">
                    <Select getOptionLabel={(option) => option.name}
                            isMulti={true}
                            className="select2-filter"
                            menuPortalTarget={document.body}
                            styles={{menuPortal: base => ({...base, zIndex: 100})}}
                            value={productState.filter.styles}
                            getOptionValue={(option) => option.id}
                            isOptionDisabled={() => productState.filter.styles && productState.filter.styles.length > 4}
                            onChange={(e) => {
                                dispatch(setProductFilter({
                                    ...productState.filter,
                                    styles: e.length > 0 ? e : "",
                                }));
                                clearSelectAll();
                            }}
                            placeholder="Select Styles"
                        // styles={customStyles}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            options={productSettings.styles}/>
                </Col>
                </Row>
            {/* </Row> */}
            {/* } */}
            <Row className="mt-3">
                <Col md="12">
                    {/*<div className="product-filter pb-0">*/}
                    {/*    <h6 className="f-w-600">Category*/}
                    {/*    </h6>*/}
                    {/*</div>*/}
                    <Select getOptionLabel={(option) => option.name}
                            className="select2-filter"
                            menuPortalTarget={document.body}
                            styles={{menuPortal: base => ({...base, zIndex: 100})}}
                            value={productState.filter.tag_type}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => {
                                dispatch(setProductFilter({
                                    ...productState.filter,
                                    tag_type: e
                                }));
                                clearSelectAll();
                            }}
                            isClearable={true}
                            placeholder="Select Tag Type"
                        // styles={customStyles}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            options={productSettings.tag_settings}/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md="12">
                    {/*<div className="product-filter pb-0">*/}
                    {/*    <h6 className="f-w-600">Tag*/}
                    {/*    </h6>*/}
                    {/*</div>*/}
                    <Select getOptionLabel={(option) => option.name}
                            isMulti={true}
                            className="select2-filter"
                            menuPortalTarget={document.body}
                            styles={{menuPortal: base => ({...base, zIndex: 100})}}
                            value={productState.filter.tags}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => {
                                dispatch(setProductFilter({
                                    ...productState.filter,
                                    tags: e.length > 0 ? e : "",
                                }));
                                clearSelectAll();
                            }}
                            isOptionDisabled={() => productState.filter.tags && productState.filter.tags.length > 4}
                            placeholder="Select Tags"
                        // styles={customStyles}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            options={productSettings.tags}/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md="12">
                    {/*<div className="product-filter pb-0">*/}
                    {/*    <h6 className="f-w-600">Tag*/}
                    {/*    </h6>*/}
                    {/*</div>*/}
                    <Select getOptionLabel={(option) => option.name}
                            className="select2-filter"
                            menuPortalTarget={document.body}
                            styles={{menuPortal: base => ({...base, zIndex: 100})}}
                            value={productState.filter.season}
                            isClearable={true}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => {
                                dispatch(setProductFilter({
                                    ...productState.filter,
                                    season: e,
                                }));
                                clearSelectAll();
                            }}
                            placeholder="Select Season"
                        // styles={customStyles}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            options={productSettings.seasons}/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md="12">
                    {/*<div className="product-filter pb-0">*/}
                    {/*    <h6 className="f-w-600">Tag*/}
                    {/*    </h6>*/}
                    {/*</div>*/}
                    <Select getOptionLabel={(option) => option.name}
                            className="select2-filter"
                            menuPortalTarget={document.body}
                            styles={{menuPortal: base => ({...base, zIndex: 100})}}
                            value={productState.filter.country}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => {
                                dispatch(setProductFilter({
                                    ...productState.filter,
                                    country: e,
                                }));
                                clearSelectAll();
                            }}
                            isClearable={true}
                            placeholder="Select Country"
                        // styles={customStyles}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            options={productSettings.countries}/>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col md="12">
                    {/*<div className="product-filter pb-0">*/}
                    {/*    <h6 className="f-w-600">Tag*/}
                    {/*    </h6>*/}
                    {/*</div>*/}
                    <Select getOptionLabel={(option) => option.name}
                            isMulti={true}
                            className="select2-filter"
                            menuPortalTarget={document.body}
                            styles={{menuPortal: base => ({...base, zIndex: 100})}}
                            value={productState.filter.compositions}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => {
                                dispatch(setProductFilter({
                                    ...productState.filter,
                                    compositions: e.length > 0 ? e : "",
                                }));
                                clearSelectAll();
                            }}
                            isOptionDisabled={() => productState.filter.compositions && productState.filter.compositions.length > 4}
                            placeholder="Select Compositions"
                        // styles={customStyles}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            options={productSettings.compositions}/>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col md="12">
                    {/*<div className="product-filter pb-0">*/}
                    {/*    <h6 className="f-w-600">Tag*/}
                    {/*    </h6>*/}
                    {/*</div>*/}
                    <Select getOptionLabel={(option) => option.name}
                            className="select2-filter"
                            menuPortalTarget={document.body}
                            styles={{menuPortal: base => ({...base, zIndex: 100})}}
                            value={productState.filter.commodity}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => {
                                dispatch(setProductFilter({
                                    ...productState.filter,
                                    commodity: e,
                                }));
                                clearSelectAll();
                            }}
                            placeholder="Select Commodity"
                            isClearable={true}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            options={productSettings.commodities}/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md="12">
                    {/*<div className="product-filter pb-0">*/}
                    {/*    <h6 className="f-w-600">Tag*/}
                    {/*    </h6>*/}
                    {/*</div>*/}
                    <Select getOptionLabel={(option) => option.name}
                            className="select2-filter"
                            menuPortalTarget={document.body}
                            styles={{menuPortal: base => ({...base, zIndex: 100})}}
                            value={productState.filter.colour}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => {
                                dispatch(setProductFilter({
                                    ...productState.filter,
                                    colour: e,
                                }));
                                clearSelectAll();
                            }}
                            placeholder="Select Colour"
                            isClearable={true}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            options={productSettings.colours}/>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col md="12">
                    <Select getOptionLabel={(option) => option.name}
                        className="select2-filter"
                        menuPortalTarget={document.body}
                        styles={{menuPortal: base => ({...base, zIndex: 100})}}
                        value={productState.filter.product_group}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => {
                            dispatch(setProductFilter({
                                ...productState.filter,
                                product_group: e,
                            }));
                            clearSelectAll();
                        }}
                        placeholder="Select Group Product"
                        isClearable={true}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        options={productSettings.group_products}
                    />
                </Col>
            </Row>

            <Row style={{marginTop:15}}>
                <Col md="12">
                    <div className="">
                        {/* <div className="product-filter pb-0">
                            <h6 className="f-w-600">Empty Field Check
                            </h6>
                        </div> */}
                        <Input type="select" name="select" onChange={(e) => {
                            dispatch(setProductFilter({
                                ...productState.filter,
                                null_check: e.target.value ? {
                                    id: e.target.value,
                                    name: e.target.options[e.target.selectedIndex].text
                                } : ""
                            }))
                            clearSelectAll();
                        }} className="form-control digits" value={productState.filter.null_check?.id}>
                            <option value="">Select Empty Field</option>
                            <option value="1-description">Description</option>
                            <option value="1-summary">Summary</option>
                            <option value="2-tag">Tag</option>
                            <option value="1-custom_tags">Handel</option>
                            <option value="1-taxonomy_id">Standard Product Type</option>
                            <option value="2-style">Style</option>
                            <option value="1-commodity_id">Commodity</option>
                            <option value="1-weight_value">Weight</option>
                            <option value="1-hs_code">Hs Code</option>
                            <option value="2-composition">Composition</option>
                            <option value="1-sale_price">Sale Price</option>


                        </Input>
                    </div>
                </Col>
            </Row>

            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
            <Accordion.Header>Inventory</Accordion.Header>
            <Accordion.Body>
            <Row className="mt-1">
                <Col md="12">
                    <div className="product-filter pb-0">

                        <div style={{marginTop:-15, padding: '10px'}}>
                            <CustomInputRange
                                title="Stock"
                                maxRange={2000}
                                stateValue={productState.filter.stock}
                                clearRange={() => {
                                    dispatch(setProductFilter({
                                        ...productState.filter,        // dispatch(setProductFilter({
                                        stock: "",
                                    }));
                                    clearSelectAll();
                                }}
                                rangeComplete={(e) => {
                                    dispatch(setProductFilter({
                                        ...productState.filter,        // dispatch(setProductFilter({
                                        stock: e,
                                    }));
                                    clearSelectAll();

                                }}
                            >

                            </CustomInputRange>
                        </div>

                    </div>
                </Col>
            </Row>
            <Row style={{marginTop: -50, padding: '10px'}}>
                <Col md="12">
                    <div className="product-filter pb-0">
                        <CustomInputRange
                            title="Price"
                            maxRange={500}
                            stateValue={productState.filter.price}
                            clearRange={() => {
                                dispatch(setProductFilter({
                                    ...productState.filter,        // dispatch(setProductFilter({
                                    price: "",
                                }));
                                clearSelectAll();
                            }}
                            rangeComplete={(e) => {
                                dispatch(setProductFilter({
                                    ...productState.filter,        // dispatch(setProductFilter({
                                    price: e,
                                }));
                                clearSelectAll();

                            }}
                        >

                        </CustomInputRange>
                    </div>
                </Col>
            </Row>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
            <Accordion.Header>Sales</Accordion.Header>
            <Accordion.Body>
            <Row className="mt-2" >
                <Col md="12">
                    <Nav tabs className="border-tab">
                        <NavItem><NavLink
                            className={productState.filter.offer_type==="" ? "active" : ""}
                            onClick={() => {
                                dispatch(setProductFilter({
                                    ...productState.filter,        // dispatch(setProductFilter({
                                    offer_type: "",
                                }));
                                clearSelectAll();
                            }}> ALL</NavLink></NavItem>
                        <NavItem><NavLink
                            className={productState.filter.offer_type?.id === 1 ? "active" : ""}
                            onClick={() => {
                                dispatch(setProductFilter({
                                    ...productState.filter,        // dispatch(setProductFilter({
                                    offer_type: {id:1,name:"Yes"}
                                }));
                                clearSelectAll();
                            }}>Sales </NavLink></NavItem>
                        <NavItem><NavLink
                            className={productState.filter.offer_type?.id === 2 ? "active" : ""}
                            onClick={() => {
                                dispatch(setProductFilter({
                                    ...productState.filter,        // dispatch(setProductFilter({
                                    offer_type: {id:2,name:"No"}
                                }));
                                clearSelectAll();
                            }}> Non</NavLink></NavItem>
                    </Nav>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col md="12">
                    <div className="product-filter pb-0" style={{padding: '10px'}}>
                        <h6 className="f-w-600">Holiday Mode
                        </h6>
                    </div>
                </Col>
                <Col md="2">
                    <div className="mt-2" style={{padding: '10px'}}>
                    <input  checked={productState.filter.holiday_mode_date_range?true:false} type="checkbox" onChange={(e) => {

                            const startDate = e.target.checked?new Date():"";
                            const endDate =e.target.checked? new Date().setDate(startDate.getDate() + 30):"";
                            dispatch(setProductFilter({
                                ...productState.filter,
                                holiday_mode_date_range:e.target.checked? {startDate:dateFormat(startDate) , endDate:dateFormat(endDate) }:"",

                            }));
                            setDateRange([startDate, endDate]);
                            clearSelectAll();

                    }}/>
                    </div>
                </Col>
                <Col md="10">
                    {productState.filter.holiday_mode_date_range &&<DatePicker 
                        dateRange={dateRange} 
                        placement="leftEnd"
                        defaultValue={dateRange}
                        dateRangeChange={(date)=>{
                            
                            setDateRange(date);
                        if(date[0] && date[1]) {
                            dispatch(setProductFilter({
                                ...productState.filter,
                                holiday_mode_date_range: {
                                    startDate: dateFormat(date[0]),
                                    endDate: dateFormat(date[1])
                                }

                            }));
                            clearSelectAll();

                        }
                    }} />}
                </Col>
            </Row>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>

           



        </ScrollArea>
            </div>

        </>
    );
}

export default ProductFilter;