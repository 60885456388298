import React,{Fragment, useState} from 'react';
import {Button, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {useHistory, withRouter} from "react-router-dom";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {forgotPasswordApi} from "../services/auth";
import {toast} from "react-toastify";

const defaultValues = {
    email: '',

}

const schema = yup.object().shape({
    email: yup.string().email('Must be a valid email').required('You must enter email'),

});



const ForgotPwMail = () => {

    const { handleSubmit,register,formState: { errors } ,setValue,control} = useForm({
        defaultValues,
        resolver: yupResolver(schema)
    });
    let history = useHistory();

    const onSubmit = async data => {
        const postData = {
            email: data.email,
            url: `${window.location.origin.toString()}/change-password`
        };

        await forgotPasswordApi(postData);
        history.push('/login')
        toast.success("Check your email inbox");
    };
    const login = () => {
        history.push('/login')
    }

    return (
        <Container fluid={true} className="p-0">
            <Row>
                <Col xs="12">
                    <div className="login-card">
                        <div>
                            <div className="login-main">
                                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                    <h6 className="mt-4">{"Recover your password"}</h6>
                                    <FormGroup>
                                        <Label className="col-form-label">Email</Label>
                                        <Controller
                                            name="email"
                                            type="email"
                                            className={`${errors.email ? ' is-invalid' : ''}`}
                                            autoComplete="new-email"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => <Input {...field} placeholder="Email" />}
                                        />
                                    </FormGroup>
                                    <FormGroup className="mt-3 mb-0">
                                        <Button color="primary" className="btn-block" type="submit">Submit</Button>
                                        <Button color="primary" onClick={login} className="btn-block" type="button">Sign In</Button>
                                    </FormGroup>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default withRouter(ForgotPwMail)