import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Container, Button, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getSalesOrderFilters,
  setSettings,
  showSalesOrder,
  setSalesCloseDialog,
} from "../../../store/salesOrderSlice";
import Select from "react-select";

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 50,
    border: "none",
  }),
};

const CloseSalesOrderModal = ({
  handleConfirm,
  setSelectedReason,
  componentName,
}) => {
  const dispatch = useDispatch();
  const [loading, isSetLoading] = useState(false);
  const authData = useSelector(({ authReducer }) => authReducer);
  const salesCloseDialogProps = useSelector(
    (state) => state.order.salesOrder.salesCloseDialogProps
  );
  const salesOrderState = useSelector(({ order }) => order.salesOrder);

  const toggleModal = () => {
    closeDialog();
    dispatch(showSalesOrder(salesCloseDialogProps.data)).then((res) => {
      if (!res.error) isSetLoading(false);
    });
  };

  const closeDialog = () => {
    const obj = { props: { isOpen: false } };
    dispatch(setSalesCloseDialog(obj));
  };

  useEffect(() => {
    if (salesCloseDialogProps.data) {
    }
  }, [salesCloseDialogProps.data]);

  useEffect(() => {
    dispatch(getSalesOrderFilters()).then((res) => {
      if (!res.error) {
        dispatch(setSettings(res.payload));
      }
    });
  }, []);

  return (
    <Modal
      isOpen={salesCloseDialogProps.props.isOpen}
      toggle={toggleModal}
      size="md"
    >
      <ModalBody>
        {salesCloseDialogProps.data && (
          <>
            <Container fluid={true}>
              <div className="text-center p-3">
                <div className="custom-sweetalert">
                  <h5 className="custom-sweetalert-title p-2">
                    Are you sure you want to close this {componentName}?
                  </h5>
                  <p className="custom-sweetalert-text p-2">
                    Once confirmed, the {componentName} will not be able to
                    proceed further
                  </p>
                  <Col>
                    <Select
                      className="select2-filter border"
                      isClearable
                      cacheOptions
                      styles={customStyles}
                      placeholder="Select Reason"
                      getOptionLabel={(option) => option.reason}
                      getOptionValue={(option) => option.id}
                      onChange={(e) => {
                        setSelectedReason(e ? e.id : null);
                      }}
                      options={
                        salesOrderState.settings
                          ? salesOrderState.settings.close_reasons
                          : null
                      }
                    />
                  </Col>
                  <div className="custom-sweetalert-buttons p-2">
                    <Button
                      color="success"
                      className="mr-1"
                      onClick={handleConfirm}
                    >
                      Yes, Proceed
                    </Button>
                    <Button color="primary" onClick={closeDialog}>
                      No, Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Container>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default CloseSalesOrderModal;
