import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import {
  getBoxTypesApi,
  addBoxTypeApi,
  updateBoxTypeApi,
  deleteBoxTypeApi,
} from "../../../services/manage-product/setting/boxTypeService";
import { toast } from "react-toastify";

export const getAllBoxTypes = createAsyncThunk(
  "boxType/getBoxType",
  async (filters, { dispatch }) => {
    const response = await getBoxTypesApi(filters);
    return response.response;
  }
);

export const addBoxType = createAsyncThunk(
  "boxType/addBoxType",
  async (boxType, { dispatch, getState }) => {
    try {
      const response = await addBoxTypeApi(boxType);
      const data = await response.response;
      dispatch(getAllBoxTypes());
      toast.success("Box type added successfully");
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const updateBoxType = createAsyncThunk(
  "boxType/updateBoxType",
  async (boxType, { dispatch, getStatus }) => {
    try {
      const response = await updateBoxTypeApi(boxType, boxType.id);
      const data = await response.response;
      dispatch(getAllBoxTypes());
      toast.success("Box type updated successfully");
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const deleteBoxType = createAsyncThunk(
  "boxType/deleteBoxType",
  async (id, { dispatch, getStatus }) => {
    try {
      const response = await deleteBoxTypeApi(id);
      const data = await response.response;
      dispatch(getAllBoxTypes());
      toast.success("Box type deleted successfully");
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

const boxTypeAdapter = createEntityAdapter({});

export const { selectAll: selectBoxType, selectById: selectBoxTypeById } =
  boxTypeAdapter.getSelectors((state) => state.product.boxType);
console.log(boxTypeAdapter);

const boxTypeSlice = createSlice({
  name: "boxType",
  initialState: boxTypeAdapter.getInitialState({
    searchText: "",
    addDialog: {
      component: "BoxType",
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setBoxTypeSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewDialog: (state, action) => {
      state.addDialog = {
        component: "BoxType",
        type: "new",
        props: {
          isOpen: true,
        },
        data: null,
      };
    },
    openEditDialog: (state, action) => {
      state.addDialog = {
        type: "edit",
        props: {
          isOpen: true,
        },
        data: action.payload,
      };
    },
    closeEditDialog: (state, action) => {
      state.addDialog = {
        type: "edit",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [getAllBoxTypes.fulfilled]: boxTypeAdapter.setAll,
  },
});

export const {
  setBoxTypeSearchText,
  openNewDialog,
  closeNewDialog,
  openEditDialog,
  closeEditDialog,
} = boxTypeSlice.actions;

export default boxTypeSlice.reducer;
