import API from "../../utils/api";

export const addSalesOrderApi = async (data) => {
  const response = await API.post("order/sales-order", data);
  return { response };
};

export const editSaleOrderApi = async (id) => {
  const response = await API.get(`order/sales-order/${id}/edit`);
  return { response };
};

export const getCurrencyAutoSuggestionApi = async (data) => {
  const response = await API.get("settings/code/type-auto-search", {
    params: data,
  });
  return { response };
};

export const getCurrencyRateApi = async (data) => {
  const response = await API.get("settings/exchange-rate", { params: data });
  return { response };
};

export const getSalesOrderApi = async (filters) => {
  const response = await API.get("order/sales-order", { params: filters });
  return { response };
};

export const getSalesOrderStatusesApi = async (filters) => {
  const response = await API.get("order/sales-order/status-list", {
    params: filters,
  });
  return { response };
};

export const showSalesOrderApi = async (id) => {
  const response = await API.get(`order/sales-order/${id}/show`);
  return { response };
};

export const updateSalesOrderApi = async (location, id) => {
  const response = await API.put(`order/sales-order/${id}/update`, location);
  return { response };
};

export const addSalesOrderCommentApi = async (data) => {
  const response = await API.post("order/sales-order/comment", data);
  return { response };
};

export const getSalesOrderCommentApi = async (data) => {
  const response = await API.get("order/sales-order/comment", { params: data });
  return { response };
};

export const showSalesOrderCommentApi = async (id, data) => {
  const response = await API.get(`order/sales-order/comment/${id}/edit`, {
    params: data,
  });
  return { response };
};

export const updateSalesOrderCommentApi = async (location, id) => {
  const response = await API.put(
    `order/sales-order/comment/${id}/update`,
    location
  );
  return { response };
};

export const deleteSalesOrderCommentApi = async (id) => {
  const response = await API.delete(`order/sales-order/comment/${id}/delete`);
  return { response };
};

export const updateSalesOrderStatusApi = async (location, id) => {
  const response = await API.post(
    `order/sales-order/${id}/status-update`,
    location
  );
  return { response };
};

export const downloadSalesOrderPickingTicketPdfApi = async (id, data) => {
  const response = await API.get(`order/sales-order/${id}/pdf`, {
    "content-type": "application/pdf",
    responseType: "blob",
    params: data,
  });
  return { response };
};

export const downloadSalesOrderPickingTicketBinPdfApi = async (id, data) => {
  const response = await API.get(`order/sales-order-group-bin-name/pdf`, {
    "content-type": "application/pdf",
    responseType: "blob",
    params: {
      ...data,
      sales_order_id: id,
    },
  });
  return { response };
};

export const sendSalesOrderInvoiceToXeroApi = async (data) => {
  const response = await API.post(`order/xero-invoice`, data);
  return { response };
};

export const getSalesOrderFilterDataApi = async () => {
  const response = await API.get("order/sales-order/filters");
  return { response };
};

export const downloadSalesOrderPdfApi = async (id, data) => {
  const response = await API.get(`order/generate-sales-order/${id}/pdf`, {
    "content-type": "application/pdf",
    responseType: "blob",
    params: data,
  });
  return { response };
};

export const closeSalesOrderApi = async (location, id) => {
  const response = await API.post(
    `order/sales-order/close-sales-order/${id}`,
    location
  );
  return { response };
};

export const viewSaleOrderPendingPickingApi = async (id) => {
  const response = await API.get(`order/sales-order/pending-picking/${id}`);
  return { response };
};

export const viewSaleOrderPendingPackingApi = async (id) => {
  const response = await API.get(`order/sales-order/pending-packing/${id}`);
  return { response };
};

export const viewSaleOrderItemLocationApi = async (id) => {
  const response = await API.get(`order/sales-order/item-location/${id}`);
  return { response };
};

export const updateSalesOrderItemLocationApi = async (location, id) => {
  const response = await API.post(
    `order/sales-order/item-location-update/${id}`,
    location
  );
  return { response };
};

export const getSalesOrderDefaultShippingApi = async (data) => {
  const response = await API.get("order/get-default-shipping", {
    params: data,
  });
  return { response };
};
