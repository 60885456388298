import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { draftOrderFilters } from "../../manage-product/product/edit/content/settings/map";
import {
  getSalesOrderInventoryApi,
  downloadSalesOrderInvoicePdfApi,
} from "../../../services/manage-order/salesOrderInventoryService";

export const getAllSaleOrderInventories = createAsyncThunk(
  "sales-order/getInventory",
  async (filters, { dispatch }) => {
    const response = await getSalesOrderInventoryApi(filters);
    dispatch(setTableRowCount(response.response.count));

    return response.response.data;
  }
);

export const downloadInventoryCommitmentPdf = createAsyncThunk(
  "sales-order/downloadInventoryCommitmentPdf",
  async (filters, { dispatch }) => {
    const response = await downloadSalesOrderInvoicePdfApi(filters);
    return response.response;
  }
);

const salesOrderInventoryAdapter = createEntityAdapter({});

export const {
  selectAll: selectAllSaleInventory,
  selectById: selectSaleInventoryById,
} = salesOrderInventoryAdapter.getSelectors(
  (state) => state.order.saleOrderInventory
);

const saleOrderInventorySlice = createSlice({
  name: "saleOrderInventory",
  initialState: salesOrderInventoryAdapter.getInitialState({
    id: null,
    noOfTableRows: 0,
    searchText: "",
    data: null,
    settings: {
      countries: [],
      shippings: [],
    },
    formSettings: {
      terms: [],
    },
    orderInventory: [],
    filter: draftOrderFilters(),
  }),
  reducers: {
    setSalesOrderFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTableRowCount: (state, action) => {
      state.noOfTableRows = action.payload;
    },
    setOrderInventories: (state, action) => {
      state.orderInventory = action.payload;
    },
    setInventoryFromSettings: (state, action) => {
      state.formSettings = action.payload;
    },
  },
  extraReducers: {
    [getAllSaleOrderInventories.fulfilled]: salesOrderInventoryAdapter.setAll,
  },
});

export const {
  setTableRowCount,
  setOrderInventories,
  setInventoryFromSettings,
} = saleOrderInventorySlice.actions;

export default saleOrderInventorySlice.reducer;
