import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumb from "../../../../layout/breadcrumb";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import PreLoader from "../../../../share-components/ui/preLoader";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Input,
} from "reactstrap";

import salesOrderInventoryReducer from "../../store";
import {
  selectAllSaleInventory,
  getAllSaleOrderInventories,
  downloadInventoryCommitmentPdf,
} from "../../store/salesOrderInventorySlice";
import { setSettings, getSalesOrderFilters } from "../../store/salesOrderSlice";
import { inventoryResponseToTable } from "../../../../utils/mapper";
import withReducer from "../../../../store/withReducer";
import Select from "react-select";

const dataMenu = [
  {
    type: 0,
    text: "Order",
  },
  {
    type: 1,
    text: "Inventory Commitment",
    link: "",
  },
];

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 50,
    border: "none",
  }),
};

const SalesOrderInventory = () => {
  const dispatch = useDispatch();
  const { committmentSku } = useParams();
  const [page, setPage] = useState(1);
  const [brand, setBrand] = useState(null);
  const [search, setSearch] = useState(null);
  const [product, setProduct] = useState(null);
  const [location, setLocation] = useState(null);
  const [binNumber, setBinNumber] = useState(null);
  const [orderNumber, setOrderNumber] = useState([]);
  let history = useHistory();
  const [loading, isSetLoading] = useState(true);
  const saleOrderInventoryState = useSelector(
    ({ order }) => order.saleOrderInventory
  );
  const salesOrderState = useSelector(({ order }) => order.salesOrder);
  const inventories = useSelector(selectAllSaleInventory);
  const [tableLoading, isSetTableLoading] = useState(false);
  const [timer, setTimer] = useState(null);
  const [sku, setSku] = useState(committmentSku || null);

  const tableColumns = [
    {
      name: "Order ID",
      selector: (row) => row.order_no,
      sortable: true,
      center: false,
    },
    {
      name: "Product",
      selector: (row) => row.product,
      sortable: true,
      center: false,
    },
    {
      name: "Order Qty",
      selector: (row) => row.quantity,
      sortable: true,
      center: false,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
      center: false,
    },
    {
      name: "Bin Number",
      selector: (row) => row.bin_location,
      sortable: true,
      center: false,
    },
  ];

  useEffect(() => {
    getData();
  }, [page, brand, search, product, location, binNumber, orderNumber, sku]);

  const getData = () => {
    const data = {
      page: page,
      pageSize: 10,
      brand: brand,
      search: search,
      product: product,
      location: location,
      bin_name: binNumber,
      order_no: orderNumber,
      sku: sku,
    };
    dispatch(getAllSaleOrderInventories(data)).then((res) => {
      if (!res.error) isSetLoading(false);
    });
  };

  useEffect(() => {
    dispatch(getSalesOrderFilters()).then((res) => {
      if (!res.error) {
        dispatch(setSettings(res.payload));
      }
    });
  }, []);

  const processPDFDownload = (name) => {
    const data = {
      product: product,
      location: location,
      order_no: orderNumber,
    };
    dispatch(downloadInventoryCommitmentPdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${name.replace(/\s+/g, "-")}_${new Date().toJSON().slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const downloadInventory = () => {
    processPDFDownload("Inventory Commitment");
  };

  return (
    <Fragment>
      <Container fluid={true} className="product-wrapper">
        {loading ? (
          <>
            <PreLoader></PreLoader>
          </>
        ) : (
          <>
            <Breadcrumb data={dataMenu} title="Inventory Commitment" />

            <Card>
              <Row>
                <Col sm="12">
                  <div className="float-sm-right p-3">
                    <a
                      className="btn btn-sm btn-primary"
                      onClick={downloadInventory}
                    >
                      Download Commitment Report
                    </a>
                  </div>
                </Col>
              </Row>
            </Card>

            <div className="product-grid">
              <div className="feature-products">
                <Row>
                  <Col md={3}>
                    <Form>
                      <FormGroup className="m-0">
                        <Input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            clearTimeout(timer);

                            const newTimer = setTimeout(() => {
                              setSku(e.target.value);
                            }, 500);

                            setTimer(newTimer);
                          }}
                          placeholder="Search by SKU."
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>

                  <Col md={3}>
                    <Select
                      className="select2-filter border"
                      isClearable
                      cacheOptions
                      styles={customStyles}
                      placeholder="Select Location"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      onChange={(e) => {
                        setLocation(e ? e.id : null);
                        setPage(1);
                      }}
                      options={
                        salesOrderState.settings
                          ? salesOrderState.settings.locations
                          : null
                      }
                    />
                  </Col>

                  <Col md={3}>
                    <Form>
                      <FormGroup className="m-0">
                        <input
                          onChange={(e) => {
                            setOrderNumber(e.target.value);
                          }}
                          type="text"
                          className="form-control border"
                          placeholder="Search By Order No."
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </div>
            </div>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="table-responsive product-table mt-4">
                      <CommonDataTable
                        headerColumns={tableColumns}
                        gridData={inventoryResponseToTable(inventories)}
                        pagination
                        progressPending={tableLoading}
                        paginationServer
                        paginationTotalRows={
                          saleOrderInventoryState.noOfTableRows
                        }
                        paginationPerPage={10}
                        noAction
                        paginationComponentOptions={{
                          noRowsPerPage: true,
                        }}
                        onChangePage={(page) => setPage(page)}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </Fragment>
  );
};
export default withReducer([
  {
    SalesOrderInventory: salesOrderInventoryReducer,
  },
])(SalesOrderInventory);
