import React, {useState} from 'react';
import {Card, CardBody, Col, Label, Row, Table} from "reactstrap";
import AsyncSelect from "react-select/async";
import {handelBinTransferDialog} from "../../../store/inventorySlice";
import {useDispatch} from "react-redux";


function AdjustmentTransfer(props) {
    const {item, index, errors, register, getValues, setValue,trigger,inventoryIndex} = props;
    const dispatch = useDispatch();
     const handelBinTranfer=()=>{
         dispatch(handelBinTransferDialog({props:{isOpen:true},data:{item:item}}));
     }

     const allocationName=()=>{
         const transferData=getValues(`inventories.${inventoryIndex}.bin_transfer`);
         let name='';
         if(transferData.length > 0){
             name=transferData[0].bin.label+":"+transferData[0].quantity
             if(transferData.length>1){
                 name+="and "+ (transferData.length-1) + "more";
             }
         }
         return name;
     }

    return (
        <>
            <td>{inventoryIndex!=-1?allocationName():''}</td>
            <td><a onClick={handelBinTranfer} className='btn bnt-info'> <i className='fa fa-plus'></i></a></td>
        </>
    );
}

export default AdjustmentTransfer;