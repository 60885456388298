import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
    listBinLocationApi, createBinLocationApi, updateBinLocationApi, deleteBinLocationApi,
    autoSuggestionBinApi
} from "../../../services/manage-product/setting/binLocationService";
import { toast } from "react-toastify";

export const getAllBinLocation = createAsyncThunk('binLocation/listBinLocation', async (data, { dispatch }) => {
    const response = await listBinLocationApi(data);
    return response.response;
});

export const createBinLocation = createAsyncThunk('binLocation/createBinLocation', async (platform, { dispatch, getState }) => {
    try {
        const response = await createBinLocationApi(platform);
        const data = await response.response;
        dispatch(getAllBinLocation());
        toast.success("Bin Location has been created successfully.");
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const updateBinLocation = createAsyncThunk('binLocation/updatebinLocation', async (platform, { dispatch, getStatus }) => {
    try {
        const response = await updateBinLocationApi(platform, platform.id);
        const data = await response.response;
        dispatch(getAllBinLocation());
        toast.success("Bin Location has been updated successfully.");
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const deleteBinLocation = createAsyncThunk('binLocation/deletebinLocation', async (id, { dispatch, getStatus }) => {
    try {
        const response = await deleteBinLocationApi(id);
        const data = await response.response;
        dispatch(getAllBinLocation());
        toast.success("Bin Location has been deleted successfully.");
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const autoSuggestionBin = createAsyncThunk('binLocation/autoSuggestionBin', async (query) => {
    const response = await autoSuggestionBinApi(query);
    return response.response;
});

const binLocationAdapter = createEntityAdapter({});

export const { selectAll: selectBinLocation, selectById: selectBinLocationById } = binLocationAdapter.getSelectors(
    state => state.product.binLocation
);

const binLocationSlice = createSlice({
    name: 'binLocation',
    initialState: binLocationAdapter.getInitialState({
        searchText: '',
        addDialog: {
            component: 'binLocation',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },

    }),
    reducers: {
        openNewDialog: (state, action) => {
            state.addDialog = {
                component: 'binLocation',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        openEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload
            };
        },
        closeNewDialog: (state, action) => {
            state.addDialog = {
                type: 'new',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
    },
    extraReducers: {
        [getAllBinLocation.fulfilled]: binLocationAdapter.setAll
    }
});

export const {
    openNewDialog, closeNewDialog, openEditDialog,
} = binLocationSlice.actions;

export default binLocationSlice.reducer;

