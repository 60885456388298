import React, { Fragment, useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Col,
  Card,
  CardBody,
  Row,
  Label,
  Alert,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { setSalesDialog } from "../../../store/salesOrderStatusSlice";
import {
  updateSalesOrderStatus,
  downloadSalesOrderPickingTicketPdf,
  downloadSalesOrderPickingTickeBinPdf,
  showSalesOrder,
} from "../../../store/salesOrderSlice";
import { salesOrderStatus } from "../../../../../utils/common";
import SaleOrderDiscussion from "../../view/content/saleOrderDiscussion";
import SaleOrderStatusProductJqx from "./salesOrderStatusProduct";

const defaultValues = {
  id: "",
  ordered_products: [],
};

const SalesOrderModal = ({ getData }) => {
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownStatusOpen, setDropdownStatusOpen] = useState(false);
  const [statusName, setStatusName] = useState("");
  const [loading, isSetLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const authData = useSelector(({ authReducer }) => authReducer);
  const [enableDownloadPicking, setEnableDownloadPicking] = useState(false);
  const salesDialogProps = useSelector(
    (state) => state.order.saleOrderStatus.salesDialogProps
  );
  const { setValue, trigger, getValues } = useForm({
    mode: "onChange",
    defaultValues,
  });
  const [discussionSection, setDiscussionSection] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [popoverTarget, setPopoverTarget] = useState(null);
  const order_id = salesDialogProps.data.id;
  const [popoverOpen, setPopoverOpen] = useState({});
  const [productData, setProductData] = useState([]);

  const togglePopover = (order_id) => {
    setPopoverOpen((prevState) => ({
      ...prevState,
      [order_id]: !prevState[order_id],
    }));
  };

  const handleStatusNameUpdate = (name) => {
    setStatusName(name);
  };

  const processPDFDownload = (name) => {
    const orderId = order_id;
    const data = {
      id: orderId,
      params: {
        name: name,
        seller: authData.defaultSeller?.value,
      },
    };

    dispatch(downloadSalesOrderPickingTicketPdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${salesDialogProps.data.order_no}_${name.replace(
          /\s+/g,
          "-"
        )}_${new Date().toJSON().slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const processPickingBinPDFDownload = (name) => {
    const orderId = order_id;
    const data = {
      id: orderId,
      params: {
        name: name,
        seller: authData.defaultSeller?.value,
      },
    };

    dispatch(downloadSalesOrderPickingTickeBinPdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${salesDialogProps.data.order_no}_${name.replace(
          /\s+/g,
          "-"
        )}_${new Date().toJSON().slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const toggleModal = () => {
    isSetLoading(true);
    closeDialog();
    getData();
    // const data = {
    //   search: search,
    // };
    // dispatch(getSalesOrderStatus(data)).then((res) => {
    //   if (!res.error) isSetLoading(false);
    // });
  };

  const toggleStatusDropdown = () => {
    setDropdownStatusOpen(!dropdownStatusOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const downloadPicking = () => {
    processPDFDownload("Picking Ticket By Style");

    const orderId = order_id;
    if (orderId) {
      const currentStatus = salesDialogProps.data.status_id;
      if (currentStatus === 1) {
        dispatch(updateSalesOrderStatus({ id: orderId, status_id: 2 }))
          .then((res) => {
            if (!res.error) {
              setStatusName(res.payload.name || "");
            }
          })
          .catch((error) => {
            console.error("Error updating status:", error);
          });
      }
    }
  };

  const downloadBinPicking = () => {
    processPickingBinPDFDownload("Picking Ticket By Bin");

    const orderId = order_id;
    if (orderId) {
      const currentStatus = salesDialogProps.data.status_id;
      if (currentStatus === 1) {
        dispatch(updateSalesOrderStatus({ id: orderId, status_id: 2 }))
          .then((res) => {
            if (!res.error) {
              setStatusName(res.payload.name || "");
            }
          })
          .catch((error) => {
            console.error("Error updating status:", error);
          });
      }
    }
  };

  const handleStatusChange = (newStatusId) => {
    const orderId = order_id;

    dispatch(updateSalesOrderStatus({ id: orderId, status_id: newStatusId }))
      .then((res) => {
        setStatusName(res.payload.name || "");
        setDiscussionSection(res.payload.id == 2);
      })
      .catch((error) => {
        console.error("Error updating status:", error);
      });
  };

  const closeDialog = () => {
    const obj = { props: { isOpen: false }, type: "new", data: "" };
    dispatch(setSalesDialog(obj));
  };

  const dropdownStatusOptions = salesOrderStatus.map((status) => (
    <DropdownItem key={status.id} onClick={() => handleStatusChange(status.id)}>
      {status.name}
    </DropdownItem>
  ));

  useEffect(() => {
    if (salesDialogProps.data) {
      setStatusName(salesDialogProps.data.status || "");
      setDiscussionSection(
        salesDialogProps.data.status_id === 2 ||
          salesDialogProps.data.status_id === 6
      );
      setShowProducts(salesDialogProps.data.status_id == 1);
    }
  }, [salesDialogProps.data]);

  useEffect(() => {
    isSetLoading(true);
    const orderId = order_id;
    if (orderId) {
      isSetLoading(true);
      dispatch(showSalesOrder({ id: orderId })).then((res) => {
        if (!res.error) {
          isSetLoading(false);
          setEnableDownloadPicking(res.payload.sum_back_order_quantity == 0);
          setValue("ordered_products", res.payload.ordered_products);
          setValue("sum_quantity", res.payload.sum_quantity);
          setValue(
            "sum_back_order_quantity",
            res.payload.sum_back_order_quantity
          );
          setProductData(res.payload.ordered_products);
          setEnableDownloadPicking(res.payload.sum_back_order_quantity == 0);
        }
      });
    } else {
      isSetLoading(false);
    }
  }, [dispatch, order_id]);

  return (
    <Modal
      isOpen={salesDialogProps.props.isOpen}
      toggle={toggleModal}
      size="xl"
      backdrop={false}
    >
      <ModalHeader toggle={toggleModal}>
        <p
          style={{ fontSize: "20px" }}
        >{`Sales Order #${salesDialogProps.data.order_no}`}</p>
        <span
          style={{
            fontWeight: "lighter",
            color: "rgb(115, 115, 115)",
            fontSize: "16px",
          }}
        >
          Status :{" "}
          <span
            style={{
              textTransform: "uppercase",
            }}
          >
            {statusName}
          </span>
        </span>
      </ModalHeader>
      <ModalBody>
        {salesDialogProps.data && (
          <>
            <Container fluid={true}>
              <div className="d-flex mb-2 px-2">
                {enableDownloadPicking ? (
                  <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                    <DropdownToggle
                      color="warning"
                      className="btn btn-sm btn-warning"
                      style={{ height: 38, marginRight: 8 }}
                    >
                      Picking Ticket{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={downloadPicking}>
                        Picking Ticket by Style
                      </DropdownItem>
                      <DropdownItem onClick={downloadBinPicking}>
                        Picking Ticket by Bin
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                ) : (
                  <>
                    <Button
                      color="warning"
                      className="btn btn-sm btn-warning"
                      style={{ height: 38, marginRight: 8 }}
                      id={`pickingTicketPopover-${order_id}`}
                      disabled
                    >
                      Picking Ticket{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </Button>
                    <Popover
                      placement="bottom"
                      isOpen={popoverOpen[order_id] || false}
                      target={`pickingTicketPopover-${order_id}`}
                      toggle={() => togglePopover(order_id)}
                      trigger="hover"
                    >
                      <PopoverBody>
                        This order has back-order quantity.
                      </PopoverBody>
                    </Popover>
                  </>
                )}
              </div>

              <Col sm="12" className="p-2">
                {showProducts && (
                  <>
                    {productData.length > 0 ? (
                      <Row>
                        <Col sm="12">
                          {getValues("ordered_products").length > 0 && (
                            <SaleOrderStatusProductJqx
                              setValue={setValue}
                              getValues={getValues}
                              trigger={trigger}
                              enableDownloadPicking={enableDownloadPicking}
                              orderedProducts={productData}
                            />
                          )}
                        </Col>
                      </Row>
                    ) : (
                      <div style={{ textAlign: "center" }}>Loading...</div>
                    )}
                  </>
                )}

                {/* {!showProducts && (
                  <div style={{ textAlign: "center" }}>Loading...</div>
                )} */}
              </Col>

              <Col sm="12" className="p-2">
                {discussionSection && (
                  <Card
                    style={{
                      border: "1px solid rgb(230, 230, 230)",
                      borderRadius: "8px",
                    }}
                  >
                    <CardBody
                      style={{ paddingTop: "24px", paddingBottom: "24px" }}
                    >
                      <Row>
                        <Col sm="6">
                          <Label
                            style={{
                              fontWeight: "bolder",
                              fontSize: "16px",
                              paddingBottom: "10px",
                            }}
                          >
                            Discussion
                          </Label>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="12">
                          <SaleOrderDiscussion
                            salesOrderId={salesDialogProps.data.id}
                            salesOrderComments={salesDialogProps.data.comments}
                            isInputShow={
                              salesDialogProps.data.status_id == 2
                                ? true
                                : false
                            }
                            salesOrderStatus={salesDialogProps.data.status_id}
                            setStatusName={handleStatusNameUpdate}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Container>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default SalesOrderModal;
