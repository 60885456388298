import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {
    getInventoryAdjustDataApi,
    exportInventoryAdjustmentDetailsApi,
    inventoryAdjustmentDataApi,
    exportInventoryAdjustmentGainLoseApi, exportConditionApi
} from "../../../services/manage-product/productService";
import {toast} from "react-toastify";

export const getInventoryAdjust=createAsyncThunk('product/getInventoryAdjust',async(data, {dispatch})=>{
    const response=await getInventoryAdjustDataApi(data);
    dispatch(setInventoryData(response.response.count))
    return response.response.data;
});

export const exportInventoryAdjustmentDetails = createAsyncThunk('product/exportInventoryAdjustmentDetails', async (data, {dispatch}) => {
    const response = await exportInventoryAdjustmentDetailsApi(data);
    return response.response;
});

export const exportInventoryGainLoss = createAsyncThunk('product/exportGainLoss', async (data, {dispatch}) => {
    const response = await exportInventoryAdjustmentGainLoseApi();
    return response.response;
});
export const exportCondition = createAsyncThunk('product/exportCondition', async (data, {dispatch}) => {
    const response = await exportConditionApi(data);
    return response.response;
});


export const inventoryAdjustmentData = createAsyncThunk('product/inventoryAdjustmentData', async (product, {dispatch}) => {
    try {
        const response = await inventoryAdjustmentDataApi(product);
        const data = await response.response;
        toast.success("Added Inventory Adjustment Data successfully");
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});



const inventoryAdjustmentAdapter = createEntityAdapter({});
export const {selectAll: selectinventoryAdjustment, selectById: selectinventoryAdjustmentById} = inventoryAdjustmentAdapter.getSelectors(
    state => state.product.inventoryAdjustment
);

const inventoryAdjustmentsSlice = createSlice({
    name: 'inventoryAdjustment',
    initialState: inventoryAdjustmentAdapter.getInitialState({
        inventoryAdjustmentTableRow: 0,

       
    }),
    reducers: {
        setInventoryData: (state, action) => {
            state.inventoryAdjustmentTableRow = action.payload;
        },

    },
    extraReducers: {
        [getInventoryAdjust.fulfilled]: inventoryAdjustmentAdapter.setAll,
    }
});

export const {setInventoryData} = inventoryAdjustmentsSlice.actions;

export default inventoryAdjustmentsSlice.reducer;