import React, {useState, useEffect} from 'react';
import man from '../assets/images/dashboard/profile.jpg';
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Button,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane
} from 'reactstrap'
import {firebase_app, googleProvider, facebookProvider, githubProvider, Jwt_token} from '../data/config'
import {handleResponse} from '../services/fack.backend'
// import { useAuth0 } from '@auth0/auth0-react'
import {toast} from 'react-toastify';
import {useHistory, withRouter} from 'react-router-dom'
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form'

import {Facebook, GitHub} from 'react-feather'
import {
    Password,
    SignIn,
    EmailAddress,
    RememberPassword,
    ForgotPassword,
    CreateAccount,
    FIREBASE,
    AUTH0,
    JWT,
    LoginWithJWT
} from '../constant';
import {loginApi} from "../services/auth";
import {connect, useDispatch} from "react-redux";
import {setUser, setUserDataPassportAuth} from "../redux/auth/userSlice";
import {getUserMenus} from "../redux/auth/navigationSlice";
import {logos} from "../utils/common";

const defaultValues = {
    email: '',
    password: ''
}

const schema = yup.object().shape({
    email: yup.string().email('Must be a valid email').required('You must enter email'),
    password: yup.string().required('You must enter  password')
});


const Logins = (props) => {

    // const {loginWithRedirect} = useAuth0()
    const dispatch = useDispatch();

    let history = useHistory();
    const [loading,setLoading] = useState(false)

    const [selected, setSelected] = useState("jwt");
    const [togglePassword, setTogglePassword] = useState(false);
    const {handleSubmit, register, formState: {errors}, setValue} = useForm({
        defaultValues,
        resolver: yupResolver(schema)
    });

    const domainName = window.location.hostname;
    const themeLogo=logos.find(function (el) {

        return el.domain==domainName;
    })
    const logo =themeLogo ? themeLogo.mainIcon : 'logo-full-city-goddess.png';


    const onSubmit = async data => {
        const params = new URLSearchParams({
            email: data.email,
            password: data.password
        });
        await loginApi(params)
            .then(data => {
                localStorage.setItem('token', data.response.accessToken);
                // window.location.href = `${process.env.PUBLIC_URL}/dashboard/default/`

                toast.success("Successfully Logged in !");
                dispatch(setUserDataPassportAuth(data.response.user));
                dispatch(getUserMenus());

                // dispatch(showMessage({ message: 'Login Successfully' }));
            })
            .catch(error => {
                toast.error("Invalid Credentials !");
            });

    };
    const forgotPassword = () => {
        history.push('/forget-password-email')
    }

    return (
        <Container fluid={true} className="p-0">
            <Row>
                <Col xs="12">
                    <div className="login-card">
                        <div>
                            <div>
                                <a className="logo" href="index.html">
                                    <img width={250} className="img-fluid for-light" src={require("../assets/images/logo/" + logo)}
                                         alt=""/>
                                    <img className="img-fluid for-dark"
                                         src={require("../assets/images/logo/logo_dark.png")} alt=""/>
                                </a>
                            </div>
                            <div className="login-main login-tab">
                                <TabContent activeTab={selected} className="content-login" style={{height: 'auto'}}>
                                    <TabPane className="fade show" tabId={selected === "firebase" ? "firebase" : "jwt"}>
                                        <Form className="theme-form" onSubmit={handleSubmit(onSubmit)} noValidate="off">
                                            <p>{"Enter your email & password to login"}</p>
                                            <FormGroup>
                                                <Label className="col-form-label">{EmailAddress}</Label>
                                                <input name="email" type="email" {...register('email')}
                                                       className={`form-control ${errors.email ? 'is-invalid' : ''}`}/>
                                                <div className="invalid-feedback">{errors.email?.message}</div>

                                            </FormGroup>
                                            <FormGroup>
                                                <Label className="col-form-label">{Password}</Label>
                                                <input name="password"
                                                       type={togglePassword ? "text" : "password"}  {...register('password')}
                                                       className={`form-control ${errors.password ? 'is-invalid' : ''}`}/>

                                                <div className="show-hide"
                                                     onClick={() => setTogglePassword(!togglePassword)}><span
                                                    className={togglePassword ? "" : "show"}></span></div>
                                                <div className="invalid-feedback">{errors.password?.message}</div>
                                                <div>
                                                    <div className="invalid-feedback">{errors.password?.message}</div>
                                                </div>
                                            </FormGroup>
                                            <div className="form-group mb-3 mt-3">
                                                <div className="checkbox ml-3">
                                                    <Input id="checkbox1" type="checkbox"/>
                                                    <Label className="text-muted"
                                                           for="checkbox1">{RememberPassword}</Label>
                                                </div>
                                                <a style={{cursor: 'pointer'}} className="link"  onClick={forgotPassword}>{ForgotPassword}</a>
                                                <div className="mt-3">
                                                    <Button type="submit" color="primary" className="btn-block">Sign
                                                        In</Button>
                                                </div>
                                            </div>
                                            {/*<h6 className="text-muted mt-4 or">{"Or Sign in with"}</h6>*/}
                                            {/*<p className="mt-4 mb-0">{"Don't have account?"}<a className="ml-2" href="#javascript">{CreateAccount}</a></p>*/}
                                        </Form>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>


        </Container>
    );
}

export default withRouter(Logins);