import React, { Fragment, useEffect, useRef, useState } from "react";

import JqxTable from "../../../../../../share-components/table/JqxTable";
import { toast } from "react-toastify";
import * as ReactDOMServer from "react-dom/server";
import { useDispatch } from "react-redux";
import {
  findIndexes,
  numberWithCommasWithoutPounds,
  numberWithCurrency,
} from "../../../../../../utils/common";
import { shippingTaxAmount } from "../../../setting/calculation";
import OrderItemDiscount from "../../../../draft-order/form/content/itemDiscount";
import {
  setOrderInvoiceItemDiscountDialog,
  setOrderItemDiscountDialog,
} from "../../../../store/draftOrderSlice";
import OrderInvoiceItemDiscount from "../../../../draft-order/form/content/invoiceItemDiscount";

const SaleOrderProductInvoiceJqx = (props) => {
  const {
    setValue,
    trigger,
    getValues,
    orderedProducts,
    onChangeDialog,
    currency,
    isDiscountValueChange,
    setIsDiscountValueChange,
  } = props;
  let gridRef = useRef(null);
  let selectRef = useRef(null);
  const dispatch = useDispatch();
  const [orderData, setOrderData] = useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);

  // useEffect(() => {
  //     setOrderData(getValues('ordered_products'));
  // }, [getValues('ordered_products')])
  //const roleRegister = register("role", { required: true });
  const datafields = [
    { name: "id", type: "number" },
    { name: "order_quantity", type: "number" },
    { name: "product", type: "array" },
    { name: "pending_quantity", type: "number" },
    { name: "quantity", type: "number" },
    { name: "rate", type: "number" },
    { name: "is_tax", type: "boolean" },
    { name: "tax_amount", type: "number" },
    { name: "total_price", type: "number" },
    { name: "gross_price", type: "number" },
    { name: "item_discount", type: "array" },
    { name: "discount", type: "number" },
    { name: "sales_order_line_item_id", type: "number" },
    { name: "is_vat", type: "boolean" },
    { name: "is_selected", type: "boolean" },
    { name: "commodity", type: "string" },
    { name: "weight", type: "number" },
  ];

  const columns = [
    { datafield: "sales_order_line_item_id", hidden: true },
    { datafield: "is_selected", hidden: true },
    { datafield: "commodity", hidden: true },
    { datafield: "is_vat", hidden: true },
    { datafield: "weight", hidden: true },
    { datafield: "tax_amount", hidden: true },
    { datafield: "item_discount", hidden: true },
    { datafield: "discount", hidden: true },
    { datafield: "total_price", hidden: true },
    {
      text: "Product",
      datafield: "product",
      editable: false,
      columntype: "text",
      width: "38%",
      cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
        const productDetails = getValues("ordered_products")[row].product;
        const html = ReactDOMServer.renderToString(
          <div style={{ textAlign: "center", padding: 5 }}>
            {productDetails && (
              <div className="row">
                <div className="col-md-3">
                  <img
                    className="img-fluid"
                    src={productDetails.image}
                    width="40"
                    alt=""
                  />
                </div>
                <div className="col-md-9">
                  <div className="row">
                    <p className={"font-primary"}>{productDetails.name}</p>
                  </div>
                  <div className="row">
                    <p>{productDetails.option}</p>
                  </div>
                  <div className="row">
                    <p>{productDetails.sku}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
        return html;
      },
    },
    {
      text: "Quantity",
      datafield: "order_quantity",
      editable: false,
      columntype: "text",
      width: "14.5%",
      cellsalign: "right",
      align: "right",

      cellsrenderer(
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) {
        const productDetails = getValues("ordered_products")[row];
        const html = ReactDOMServer.renderToString(
          <div style={{ textAlign: "center", padding: 5 }}>
            <div className="row mt-2">
              <div className="col-12">
                <div className="col-md-12 p-1">
                  <div className="row">
                    <div className="col-4">
                      <p className="font-primary text-left font-weight-bolder">
                        Order Qty
                      </p>
                    </div>
                    <div className="col-8">
                      <p className="text-right">{rowdata.order_quantity}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <p className="font-primary text-left font-weight-bolder">
                        Pending Qty
                      </p>
                    </div>
                    <div className="col-8">
                      <p className="text-right">{rowdata.pending_quantity}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        return html;
      },
    },
    {
      text: "Invoice Qty",
      datafield: "quantity",
      editable: true,
      columntype: "text",
      width: "9%",
      cellsalign: "right",
      align: "right",
      cellbeginedit(row, datafield, columntype, val) {
        let found = 0;
        gridRef
          .getselectedrowindexes()
          .forEach((element) => (element === row ? found++ : ""));
        return found > 0;
      },
      cellclassname: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) {
        return "editable-column";
      },
      validation: function (cell, value) {
        const stock = gridRef.getcellvalue(cell.row, "pending_quantity");
        const selected = gridRef.getcellvalue(cell.row, "is_selected");

        const isDecimal = value % 1 !== 0;
        const isNegative = value < 0;
        if (value < 1 && selected) {
          toast.error("should be grater than 0");
          return {
            result: false,
            message: "should be grater than 0",
          };
        } else if (Number(stock) < Number(value) && selected) {
          toast.error("Should be less the Ordered Quantity");
          return {
            result: false,
            message: "Should be less the Ordered Quantity",
          };
        } else if (isDecimal || isNegative) {
          return {
            result: false,
            message: "Must be a non-negative integer.",
          };
        } else {
          return true;
        }
      },
    },
    {
      text: "Rate",
      datafield: "rate",
      editable: false,
      columntype: "text",
      width: "12%",
      cellsalign: "right",
      align: "right",
      cellsformat: "c2",
    },
    {
      text: "Gross Amount",
      datafield: "gross_price",
      editable: false,
      columntype: "text",
      width: "24%",
      height: "100px",
      cellsalign: "right",
      // cellsformat: 'c2',
      align: "center",
      cellsrenderer(
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) {
        const productDetails = getValues("ordered_products")[row];
        const html = ReactDOMServer.renderToString(
          <div style={{ textAlign: "center", padding: 5 }}>
            {productDetails && (
              <div className="row">
                <div className="col-12">
                  <div className="col-md-12 p-1">
                    <div className="row">
                      <div className="col-12">
                        <p className="font-primary text-center font-weight-bolder">
                          Gross{" "}
                          {rowdata.item_discount ? <>/ Discount </> : null} /
                          Tax
                        </p>
                      </div>
                      <div className="col-12">
                        <p className="text-center">
                          {numberWithCurrency(rowdata.total_price, currency)}
                          {rowdata.item_discount ? (
                            <>
                              / {numberWithCurrency(rowdata.discount, currency)}
                            </>
                          ) : null}
                          / {numberWithCurrency(rowdata.tax_amount, currency)}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <p className="font-primary text-center font-weight-bolder">
                          Sub Total :{" "}
                          {numberWithCurrency(rowdata.gross_price, currency)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
        return html;
      },
    },
  ];
  const getJqxRef = (jqx) => {
    if (jqx) {
      gridRef = jqx;

      setTimeout(() => {
        if (gridRef) {
          const myEle = document.getElementById(gridRef._id);
          if (myEle) {
            gridRef.updatebounddata();
          }
        }
      }, 1000);
    }
  };
  const onDelete = (data) => {
    setDataToHookForm();
  };

  const cellEdit = (event) => {
    const rowArgs = event.args;
    if (rowArgs.datafield == "quantity") {
      const pendingQuantity = gridRef.getcellvalue(
        rowArgs.rowindex,
        "pending_quantity"
      );

      const singlePrice = gridRef.getcellvalue(rowArgs.rowindex, "rate");
      const isTax = gridRef.getcellvalue(rowArgs.rowindex, "is_vat");
      if (rowArgs.value > pendingQuantity) {
        const quantity = gridRef.getcellvalue(rowArgs.rowindex, "quantity");
        rowArgs.value = quantity;
      }
      setTotalAmount(rowArgs.rowindex, rowArgs.value, singlePrice, isTax);
    }

    gridRef.setcellvalue(rowArgs.rowindex, rowArgs.datafield, rowArgs.value);
    setDataToHookForm();
    // onChangeDialog(false);
  };

  const setTotalAmount = (index, quantity, singlePrice, isVat) => {
    const totalPrice = quantity * singlePrice;

    const itemDiscount = gridRef.getcellvalue(index, "item_discount");
    let discountAmount = 0;

    if (itemDiscount) {
      const itemDiscountData = itemDiscount[0];
      const discountValue = itemDiscountData.value;
      if (itemDiscountData.discount_type == 2) {
        discountAmount = totalPrice * (discountValue / 100);
      } else {
        discountAmount = discountValue;
      }
    }
    const taxAmount = isVat ? (totalPrice - discountAmount) * 0.2 : 0;


    gridRef.setcellvalue(index, "discount", discountAmount);
    gridRef.setcellvalue(index, "total_price", totalPrice);
    gridRef.setcellvalue(index, "tax_amount", taxAmount);
    gridRef.setcellvalue(
      index,
      "gross_price",
      taxAmount + totalPrice - discountAmount
    );
  };
  const setDataToHookForm = () => {
    let orderData = [];
    let taxTotal = 0;
    let totalWeight = 0;
    const gridInformation = gridRef.getdatainformation();
    for (let i = 0; i < gridInformation.rowscount; i++) {
      const rowData = gridRef.getrowdata(i);
      orderData.push(rowData);
      if (rowData.is_selected) {
        taxTotal += rowData.tax_amount;
        totalWeight += rowData.weight * rowData.quantity;
      }
    }

    if (taxTotal) {
      const shippingTax = shippingTaxAmount(getValues);
      setValue("tax", {
        is_charge: true,
        rate_name: "Vat",
        percentage: taxTotal + shippingTax,
      });
    } else {
      setValue("tax", "");
    }
    weightCalculation(totalWeight);
    setValue("ordered_products", orderData);
    onChangeDialog(false);
  };

  const handelRowSelect = (event) => {
    const orderProducts = getValues("ordered_products");

    const selectedRowCount = gridRef.getselectedrowindexes().length;
    const totalRowCount = orderProducts.length;

    const orderData = [];
    let taxTotal = 0;
    const rowIndexes = gridRef.getselectedrowindexes();
    let totalWeight = 0;
    orderProducts.map(function (item, index) {
      const isSelected = rowIndexes.includes(index);

      const quantity = isSelected
        ? item.quantity
          ? item.quantity
          : item.pending_quantity
        : 0;
      const subTotal = item.rate * quantity;
      const taxAmount = item.is_vat ? subTotal * 0.2 : 0;

      setTotalAmount(index, quantity, item.rate, item.is_vat);
      orderData.push({
        ...item,
        quantity: quantity,
        total_price: subTotal,
        tax_amount: taxAmount,
        gross_price: taxAmount + subTotal,
        is_selected: isSelected,
      });
      if (isSelected) {
        taxTotal = taxTotal + taxAmount;
        totalWeight += quantity * item.weight;
      }
      gridRef.setcellvalue(index, "quantity", quantity);
      gridRef.setcellvalue(index, "is_selected", isSelected);
    });

    if (taxTotal) {
      setValue("tax", {
        is_charge: true,
        rate_name: "Vat",
        percentage: taxTotal,
      });
    } else {
      setValue("tax", "");
    }
    weightCalculation(totalWeight);
    setValue("ordered_products", orderData);
    onChangeDialog(false);
  };

  const weightCalculation = (totalWeight) => {
    const unit = getValues("net_unit_type") == 1 ? 1 : 1000;
    const totalNoOfBox = getValues("total_no_of_boxes")
      ? Number(getValues("total_no_of_boxes"))
      : 1;
    const totalBoxWeight = totalNoOfBox * 0.85;
    setValue("total_no_of_boxes", totalNoOfBox);
    setValue("total_net_weight", (totalWeight * unit).toFixed(2));
    setValue(
      "total_gross_weight",
      ((totalWeight + totalBoxWeight) * unit).toFixed(2)
    );
  };

  const cellClickFunction = (arg) => {
    const selectedRows = gridRef.getselectedrowindexes();
    const isRowSelected = selectedRows.includes(arg.rowindex);

    if (isRowSelected) {
      if (arg.datafield === "product") {
        const rowData = arg.row.bounddata;
        const data = {
          variant_id: rowData.product.variant,
          sku: rowData.product.sku,
          item_discount: rowData.item_discount,
          total_price: rowData.total_price.toFixed(2),
        };
        dispatch(
          setOrderInvoiceItemDiscountDialog({
            props: { isOpen: true },
            data: data,
          })
        );
        // setSelectedRowData(arg.row.bounddata);
      }

      onChangeDialog(false);
    }
  };

  const isValid = () => {
    const gridInformation = gridRef.getdatainformation();
    let isValid = true;
    for (let i = 0; i < gridInformation.rowscount; i++) {
      const rowData = gridRef.getrowdata(i);
      const stock = rowData.pending_quantity;
      if (rowData.is_selected) {
        if (rowData.quantity < 1) {
          isValid = false;
        } else if (Number(stock) < Number(rowData.quantity)) {
          isValid = false;
        }
      }
    }
    return isValid;
  };

  return (
    <Fragment>
      <div style={{ width: "100%" }}>
        {getValues("ordered_products").length > 0 && (
          <JqxTable
            deleteAll
            rowsheight={75}
            datafields={datafields}
            columns={columns}
            data={getValues("ordered_products")}
            myGrid={gridRef}
            getJqxRef={getJqxRef}
            autoheight={getValues("ordered_products").length < 7}
            scrollerbar
            height="500"
            onCellendedit={cellEdit}
            disableCreateRow
            onDelete={onDelete}
            onRowselect={handelRowSelect}
            onRowunselect={handelRowSelect}
            selectionmode={"checkbox"}
            showheader={true}
            showheadermenu={true}
            isDelete={false}
            currency={currency}
            cellClickDataField={["product"]}
            cellClickFunction={cellClickFunction}
            selectedrowindexes={findIndexes(
              getValues("ordered_products"),
              (item) => item.is_selected
            )}
          />
        )}
      </div>
      <OrderInvoiceItemDiscount
        rowdata={selectedRowData}
        orderGetValues={getValues}
        orderSetValue={setValue}
        orderTrigger={trigger}
        onChangeDialog={onChangeDialog}
        isDiscountValueChange={isDiscountValueChange}
        setDataToHookForm={setDataToHookForm}
      ></OrderInvoiceItemDiscount>
    </Fragment>
  );
};

function areEqual(prevProps, nextProps) {
  const prev = {
    productChange: prevProps.isProductValueChange,
  };
  const next = {
    productChange: nextProps.isProductValueChange,
  };
  return JSON.stringify(prev) == JSON.stringify(next);
}

export default React.memo(SaleOrderProductInvoiceJqx, areEqual);
