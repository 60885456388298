import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../../layout/breadcrumb";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import PreLoader from "../../../../share-components/ui/preLoader";
import {
  Modal,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Label,
} from "reactstrap";
import salesOrderReturnReducer from "../../store";

import { mapSearchCustomerAutoSuggestion } from "../../../../utils/mapper";
import { customerAutoSuggestionApi } from "../../../../services/order";
import withReducer from "../../../../store/withReducer";
import {
  getAllSaleOrderInvoices,
  selectAllSaleInvoice,
} from "../../store/saleOrderInvoiceSlice";
import {
  getPendingReturnRequest,
  selectAllPendingReturns,
} from "../../store/saleOrderReturnSlice";
import { setSettings, getSalesOrderFilters } from "../../store/salesOrderSlice";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import DatePicker from "../../../../share-components/ui/DateRangePicker";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { DateRangePicker } from "rsuite";

const { afterToday } = DateRangePicker;

const dataMenu = [
  {
    type: 0,
    text: "Order",
  },
  {
    type: 1,
    text: "Pending Return",
    link: "",
  },
];

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 50,
    border: "none",
  }),
};

const SalesOrderPendingReturn = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [brand, setBrand] = useState(null);
  const [search, setSearch] = useState(null);
  const [name, setName] = useState([]);
  const [modal, setModal] = useState(false);
  const [invoiceXeroNumber, setInvoiceXeroNumber] = useState([]);
  const [orderNumber, setOrderNumber] = useState([]);
  const [customer, setCustomer] = useState(null);
  let history = useHistory();
  const [loading, isSetLoading] = useState(false);

  const saleOrderReturnState = useSelector(
    ({ order }) => order.salesOrderReturn
  );
  const salesOrderState = useSelector(({ order }) => order.salesOrder);

  const [tableLoading, isSetTableLoading] = useState(false);
  const reduxState = useSelector((state) => state);
  const userState = useSelector(({ authReducer }) => authReducer.data);
  const currentUser = useSelector(({ authReducer }) => authReducer.data.user);
  const pendingReturns = useSelector(selectAllPendingReturns);
  const TodayDate = new Date();
  const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
  const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    getData();
  }, [
    page,
    customer,
    orderNumber,
    invoiceXeroNumber,
    dateRange,
    startDate,
    endDate,
  ]);

  const getData = () => {
    const data = {
      page: page,
      customer: customer,
      dateRange: dateRange,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      order_number: orderNumber,
      invoice_xero_number: invoiceXeroNumber,
      pageSize: 10,
    };
    dispatch(getPendingReturnRequest(data)).then((res) => {
      if (!res.error) isSetLoading(false);
    });
  };

  useEffect(() => {
    dispatch(getSalesOrderFilters()).then((res) => {
      if (!res.error) {
        dispatch(setSettings(res.payload));
      }
    });
  }, []);

  const tableColumns = [
    {
      name: "Invoice #",
      selector: (row) => row.invoice_no,
      sortable: true,
      center: false,
    },
    {
      name: "Customer",
      selector: (row) => row.customer,
      sortable: true,
      center: false,
    },

    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      center: false,
    },
    {
      name: "Created By",
      selector: (row) => row.created_by,
      sortable: true,
      center: false,
    },

    {
      name: "Action",
      selector: (row) => (
        <div>
          <a
            onClick={() => {
              onEdit(row);
            }}
          >
            <i
              className="fa fa-undo"
              style={{
                width: 25,
                fontSize: 16,
                padding: 11,
                color: "rgb(255, 187, 51)",
              }}
            ></i>
          </a>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];

  const onEdit = (row) => {
    history.push("/order/pending-return/" + row.id + "/edit");
  };
  const onView = (row) => {
    history.push(
      "order/sale/" + row.sales_order_id + "/billing/invoice/" + row.id
    );
  };

  const logHistory = () => {
    document.querySelector(".customizer-contain").classList.add("open");
  };

  const typePromiseOption = (inputValue) =>
    new Promise((resolve) => {
      const data = {
        value: inputValue,
      };
      setTimeout(() => {
        resolve(
          mapSearchCustomerAutoSuggestion(customerAutoSuggestionApi(data))
        );
      });
    });

  const handleCustomerChange = (selectedOption) => {
    setCustomer(selectedOption ? selectedOption.value : null);
  };

  return (
    <Fragment>
      <Container fluid={true} className="product-wrapper">
        {loading ? (
          <>
            <PreLoader></PreLoader>
          </>
        ) : (
          <>
            <Breadcrumb data={dataMenu} title="Pending Return" />
            <div className="product-grid">
              <div className="feature-products">
                <Row>
                  <Col md={3}>
                    <Form>
                      <FormGroup className="row">
                        <Col sm="12">
                          <DatePicker
                            dateRange={dateRange}
                            dateRangeChange={(update) => {
                              setDateRange(update);
                            }}
                            disabledDate={afterToday()}
                            defaultValue={[TodayDate, sevenDays]}
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>

                  <Col md={3}>
                    <AsyncSelect
                      isClearable
                      cacheOptions
                      defaultOptions
                      styles={customStyles}
                      loadOptions={typePromiseOption}
                      onChange={handleCustomerChange}
                      placeholder="Select Customer"
                    />
                  </Col>

                  <Col md={3}>
                    <Form>
                      <FormGroup className="m-0">
                        <input
                          onChange={(e) => {
                            setInvoiceXeroNumber(e.target.value);
                          }}
                          type="text"
                          className="form-control border"
                          placeholder="Search By Invoice No. or Xero ID"
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>

                  <Col md={3}>
                    <Form>
                      <FormGroup className="m-0">
                        <input
                          onChange={(e) => {
                            setOrderNumber(e.target.value);
                          }}
                          type="text"
                          className="form-control border"
                          placeholder="Search By Order No."
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </div>
            </div>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    {/* <Clock
                      style={{
                        width: "16px",
                        height: "16px",
                        position: "fixed",
                        right: "40px",
                      }}
                      className="mr-1"
                      onClick={logHistory}
                    /> */}
                    <div
                      style={{
                        position: "fixed",
                        top: "50%",
                        zIndex: 10,
                        right: 0,
                      }}
                    >
                      {/* <LogActivity log="Sales Order"></LogActivity> */}
                    </div>
                    <div className="table-responsive product-table mt-4">
                      <CommonDataTable
                        headerColumns={tableColumns}
                        gridData={pendingReturns}
                        pagination
                        progressPending={tableLoading}
                        paginationServer
                        paginationTotalRows={saleOrderReturnState.noOfTableRows}
                        paginationPerPage={10}
                        noAction
                        paginationComponentOptions={{
                          noRowsPerPage: true,
                        }}
                        onChangePage={(page) => setPage(page)}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </Fragment>
  );
};
export default withReducer([
  {
    salesOrderReturn: salesOrderReturnReducer,
  },
])(SalesOrderPendingReturn);
