import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Container, Table } from "reactstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import withReducer from "../../../../../store/withReducer";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import PreLoader from "../../../../../share-components/ui/preLoader";
import salesOrderReturnItemReducer from "../../../store";
import {
  downloadSalesOrderCreditNotePdf,
  editSalesOrderReturn,
  indexSalesOrderReturnCreditNotes,
  showSalesOrderReturnCreditNotes,
} from "../../../store/saleOrderReturnItemSlice";
import Breadcrumb from "../../../../../layout/breadcrumb";
import Slider from "react-slick";
import {
  CarouseNavigation,
  numberWithCommasWithoutPounds,
  numberWithCurrency,
} from "../../../../../utils/common";

const dataMenu = [
  {
    type: 1,
    text: "Return Form",
    link: "/order/sale/return",
  },
  {
    type: 0,
    text: "Return View",
  },
];

const defaultValues = {
  id: "",
  ordered_products: [],
  credit_notes: [],
  return_values: [],
  total_amount: "",
  shipping_charges: "",
  discount: "",
  tax: "",
  currency: "£",
};

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  nextArrow: <CarouseNavigation icon="fa-arrow-right" />,
  prevArrow: <CarouseNavigation icon="fa-arrow-left" />,
};

const ReturnViewForm = (props) => {
  const { return_id, id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [isCreditNote, setIsCreditNote] = useState(false);
  let history = useHistory();
  const { id: creditNoteId } = useParams();
  const { setValue, getValues } = useForm({
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    let isOrderedProductsLoaded = false;
    let isCreditNotesLoaded = false;
    let isIndexCreditNotesLoaded = false;

    dispatch(editSalesOrderReturn(return_id)).then((res) => {
      if (!res.error) {
        setValue("ordered_products", res.payload);
        if (res.payload.length > 0) {
          isOrderedProductsLoaded = true;
        }

        if (isOrderedProductsLoaded) {
          // setLoading(false);
        }
      }
    });

    dispatch(showSalesOrderReturnCreditNotes(return_id)).then(
      (creditNotesRes) => {
        if (!creditNotesRes.error) {
          setValue("credit_notes", creditNotesRes.payload);
          if (creditNotesRes.payload.length > 0) {
            isCreditNotesLoaded = true;
          }
        }
      }
    );

    if (creditNoteId) {
      setLoading(true);
      dispatch(indexSalesOrderReturnCreditNotes(creditNoteId)).then(
        (indexRes) => {
          if (!indexRes.error) {
            setValue("return_values", indexRes.payload);

            if (indexRes.payload.credit_note_id) {
              setIsCreditNote(true);
            } else {
              setIsCreditNote(false);
            }

            setLoading(false);
          }
        }
      );
    }
  }, [dispatch, id]);

  const onDownload = () => {
    processPDFDownload("Credit Note", getValues("return_values")?.id);
  };

  const processPDFDownload = (name, orderId) => {
    const data = {
      id: orderId,
      params: {
        name: name,
      },
    };
    dispatch(downloadSalesOrderCreditNotePdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${getValues("return_values")?.credit_note_id}_${name.replace(
          /\s+/g,
          "-"
        )}_${new Date().toJSON().slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <Fragment>
      {loading ? (
        <>
          <PreLoader></PreLoader>
        </>
      ) : (
        <>
          <Breadcrumb title="Return View" data={dataMenu} />

          <Container fluid={true} className="product-wrapper">
            <Row className="mt-3">
              {getValues("ordered_products").length > 0 && (
                <Col md={12} className="float-right">
                  <Link
                    className="btn btn-primary pull-right ml-2"
                    to={`${process.env.PUBLIC_URL}/order/sale/return/${return_id}/edit`}
                  >
                    Create Return
                  </Link>
                </Col>
              )}
              <Col
                md={12}
                className={
                  getValues("credit_notes").length > 5 ? "slick-arrow" : ""
                }
              >
                <Slider {...settings}>
                  {getValues("credit_notes").map((item, i) => (
                    <div className="p-1 " key={i}>
                      <Card className={id == item.id ? "active-invoice" : ""}>
                        <CardBody>
                          <Link
                            to={`${process.env.PUBLIC_URL}/order/sale/return/${return_id}/view/${item.id}`}
                          >
                            <p className="font-weight-bolder f-14">
                              Return ID : {item.id}
                            </p>
                          </Link>
                          <p>Date : {item.date}</p>
                        </CardBody>
                      </Card>
                    </div>
                  ))}
                </Slider>
              </Col>
            </Row>

            {isCreditNote && (
              <Row className="mt-3">
                <Col md={12}>
                  <Card>
                    <CardBody style={{ padding: "24px" }}>
                      <>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                          Credit Note ID :{" "}
                          {getValues("return_values")?.credit_note_id}{" "}
                        </span>
                      </>
                      <a
                        className="btn btn-success pull-right ml-2"
                        onClick={onDownload}
                      >
                        Download Credit Note
                      </a>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}

            <Card>
              <CardBody style={{ padding: "24px" }}>
                <Row className="mb-3">
                  <Col md="12">
                    <h6>RMA Details</h6>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <span className="f-w-600">Sales Order ID : </span>
                    <span className="f-w-100 ">
                      {getValues("return_values")?.sales_order_id}
                    </span>
                  </Col>
                  <Col md={3}>
                    <span className="f-w-600">Invoice ID : </span>
                    <span className="f-w-100 ">
                      {getValues("return_values")?.invoice_id}
                    </span>
                  </Col>
                  <Col md={3}>
                    <span className="f-w-600">Customer : </span>
                    <span className="f-w-100 ">
                      {getValues("return_values")?.customer}
                    </span>
                  </Col>
                  <Col md={3}>
                    <span className="f-w-600">Created By : </span>
                    <span className="f-w-100 ">
                      {getValues("return_values")?.created_by}
                    </span>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody style={{ padding: "24px" }}>
                <Col md={12}>
                  <div className="table-responsive w-100">
                    <Table>
                      <thead className="thead-light">
                        <tr>
                          <th style={{ width: "20%" }}>Product</th>
                          <th style={{ textAlign: "center", width: "10%" }}>
                            Qty
                          </th>
                          <th style={{ textAlign: "center", width: "10%" }}>
                            Rate
                          </th>
                          <th style={{ textAlign: "center", width: "10%" }}>
                            Amount
                          </th>
                          <th style={{ textAlign: "center", width: "15%" }}>
                            Reason
                          </th>
                          <th style={{ textAlign: "center", width: "10%" }}>
                            Re Stock
                          </th>
                          <th style={{ textAlign: "center", width: "10%" }}>
                            Warehouse Declined
                          </th>
                          <th style={{ textAlign: "center", width: "10%" }}>
                            Accounts Declined
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {getValues("return_values")?.ordered_products.map(
                          (item, i) => (
                            <tr key={i}>
                              <td>
                                <div className="row">
                                  <div className="col-md-3">
                                    <img
                                      className="img-fluid"
                                      src={item.product.image}
                                      width="40"
                                      alt=""
                                    />
                                  </div>
                                  <div className="col-md-9">
                                    <div className="row">
                                      <p className={"font-primary"}>
                                        {item.product.name}
                                      </p>
                                    </div>
                                    <div className="row">
                                      <p>{item.product.option}</p>
                                    </div>
                                    <div className="row">
                                      <p>{item.product.sku}</p>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div style={{ marginTop: "1rem" }}>
                                  {item.quantity}
                                </div>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div style={{ marginTop: "1rem" }}>
                                  {getValues("return_values")?.currency.symbol}{" "}
                                  {numberWithCommasWithoutPounds(item.rate)}{" "}
                                </div>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div style={{ marginTop: "1rem" }}>
                                  {getValues("return_values")?.currency.symbol}{" "}
                                  {numberWithCommasWithoutPounds(item.amount)}{" "}
                                </div>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div style={{ marginTop: "1rem" }}>
                                  {item.return_reason}
                                </div>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div style={{ marginTop: "1rem" }}>
                                  {item.re_stock}
                                </div>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div style={{ marginTop: "1rem" }}>
                                  {item.warehouse_declined}
                                </div>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div style={{ marginTop: "1rem" }}>
                                  {item.account_declined}
                                </div>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </CardBody>
            </Card>

            <Row>
              <Col md={8}>
                <Card>
                  <CardBody style={{ padding: "24px" }}>
                    <Row>
                      <Col md="12">
                        <h6 className="pl-4 pb-3">Payment</h6>
                      </Col>
                    </Row>
                    <Col md="12">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td>Total Credit Amount</td>
                            <td></td>
                            <td align="right">
                              {/* {getValues("return_values")?.currency.symbol}{" "}
                              {getValues("return_values")?.total_amount} */}
                              {numberWithCurrency(
                                getValues("return_values")?.total_amount,
                                getValues("return_values")?.currency.symbol
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Discount</td>
                            <td>
                              {getValues("discount")
                                ? getValues("discount")?.reason
                                  ? getValues("discount")?.reason
                                  : "----"
                                : "----"}
                            </td>
                            <td align="right">
                              {/* {getValues("return_values")?.currency.symbol}{" "}
                              {
                                getValues("return_values")
                                  ?.total_discount_amount
                              } */}
                               {numberWithCurrency(
                                getValues("return_values")?.total_discount_amount,
                                getValues("return_values")?.currency.symbol
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Sub Total Credit</td>
                            <td></td>
                            <td align="right">
                              {/* {getValues("return_values")?.currency.symbol}{" "}
                              {getValues("return_values")?.sub_credit_amount} */}
                               {numberWithCurrency(
                                getValues("return_values")?.sub_credit_amount,
                                getValues("return_values")?.currency.symbol
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Shipment</td>
                            <td>
                              {getValues("shipping_charges")
                                ? getValues("shipping_charges")?.rate_name
                                  ? getValues("shipping_charges")?.rate_name
                                  : "----"
                                : "----"}
                            </td>
                            <td align="right">
                              {/* {getValues("return_values")?.currency.symbol}{" "}
                              {
                                getValues("return_values")
                                  ?.total_shipping_amount
                              } */}
                               {numberWithCurrency(
                                getValues("return_values")?.total_shipping_amount,
                                getValues("return_values")?.currency.symbol
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Tax</td>
                            <td>
                              {getValues("tax")
                                ? getValues("tax")?.reason
                                  ? getValues("tax")?.reason
                                  : "----"
                                : "----"}
                            </td>
                            <td align="right">
                              {/* {getValues("return_values")?.currency.symbol}{" "}
                              {getValues("return_values")?.total_tax_amount} */}
                              {numberWithCurrency(
                                getValues("return_values")?.total_tax_amount,
                                getValues("return_values")?.currency.symbol
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="2">Net Total Credit</td>
                            <td align="right">
                              {/* {getValues("return_values")?.currency.symbol}
                              {getValues("return_values")?.total_net_amount} */}
                              {numberWithCurrency(
                                getValues("return_values")?.total_net_amount,
                                getValues("return_values")?.currency.symbol
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </Fragment>
  );
};

export default withReducer([
  {
    SalesOrderReturnItem: salesOrderReturnItemReducer,
  },
])(ReturnViewForm);
