export const packCalculation = (value = null, datafield = null, dataSizeRatio, noOfPack = 0, isPack, isSingle = false) => {
    console.log(noOfPack,"Fafa");
    if (dataSizeRatio.length > 0) {
        const ratio = datafield ? dataSizeRatio.find(x => x.id == datafield).ratio : dataSizeRatio[0].ratio;
        const pack = value ? value / Number(ratio) : 0;
        let minimumPack = Math.floor(pack);
        let totalAggregate = 0;
        let totalPack = 0;
        let totalSingle = 0;
        let totalPieceInPack = 0;


        dataSizeRatio.map((rationItem, rationIndex) => {
            if (datafield != rationItem.id) {
                let otherSizeTotal = rationItem.quantity;
                let otherSizePack = ((otherSizeTotal ? otherSizeTotal : 0)) / Number(rationItem.ratio);
                minimumPack = Math.floor(minimumPack == "NAN" || minimumPack > otherSizePack ? otherSizePack : minimumPack);
            }
        });

        const actualPack = minimumPack;
        let packSingle=0;

        if (isSingle) {
             packSingle = noOfPack??0;

            if (packSingle > minimumPack) {
                packSingle = minimumPack;
            }
            minimumPack = minimumPack - packSingle;

        } else {
            if (minimumPack > 0 && !isPack) {
                minimumPack = minimumPack > noOfPack ? noOfPack : minimumPack;
            }
            packSingle = actualPack - minimumPack;
        }
        const variantQuantities = [];
        dataSizeRatio.map((rationItem, rationIndex) => {
            const totalValue = datafield == rationItem.id ? value : rationItem.quantity;
            const pack = minimumPack * Number(rationItem.ratio);
            const single = (totalValue ? totalValue : 0) - (minimumPack * Number(rationItem.ratio));
            totalAggregate = totalAggregate + Number((totalValue ? totalValue : 0));
            totalPack = totalPack + pack;
            totalSingle = totalSingle + single;
            totalPieceInPack = totalPieceInPack + Number(rationItem.ratio);
            variantQuantities.push({
                size: rationItem.id,
                variant_id: rationItem.variant_id,
                total: totalValue,
                pack: pack,
                single: single
            })
        });
        let totalRow = {type: "total", total: totalAggregate};
        let packRow = {type: "pack", total: totalPack};
        let singleRow = {type: "single", total: totalSingle};

        variantQuantities.map((item, index) => {
            totalRow[item.size] = {variant_id: item.variant_id, quantity: item.total};
            packRow[item.size] = {variant_id: item.variant_id, quantity: item.pack};
            singleRow[item.size] = {variant_id: item.variant_id, quantity: item.single}
        });

        const variantQuantity = [];
        variantQuantity.push(totalRow);
        variantQuantity.push(packRow);
        variantQuantity.push(singleRow);

        console.log(packSingle,minimumPack,"----test  test 33-----")
        return {
            variants: variantQuantity,
            pack: minimumPack,
            noOfSingle: packSingle,
            totalPack: minimumPack + packSingle
        }
        // subGrid.current[index].setcellvalue(0, 'total_qty', totalAggregate);
        // subGrid.current[index].setcellvalue(1, 'total_qty', totalPack);
        // subGrid.current[index].setcellvalue(2, 'total_qty', totalSingle);
        // gridRef.setcellvalue(index, 'pack_qty', minimumPack);
        // gridRef.setcellvalue(index, 'no_of_total_pack', Number(minimumPack) + Number(packSingle));
        // gridRef.setcellvalue(index, 'no_piece_in_pack', totalPack);
        // gridRef.setcellvalue(index, 'place_in_packs', totalPieceInPack);
        // gridRef.setcellvalue(index, 'pack_for_single', packSingle);


    }


}