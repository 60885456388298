import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../layout/breadcrumb";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import PreLoader from "../../../share-components/ui/preLoader";
import LogActivity from "../../log";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap";
import fulfillmentReducer from "../store";
import {
  getAllFulfillments,
  selectFulfillment,
} from "../store/saleOrderFulfillmentSlice";
import { setSettings, getSalesOrderFilters } from "../store/salesOrderSlice";
import {
  fulfillmentResponseToTable,
  mapSearchCustomerAutoSuggestion,
} from "../../../utils/mapper";
import { customerAutoSuggestionApi } from "../../../services/order";
import withReducer from "../../../store/withReducer";
import moment from "moment";
import DatePicker from "../../../share-components/ui/DateRangePicker";
import { DateRangePicker } from "rsuite";
import Select from "react-select";
import AsyncSelect from "react-select/async";

const { afterToday } = DateRangePicker;

const dataMenu = [
  {
    type: 0,
    text: "Order",
  },
  {
    type: 1,
    text: "Fulfillments",
    link: "",
  },
];

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 50,
    border: "none",
  }),
};

const Fulfillment = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [brand, setBrand] = useState(null);
  const [search, setSearch] = useState(null);
  const [name, setName] = useState([]);
  const [orderNumber, setOrderNumber] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [fulfillmentNumber, setFulfillmentNumber] = useState(null);
  const [deliveryPartner, setDeliveryPartner] = useState(null);
  let history = useHistory();
  const [loading, isSetLoading] = useState(true);
  const fulfillments = useSelector(selectFulfillment);
  const saleOrderFulfillmentState = useSelector(
    ({ order }) => order.salesOrderFulfilment
  );
  const salesOrderState = useSelector(({ order }) => order.salesOrder);
  const userState = useSelector(({ authReducer }) => authReducer.data);
  const currentUser = useSelector(({ authReducer }) => authReducer.data.user);
  const [tableLoading, isSetTableLoading] = useState(false);
  const TodayDate = new Date();
  const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
  const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
  const [startDate, endDate] = dateRange;
  const [createdDate, setCreatedDate] = useState([sevenDays, TodayDate]);
  const [createdStartDate, createdEndDate] = createdDate;

  const tableColumns = [
    {
      name: "Fulfillment ID",
      selector: (row) => row.fulfillment_no,
      sortable: true,
      center: false,
    },
    {
      name: "Order ID",
      selector: (row) => row.order_id,
      sortable: true,
      center: false,
    },
    {
      name: "Customer",
      selector: (row) => row.customer,
      sortable: true,
      center: false,
    },
    {
      name: "Created Date",
      selector: (row) => row.created_date,
      sortable: true,
      center: false,
    },
    {
      name: "Delivery Partner",
      selector: (row) => row.delivery_partner,
      sortable: true,
      center: false,
    },
    {
      name: "Tracking ID",
      selector: (row) => row.tracking_id,
      sortable: true,
      center: false,
    },
    {
      name: "Created By",
      selector: (row) => row.created_by,
      sortable: true,
      center: false,
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          {userState.user &&
            (userState.user.permission.indexOf("View Seller") !== -1 ? (
              <a
                onClick={() => {
                  onView(row);
                }}
              >
                <i
                  className="fa fa-eye"
                  style={{
                    width: 25,
                    fontSize: 16,
                    padding: 11,
                    color: "rgb(51, 153, 255)",
                  }}
                ></i>
              </a>
            ) : (
              ""
            ))}
          {/* {userState.user &&
            (userState.user.permission.indexOf("Update Seller") !== -1 ? (
              <a
                onClick={() => {
                  onEdit(row);
                }}
              >
                <i
                  className="fa fa-pencil"
                  style={{
                    width: 25,
                    fontSize: 16,
                    padding: 11,
                    color: "rgb(40, 167, 69)",
                  }}
                ></i>
              </a>
            ) : (
              ""
            ))} */}
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];

  useEffect(() => {
    getData();
  }, [
    page,
    brand,
    search,
    customer,
    orderNumber,
    deliveryPartner,
    fulfillmentNumber,
    dateRange,
    createdStartDate,
    createdEndDate,
  ]);

  const getData = () => {
    const data = {
      page: page,
      pageSize: 10,
      brand: brand,
      search: search,
      customer: customer,
      delivery_partner: deliveryPartner,
      order_number: orderNumber,
      fulfillment_number: fulfillmentNumber,
      dateRange: dateRange,
      created_start_date: moment(startDate).format("YYYY-MM-DD"),
      created_end_date: moment(endDate).format("YYYY-MM-DD"),
    };
    dispatch(getAllFulfillments(data)).then((res) => {
      console.log(res);
      if (!res.error) isSetLoading(false);
    });
  };

  useEffect(() => {
    dispatch(getSalesOrderFilters()).then((res) => {
      if (!res.error) {
        dispatch(setSettings(res.payload));
      }
    });
  }, []);

  const onView = (row) => {
    history.push(
      "sale/" + row.sales_order_id + "/billing/fulfillment/" + row.id
    );
  };

  const typePromiseOption = (inputValue) =>
    new Promise((resolve) => {
      const data = {
        value: inputValue,
      };
      setTimeout(() => {
        resolve(
          mapSearchCustomerAutoSuggestion(customerAutoSuggestionApi(data))
        );
      });
    });

  const handleCustomerChange = (selectedOption) => {
    setCustomer(selectedOption ? selectedOption.value : null);
  };

  return (
    <Fragment>
      <Container fluid={true} className="product-wrapper">
        {loading ? (
          <>
            <PreLoader></PreLoader>
          </>
        ) : (
          <>
            <Breadcrumb data={dataMenu} title="Fulfillments" />
            <div className="product-grid">
              <div className="feature-products">
                <Row>
                  <Col md={3}>
                    <Form>
                      <FormGroup className="row">
                        <Col sm="12">
                          <DatePicker
                            dateRange={dateRange}
                            dateRangeChange={(update) => {
                              setDateRange(update);
                            }}
                            disabledDate={afterToday()}
                            defaultValue={[TodayDate, sevenDays]}
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>

                  <Col md={3}>
                    <AsyncSelect
                      isClearable
                      cacheOptions
                      defaultOptions
                      styles={customStyles}
                      loadOptions={typePromiseOption}
                      onChange={handleCustomerChange}
                      placeholder="Select Customer"
                    />
                  </Col>

                  <Col md={3}>
                    <Select
                      className="select2-filter border"
                      isClearable
                      cacheOptions
                      styles={customStyles}
                      placeholder="Select Delivery Partner"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      onChange={(e) => {
                        setDeliveryPartner(e ? e.id : null);
                        setPage(1);
                      }}
                      options={
                        salesOrderState.settings
                          ? salesOrderState.settings.delivery_partner
                          : null
                      }
                    />
                  </Col>

                  <Col md={3}>
                    <Form>
                      <FormGroup className="m-0">
                        <input
                          onChange={(e) => {
                            setFulfillmentNumber(e.target.value);
                          }}
                          type="text"
                          className="form-control border"
                          placeholder="Search By Fulfillment No."
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>

                  <Col md={3}>
                    <Form>
                      <FormGroup className="m-0">
                        <input
                          onChange={(e) => {
                            setOrderNumber(e.target.value);
                          }}
                          type="text"
                          className="form-control border"
                          placeholder="Search By Order No."
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </div>
            </div>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div
                      style={{
                        position: "fixed",
                        top: "50%",
                        zIndex: 10,
                        right: 0,
                      }}
                    >
                      {/* <LogActivity log="Sales Order Fulfillment"></LogActivity> */}
                    </div>
                    <div className="table-responsive product-table mt-4">
                      <CommonDataTable
                        headerColumns={tableColumns}
                        gridData={fulfillmentResponseToTable(fulfillments)}
                        pagination
                        progressPending={tableLoading}
                        paginationServer
                        paginationTotalRows={
                          saleOrderFulfillmentState.noOfTableRows
                        }
                        paginationPerPage={10}
                        noAction
                        paginationComponentOptions={{
                          noRowsPerPage: true,
                        }}
                        onChangePage={(page) => setPage(page)}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </Fragment>
  );
};

export default withReducer([
  {
    fulfillment: fulfillmentReducer,
  },
])(Fulfillment);
