import React, { useMemo } from 'react';
import {useDropzone} from 'react-dropzone';
import {toast} from "react-toastify";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const DropZone = (props) => {
    const {onChangeFile, extension, multiple} = props;

    const onDrop = (acceptedFiles,reject) => {
              onChangeFile(acceptedFiles,reject);

    };

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({accept: extension, multiple: multiple, onDrop,maxSize:  104857600 });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <div className="container">

            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                <p>Add media or drop file</p>
            </div>
        </div>
    );
}

export default DropZone;
