import React, {Fragment, useState} from 'react';
import {Card, CardBody, Col, FormGroup, Input, Label, Row, Table} from "reactstrap";
import ReactPaginate from "react-paginate";


function SearchVariantTable(props) {
    const {item, index,selectedVariantIndex,append,remove,selectedProductIndex,getValues,setValue,product,productAppend,trigger} = props;

    return (
        <tr key={index}>
            <td style={{width: '5%'}}><input
                type={"checkbox"}
                checked={selectedVariantIndex!==-1}
                 onChange={(e) => {
                       if(e.target.checked){
                           if(selectedProductIndex!==-1){
                               const existVariants=getValues(`products.${selectedProductIndex}.variants`);
                               existVariants.push(item);
                               setValue(`products.${selectedProductIndex}`,{...product,variants:existVariants});
                               trigger();
                           }else{
                               productAppend({...product,variants:[item]})
                           }
                       }else{
                           remove(selectedVariantIndex);
                       }
                 }}
            /></td>

            <td style={{
                width: '50%',
                textAlign: 'left'
            }}>{item.variant_name}</td>
            <td style={{textAlign: 'right'}}>{item.stock}</td>
            <td style={{textAlign: 'right'}}>£ {item.price}</td>
        </tr>
    );
}

export default SearchVariantTable;