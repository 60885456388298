import React, {useState} from 'react';
import {Card, CardBody, Col, FormGroup, Input, Label, Row, Table} from "reactstrap";
import ReactPaginate from "react-paginate";


function AdjustmentReplace(props) {
    const {item, index, errors, register, setValue,getPackCalculation,handleCheckBin,getValues,trigger} = props;

    return (
        <div>
            <input
                style={{height:25}}
                value={index != -1?getValues(`inventories.${index}.quantity`):''}
                onFocus={()=>{
                    handleCheckBin(item);
                }}
                onChange={(e) => {
                    setValue(`inventories.${index}.quantity`, e.target.value);
                    trigger(`inventories.${index}.quantity`);
                    getPackCalculation(true,true);

                }}
                type="number"
                className={`form-control w-50  ${errors.inventories?.[index]?.quantity ? 'is-invalid' : ''}`}
                placeholder=''/>
            <div className="invalid-feedback"
            >{errors.inventories?.[index]?.quantity?.message}</div>
        </div>
    );
}

export default AdjustmentReplace;