import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import { Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import salesOrderReducer from "./../../store";
import withReducer from "../../../../store/withReducer";
import PreLoader from "../../../../share-components/ui/preLoader";
import { Link, useParams } from "react-router-dom";
import BillIngInvoice from "./invoice";
import BillIngFulfillment from "./fulfillment";

// const dataMenu = [
//   {
//     type: 1,
//     text: "Invoice",
//     link: "/order/invoices",
//   },
//   {
//     type: 0,
//     text: "Invoice View",
//   },
// ];

const BillingForm = (props) => {
  const { id, order_id, slug } = useParams();
  const dispatch = useDispatch();
  const [loading, isSetLoading] = useState(false);
  const salesOrderState = useSelector(({ order }) => order.salesOrder);
  const [isEditable, setIsEditable] = useState(!id);

  const dataMenu =

      [
          { type: 1, text:   slug === "invoice"?"Invoice":"Fulfillment", link: slug === "invoice"? "/order/invoices":"/order/fulfillments"  },
          { type: 0, text: slug === "invoice"?"Invoice View":"Fulfillment View" },
        ];

  return (
    <Fragment>
      <Breadcrumb
        title={`Order# : ${salesOrderState.orderData?.order_no}`}
        data={dataMenu}
      />
      <Container fluid={true} className="product-wrapper">
        {loading ? (
          <>
            <PreLoader></PreLoader>
          </>
        ) : (
          <>
            <Row>
              <Col md={12}>
                <Link
                  to={`${process.env.PUBLIC_URL}/order/sale/${order_id}/billing/invoice`}
                  className={`btn ${
                    slug == "invoice"
                      ? "btn btn-primary"
                      : "btn-outline-primary"
                  }`}
                >
                  {" "}
                  Invoice{" "}
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/order/sale/${order_id}/billing/fulfillment`}
                  className={`btn ${
                    slug == "fulfillment"
                      ? "btn btn-primary"
                      : "btn-outline-primary"
                  }`}
                >
                  Fulfillment
                </Link>
              </Col>
            </Row>
            <div>
              {slug == "invoice" ? (
                <BillIngInvoice order_id={order_id} id={id}></BillIngInvoice>
              ) : (
                <BillIngFulfillment
                  order_id={order_id}
                  id={id}
                ></BillIngFulfillment>
              )}
            </div>
          </>
        )}
      </Container>
    </Fragment>
  );
};

export default withReducer([{ salesOrder: salesOrderReducer }])(BillingForm);
