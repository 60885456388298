import React, {Fragment, useEffect, useRef, useState} from "react";
import * as ReactDOM from 'react-dom';
import {Badge, Button, Card, CardBody, Col, FormGroup, Input, Label} from "reactstrap";
import Select from "react-select";
import JqxTable from "../../../../share-components/table/JqxTable";
import {toast} from "react-toastify";
import ButtonLoader from "../../../../share-components/ui/buttonLoader";
import {useDispatch, useSelector} from "react-redux";
import {updateInventory} from "../../store/inventorySlice";


const VariationInventoryJqx = (props) => {
    const {productId, product, sizes, variants} = props;
    const dispatch = useDispatch();
    let gridRef = useRef();
    let selectRef = useRef(null);
    const [variationData, setVariationData] = useState([]);
    const [buttonDisable, setButtonDisable] = useState(false);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    const isEdit = !(product && !product.is_third_party_seller && (currentUser.modules).includes('bin-location'));
    //const roleRegister = register("role", { required: true });
    let datafields = [
        {name: 'id', type: 'id'},
        {name: 'colour_id', type: 'id'},
        {name: 'colour_name', type: 'string'},
        {name:'pack_for_single',type: 'number'}
    ];

    let columns = [
        {datafield: 'id', hidden: true},
        {datafield: 'colour_id', hidden: true},
        {datafield: 'pack_for_single', hidden: true},
        {
            text: 'Colour',
            datafield: 'colour_name',
            editable: false,
            columntype: 'text',
            width: '50%'
        }
    ];

    sizes.map((item, key) => {
        datafields.push({
            name: item.id,
            type: 'string'
        });
        columns.push({
            text: item.name,
            datafield: item.id,
            width: (50 / sizes.length) + '%',
            editable: isEdit,
            columntype: 'text',
        })
    })

    useEffect(() => {
        setVariationData(variants);
    }, [variants])

    const getJqxRef = jqx => {
        if (jqx) {
            gridRef = jqx;

            setTimeout(
                () => {
                    if (gridRef) {
                        const myEle = document.getElementById(gridRef._id);
                        if (myEle) {
                            gridRef.updatebounddata();
                        }
                    }

                    // myGrid.updatebounddata();
                },
                1000
            );
        }
    };


    const cellEdit = event => {
        // setDataToHookForm();
        if (event.args.datafield == 'name') {
            // trigger('color');
        }
    };
    const inventoryUpdateSubmit = () =>{
        const data= setDataToHookForm();
        setButtonDisable(true);
        const params={id:productId,variation:{variation:data}};

        dispatch(updateInventory({...params})).then(res => {
                setButtonDisable(false);
        });
    }


    const setDataToHookForm = () => {

        let variantData = [];
        const gridInformation = gridRef.getdatainformation();
        for (let i = 0; i < gridInformation.rowscount; i++) {
            const rowData = gridRef.getrowdata(i);
                variantData.push(rowData);

        }
        setVariationData(variantData);
        return variantData;

    }


    return (
        <Fragment>
            {variationData.length >0 &&   <>
                <div className="form-row mb-3">
                    <Col md="12">
                        <div style={{width: '100%'}}>
                            <JqxTable
                                datafields={datafields}
                                columns={columns}
                                data={variationData}
                                myGrid={gridRef}
                                getJqxRef={getJqxRef}
                                isDelete={false}
                                onCellendedit={cellEdit}
                                disableCreateRow
                            />
                        </div>
                    </Col>
                </div>
                {(currentUser.permission).includes('Create Inventory Item') ? 
                <>
                              
                    <div className="form-row mb-3">
                        <Col md="12">
                            {isEdit ?  
                            <ButtonLoader className={'pull-right'} disabled={buttonDisable}  onClick={inventoryUpdateSubmit} color="primary" type="button"  btntext="Save & Publish"></ButtonLoader>
                            : <a className={'btn btn-link pull-right'} href={"/product/add-adjustment-Inventory/" + product.code} target="_blank">Go to Inventory Adjustment</a>
                            }
                        </Col>
                    </div>
                </> 
                : ''}
            </>}
        </Fragment>
    );
}
// function areEqual(prevProps, nextProps) {
//     return JSON.stringify(prevProps.getValues) == JSON.stringify(nextProps.getValues);
//     /*
//     return true if passing nextProps to render would return
//     the same result as passing prevProps to render,
//     otherwise return false
//     */
// }
export default VariationInventoryJqx;