import React, { Fragment, useEffect, useRef, useState } from "react";
import * as ReactDOM from 'react-dom';
import {
    Alert,
    Badge,
    Card,
    CardBody,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";

import {useDispatch, useSelector} from "react-redux";
import {setSkuDialog} from "../../../store/prodcutSlice";
import TagsInput from "react-tagsinput";
import {ModalFooter} from "react-bootstrap";
import CustomTagsInput from "../../../../../share-components/ui/customTagsInput";
import AsyncSelect from "react-select/async";
import { binSearchAutoSuggestion } from "../../../../../utils/mapper";
import { binAutoSuggestionApi } from "../../../../../services/manage-product/productService";

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
    })
};

const SkuDetails = (props) => {
    const { setValue, trigger, getValues, imageSelectOnClick, skuOnClick } = props;
    const dispatch = useDispatch();
    const productState = useSelector(({product}) => product.product.skuDialogProps);
    const colourId = productState.data ? productState.data.colour_id : "";
    const rowIndex = productState.data ? productState.data.rowIndex : "";
    const colourName = productState.data ? productState.data.colour : "";
    const [sku, setSku] = useState([]);
    const [isChange, setIsChange] = useState(false);
    const [binLocations, setBinLocations] = useState([]);
    const [selectedBins, setSelectedBins] = useState({});
    const [binLocationDefaultOption, setBinLocationDefaultOption] = useState([]);

    const closDialog = () => {
        dispatch(setSkuDialog({ props: false }))
    }

    useEffect(() => {
        if (colourId) {
            let skuData = [];
            var binLocationsData = {};
            setSelectedBins({});

            const colourData = getValues('color').find(x => x.id == colourId);
            const binNumbers = getValues('bin_numbers');

            if (getValues('selling_type').find(x => x.id === 1)) {
                getValues('size').map((item, index) => {
                    const sku = ((getValues('code') + "-" + colourData.name + "-" + item.name).toLocaleLowerCase()).replaceAll(' ', '');
                    skuData.push({ sku: sku, colour: colourData.id, size: item.id });
                });
            }

            if (getValues('selling_type').find(x => x.id === 2)) {
                const sku = ((getValues('code') + "-" + colourData.name + "-pack").toLocaleLowerCase()).replaceAll(' ', '');
                skuData.push({ sku: sku, colour: colourData.id, size: 1 });
            }

            skuData.forEach((item) => {                   
                let itemVariant = binNumbers.find(binItem => {
                    return (binItem.size_id == item.size) && (binItem.colour_id == colourId);
                });

                let _binLocations = [{
                    'name': '',
                    'qty': ''
                }];

                if (itemVariant && itemVariant.bin_number && itemVariant.bin_number.length > 0) {                    
                    let _selectedBins = [];
                    _binLocations = itemVariant.bin_number;
                    _binLocations.forEach((bin, binIndex) => { 
                        _selectedBins.push({ label: bin.name, value: bin.name });
                    });
                    setSelectedBins(prevBins => ({
                        ...prevBins,
                        [item.size]: _selectedBins
                    }));
                }

                binLocationsData[item.size] = _binLocations;
            });

            setSku(skuData);
            setBinLocations(binLocationsData);

            binSearchAutoSuggestion(binAutoSuggestionApi([])).then(response => {
                setBinLocationDefaultOption(response)
            });
        }

    }, [colourId]);


    const updateBinDetails = (sizeId, value, fieldType, rowIndex = null) => {
        if (colourId) {
            const binNumbers = getValues('bin_numbers');
            let dataIndex = binNumbers.findIndex(x => x.size_id == sizeId && x.colour_id == colourId);
                        
            if (dataIndex == -1) {
                let data = {
                    size_id: sizeId,
                    colour_id: colourId,
                    bin_number: (fieldType != 0) ? {0 : { "name": value, "qty": null }} : {},
                    gtn_number: (fieldType == 0) ? value : "",
                };
                dataIndex = binNumbers.length;
                binNumbers.push(data)
            } else {
                if (fieldType == 1) {
                    let foundObject = (binNumbers[dataIndex].bin_number.hasOwnProperty(rowIndex));

                    if (foundObject) {
                        let currentObject = binNumbers[dataIndex].bin_number[rowIndex];
                        binNumbers[dataIndex].bin_number[rowIndex] = {
                            "name": value,
                            "qty": currentObject.qty
                        }
                    } else {
                        binNumbers[dataIndex].bin_number[rowIndex] = {
                            "name": value,
                            "qty": null
                        }
                    }
                } else if (fieldType == 2) { // Update quantity
                    let foundObject = (binNumbers[dataIndex].bin_number.hasOwnProperty(rowIndex));

                    if (foundObject) {
                        let currentObject = binNumbers[dataIndex].bin_number[rowIndex];
                        binNumbers[dataIndex].bin_number[rowIndex] = {
                            "name": currentObject.name,
                            "qty": Number(value)
                        }
                    } else {
                        binNumbers[dataIndex].bin_number[rowIndex] = {
                            "name": null,
                            "qty": Number(value)
                        }
                    }
                } else {
                    binNumbers[dataIndex].gtn_number = value;
                }
            }

            setValue('bin_numbers', binNumbers);
            trigger('bin_numbers');

            const bl = binLocations[sizeId];
            bl[rowIndex].qty = binNumbers[dataIndex].bin_number[rowIndex].qty;
            setBinLocations((prevData) => {
                return {
                    ...prevData,
                    [sizeId]: bl
                };
            });
        }
    }

    const handleRemoveBinLocation = (sizeId, rowIndex) => {
        const binNumbers = getValues('bin_numbers');
        const dataIndex = binNumbers.findIndex(x => x.size_id == sizeId && x.colour_id == colourId);
        
        let selectedOption = [...(selectedBins[sizeId] || [])];
        selectedOption.splice(rowIndex, 1);
        setSelectedBins((prevData) => {
            return {
                ...prevData,
                [sizeId]: selectedOption
            };
        });

        let bl = binLocations[sizeId];
        bl.splice(rowIndex, 1);
        setBinLocations((prevData) => {
            return {
                ...prevData,
                [sizeId]: bl
            };
        });

        let bin_number = [...binNumbers[dataIndex].bin_number];
        bin_number.splice(rowIndex, 1);
        binNumbers[dataIndex].bin_number = bin_number;
        setValue('bin_numbers', binNumbers);
        trigger('bin_numbers');
    };

    const handleAddBinLocation = (size) => {
        let bl = binLocations[size];
        bl.push({ 'name': '', 'qty': '' });
        setBinLocations((prevData) => {
            return {
                ...prevData,
                [size]: bl
            };
        });
    };

    const binLocationOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(binSearchAutoSuggestion(binAutoSuggestionApi(data)))
            });
        });

    function binLocationRow(item, itemIndex) {
        let locations = getValues('locations');
        let itemVariant = getValues('bin_numbers').find(binItem => {
            return (binItem.size_id == item.size) && (binItem.colour_id == colourId);
        });  
        let bin_numbers = itemVariant ? itemVariant.bin_number : [];
        let groupBins = {};
        
        bin_numbers.forEach(bin => {
            if(groupBins[bin.location_id] === undefined) groupBins[bin.location_id] = [];

            groupBins[bin.location_id].push(bin);
        });

        return <Row className={'product-name' + (itemIndex == 0 ? '' : ' pt-3 mt-3 border-top')} key={itemIndex}>
                <Col md={2}>
                    <h6 className="mt-2" style={{ fontSize: 14 }}>{(item.sku).toUpperCase()}</h6>
                </Col>

                <Col md={2}>
                    <span >{itemVariant?.gtn_number}</span>
                    {/* <input name="name" defaultValue={itemVariant?.gtn_number} type="text" placeholder="GTIN #" className={`form-control`}
                            onChange={(event) => {
                                updateBinDetails(item.size, event.target.value, 0)
                    }} /> */}
                </Col>

                <Col md="">
                    {Object.keys(groupBins).map((locationId, _i) => {
                        var bin_numbers = groupBins[locationId];
                        var location = locations.find((_location) => _location.id == locationId);
                        
                        return <Row className={(_i == 0 ? '' : 'mt-2')} key={`${itemIndex}_${locationId}`}>
                            <Col md={3}>
                                <h6 className="mt-2" style={{ fontSize: 14 }}>{location?.name}</h6>
                            </Col>

                            <Col md={9}>
                                {bin_numbers.map((binLocation, index) => (
                                    <span className="bin-qty text-center" key={itemIndex + '_' + index}>
                                        {binLocation.name ? binLocation.name +' ('+binLocation.qty +')' : binLocation}
                                    </span>
                                ))}
                            </Col>
                        </Row>
                    })}
                    
                </Col>

                {/* <Col md={3}>
                    {index == 0 &&
                        <input name="name" defaultValue={itemVariant?.gtn_number} type="text" placeholder="GTIN #" className={`form-control`}
                            onChange={(event) => {
                                updateBinDetails(item.size, event.target.value, 0)
                            }} />
                    }
                </Col> */}

                {/* <Col md={3}>
                    <AsyncSelect
                        placeholder="Select bin number"
                        loadOptions={binLocationOption}
                        defaultOptions={binLocationDefaultOption}
                        value={selectedBins[item.size] && selectedBins[item.size][index] ? selectedBins[item.size][index] : null}
                        onChange={(binOption) => {
                            let selectedOption = selectedBins[item.size] ? selectedBins[item.size] : [];
                            selectedOption[index] = binOption;
                            setSelectedBins(prevBins => ({
                                ...prevBins,
                                [item.size]: selectedOption
                            }));
                            updateBinDetails(item.size, (binOption ? binOption.label : null), 1, index);
                        }}
                        isClearable={true}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        onClear={(e) => {

                        }}
                    />
                </Col> */}

                {/* <Col md={2}>
                    <input
                        name="quantity"
                        placeholder="Quantity"
                        className={`form-control`}
                        value={binLocation.qty === null ? '' : binLocation.qty}
                        onChange={(event) => {
                            updateBinDetails(item.size, event.target.value, 2, index)
                        }}
                    />
                </Col> */}

                {/* <Col md={1}>
                    {index == 0 && <i className="fa fa-plus-circle" onClick={() => handleAddBinLocation(item.size)}></i>}
                    {index > 0 && <i className="fa fa-minus-circle text-danger" onClick={() => handleRemoveBinLocation(item.size, index)}></i>}
                </Col> */}
            </Row>
    }

    return (
        <Fragment>
            <div className="form-row mb-3">
                <Modal size="xl"    {...productState.props} toggle={() => {
                    closDialog()
                }}>
                    <ModalHeader toggle={() => {
                        closDialog()
                    }}>
                        {colourName + "'s sku"}
                    </ModalHeader>

                    <ModalBody>
                        <Row className="product-name mb-3">
                            <Col md={2} className="text-left">
                                <strong>SKU</strong>
                            </Col>
                            <Col md={2} className="text-left">
                                <strong>GTN Number</strong>
                            </Col>
                            <Col md="">
                                <Row>
                                    <Col md={3} className="text-left">
                                        <strong>Locations</strong>
                                    </Col>
                                    <Col md={9} className="text-left">
                                        <strong>BIN Number (Quantity)</strong>
                                    </Col>
                                </Row>
                            </Col>
                            {/* <Col md={2} className="text-center">
                                <strong>Quantity</strong>
                            </Col>
                            <Col md={1}></Col> */}
                        </Row>

                        {sku.map((item, i) =>
                            binLocationRow(item, i)
                        )}
                    </ModalBody>

                    <ModalFooter>
                        <Row>
                            <Col md={12}>
                                <button type="button" className="btn btn-primary" onClick={() => {
                                    skuOnClick(rowIndex, colourId, colourName, binLocations);
                                    // setValue('bin_locations', binLocations);
                                    // trigger('bin_locations');
                                    closDialog();
                                }}>Save
                                </button>
                            </Col>
                        </Row>
                    </ModalFooter>

                    <Row className=" mb-3 mt-3">
                        <Col md={12}>
                            <div className="text-center">
                                <span>{"Temporary save only! For permanent save, use the Publish or save button."}</span>
                            </div>
                        </Col>
                    </Row>

                </Modal>
            </div>
        </Fragment>
    );
}

function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.getValues) == JSON.stringify(nextProps.getValues);
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
}

export default React.memo(SkuDetails, areEqual);