import { combineReducers } from "@reduxjs/toolkit";
import order from "./orderSlice";
import draftOrder from "./draftOrderSlice";
import checkout from "./checkoutSlice";
import groupReturn from "./groupReturnSlice";
import pendingOrderList from "./pendingOrderSlice";
import cancelOrder from "./cancelOrderSlice";
import pickingTicket from "./pickingTicketSlice";
import salesOrder from "./salesOrderSlice";
import salesOrderInvoice from "./saleOrderInvoiceSlice";
import salesOrderFulfilment from "./saleOrderFulfillmentSlice";
import salesOrderReturn from "./saleOrderReturnSlice";
import saleOrderReturnItem from "./saleOrderReturnItemSlice";
import saleOrderStatus from "./salesOrderStatusSlice";
import saleOrderInventory from "./salesOrderInventorySlice";

const orderReducer = combineReducers({
  order,
  draftOrder,
  checkout,
  groupReturn,
  pendingOrderList,
  cancelOrder,
  pickingTicket,
  salesOrder,
  salesOrderInvoice,
  salesOrderFulfilment,
  salesOrderReturn,
  saleOrderReturnItem,
  saleOrderStatus,
  saleOrderInventory,
});

export default orderReducer;
