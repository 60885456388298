import { combineReducers } from "redux";
import shippingSetting from "./shippingSettingSlice";
import returnReasonSetting from "./returnReasonSettingSlice";
import closeReasonSetting from "./closeReasonSettingSlice";
import paymentMethodSetting from "./paymentMethodSettingSlice";

const orderSettingReducer = combineReducers({
  shippingSetting,
  returnReasonSetting,
  closeReasonSetting,
  paymentMethodSetting,
});

export default orderSettingReducer;
