import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col, Form, FormGroup, Label, Row } from "reactstrap";

import { Link, useHistory, useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CarouseNavigation } from "../../../../../utils/common";
import BillingOrderDetail from "../orderDetail";
import withReducer from "../../../../../store/withReducer";
import salesOrderFulfillmentReducer from "../../../store";
import LogActivity from "../../../../log";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  addSalesOrderFulfillment,
  closeCurrentSalesOrderFulfillment,
  editSaleOrderFulfilment,
  getCreateData,
  getFulfillmentByOrder,
  getPendingSaleOrderFulfillmentItems,
  setFromSettings,
  setOrderFulfillment,
  updateSalesOrderFulfillment,
} from "../../../store/saleOrderFulfillmentSlice";
import SalesOrderProductFulfillmentJqx from "./content/salesOrderProductFulfillmentJqx";
import Select from "react-select";
import {
  setOrderData,
  setSalesCloseDialog,
} from "../../../store/salesOrderSlice";
import SalesOrderFulfillmentView from "./view";
import ButtonLoader from "../../../../../share-components/ui/buttonLoader";
import CloseSalesOrderModal from "../../view/content/closeSalesOrderModal";
import { toast } from "react-toastify";

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  nextArrow: <CarouseNavigation icon="fa-arrow-right" />,
  prevArrow: <CarouseNavigation icon="fa-arrow-left" />,
};
const defaultValues = {
  id: "",
  ordered_products: [],
  tracking_url: "",
  tracking_id: "",
  delivery_partner: "",
  total_no_of_boxes: "",
  total_gross_weight: "",
  total_net_weight: "",
  status:0,
};
const schema = yup.object().shape({
  tracking_url: yup.string().url("Invalid url!").nullable(),
  total_gross_weight: yup
    .string()
    .required("You must enter total gross weight"),
  total_net_weight: yup.string().required("You must enter total net weight"),
  total_no_of_boxes: yup.string().required("You must enter total no of boxes"),
});
const BillIngFulfillment = (props) => {
  const { id, order_id, slug } = useParams();
  let history = useHistory();
  const dispatch = useDispatch();
  const [buttonDisable, setButtonDisable] = useState(false);
  const salesOrderFulfillmentState = useSelector(
    ({ order }) => order.salesOrderFulfilment
  );
  const [isEditable, setIsEditable] = useState(!id);
  const [isProductValueChange, setIsProductValueChange] = useState(true);
  const [selectedReason, setSelectedReason] = useState(null);
  const [closeReason, setCloseReason] = useState("");
  const [canClose, setCanClose] = useState(false);
  const [isOnline, setIsOnline] = useState(false);

  const {
    handleSubmit,
    formState,
    reset,
    control,
    register,
    setValue,
    trigger,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { errors, isDirty } = formState;
  useEffect(() => {
    dispatch(getCreateData()).then((res) => {
      dispatch(setFromSettings(res.payload));
    });
  }, []);
  useEffect(() => {
    if (order_id) {
      dispatch(getFulfillmentByOrder(order_id)).then((res) => {
        dispatch(setOrderFulfillment(res.payload));
      });
    }

    if (order_id && id) {
      dispatch(editSaleOrderFulfilment(id)).then((res) => {
        if (!res.error) {
          dispatch(setOrderData(res.payload.order));
          setIsProductValueChange(!isProductValueChange);
          reset(res.payload.fulfillment);

          if (getValues("is_closed")) {
            setCanClose(true);
            setCloseReason(getValues("close_reason"));
          } else {
            setCanClose(false);
          }
          if (getValues("is_online")) {
            setIsOnline(true);
          }
        }
      });
    } else {
      if (order_id) {
        dispatch(getPendingSaleOrderFulfillmentItems(order_id)).then((res) => {
          if (!res.error) {
            dispatch(setOrderData(res.payload.order));
            setValue("ordered_products", res.payload.order_products);
            trigger("ordered_products");
            setIsProductValueChange(!isProductValueChange);
          }
        });
      }
    }
  }, [id, order_id]);

  const onSubmit = (data) => {
    setButtonDisable(true);
    const fulfillmentData = {
      sales_order_id: order_id,
      ...data,
      delivery_partner: data.delivery_partner?.id,
      ordered_products: data.ordered_products
        .filter(function (v, i) {
          return v.is_selected;
        })
        .map(function (item, index) {
          return {
            id: item.id,
            sales_order_line_item_id: item.sales_order_line_item_id,
            quantity: item.fulfillment_quantity,
          };
        }),
    };
    if (id) {
      fulfillmentData["id"] = id;
      dispatch(updateSalesOrderFulfillment({ ...fulfillmentData })).then(
        (res) => {
          setIsEditable(false);
          setButtonDisable(false);
        }
      );
    } else {
      dispatch(addSalesOrderFulfillment({ ...fulfillmentData })).then((res) => {
        setButtonDisable(false);
        history.push(
          `/order/sale/${order_id}/billing/fulfillment/${res.payload.id}`
        );
      });
    }
  };

  const onChangeDialog = (isTrigger) => {
    trigger("ordered_products");
  };

  const closeSalesOrderFulfillment = () => {
    const data = {
      props: { isOpen: true },
      type: "edit",
      data: {
        id: getValues("id"),
      },
    };

    dispatch(setSalesCloseDialog(data));
  };

  const closeDialog = () => {
    const obj = { props: { isOpen: false } };
    dispatch(setSalesCloseDialog(obj));
  };

  const handleConfirm = () => {
    if (!selectedReason) {
      toast.error(`Please select a reason before proceeding.`);
      return;
    }

    const orderId = getValues("id");

    dispatch(
      closeCurrentSalesOrderFulfillment({
        id: orderId,
        reason: selectedReason,
      })
    )
      .then((res) => {
        setCloseReason(res.payload?.reason || "");
        setCanClose(true);
        closeDialog();
      })
      .catch((error) => {
        console.error("Error closing Fulfillment:", error);
      });
  };

  return (
    <>
      {id && (
        <Row>
          <Col md={12}>
            {!isOnline && (
              <>
                {!canClose && (
                  <a
                    color="danger"
                    type="button"
                    className="btn btn-sm btn-danger pull-right ml-2"
                    style={{
                      height: 34,
                      marginRight: 8,
                      fontSize: 14,
                      letterSpacing: "1.1px",
                      font: "apple-System,Arial,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,STXihei,sans-serif",
                    }}
                    onClick={closeSalesOrderFulfillment}
                  >
                    Close Fulfillment
                  </a>
                )}
              </>
            )}
           <>{(getValues("status")<6)&&<Link
              className="btn btn-primary pull-right ml-2"
              to={`${process.env.PUBLIC_URL}/order/sale/${order_id}/billing/fulfillment`}
            >Generate New Fulfilment{" "}
            </Link>}</>
            {!canClose && (
              <>
                {!getValues("is_online") && (
                  <a
                    className="btn btn-warning pull-right"
                    onClick={() => {
                      setIsEditable(!isEditable);
                    }}
                  >
                    {isEditable ? "View" : "Edit"}
                  </a>
                )}
              </>
            )}
            <div className="pull-right ml-2 p-2">
              <h5>
                {canClose ? (
                  <>
                    Status : Closed
                    {closeReason ? " - " + closeReason : ""}
                  </>
                ) : (
                  ""
                )}
              </h5>
            </div>
          </Col>
        </Row>
      )}
      <Row className="mt-3">
        <Col
          md={12}
          className={
            salesOrderFulfillmentState.orderFulfillment.length > 5
              ? "slick-arrow"
              : ""
          }
        >
          <Slider {...settings}>
            {salesOrderFulfillmentState.orderFulfillment.map((item, i) => (
              <div className="p-1 " key={i}>
                <Card className={id == item.id ? "active-invoice" : ""}>
                  <CardBody>
                    <Link
                      to={`${process.env.PUBLIC_URL}/order/sale/${order_id}/billing/fulfillment/${item.id}`}
                    >
                      <p className="font-weight-bolder f-14">
                        Fulfillment ID : {item.fulfillment_no}
                      </p>
                    </Link>
                    <p>Date : {item.created_at}</p>
                  </CardBody>
                </Card>
              </div>
            ))}
          </Slider>
        </Col>
      </Row>

      <Row className="mt-3">
        {id && (
          <div style={{ position: "fixed", top: "50%", zIndex: 10, right: 0 }}>
            <LogActivity log="Sales Order Fulfillment" id={id}></LogActivity>
          </div>
        )}
        <Col md={9}>
          {isEditable ? (
            <>
              {getValues("ordered_products").length > 0 ? (
                <Form noValidate="" onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={12}>
                      <Card>
                        <CardBody>
                          <Row>
                            <Col md={4}>
                              <FormGroup className="mt-2">
                                <Label for="selectOption">
                                  Select Delivery Partner
                                </Label>
                                <Select
                                  placeholder="Select Partners"
                                  type="select"
                                  name="trackingName"
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                  value={getValues("delivery_partner")}
                                  isClearable
                                  options={
                                    salesOrderFulfillmentState.formSettings
                                      .delivery_partners
                                  }
                                  onChange={(e) => {
                                    setValue("delivery_partner", e);
                                    trigger("delivery_partner");
                                  }}
                                  className={` ${
                                    errors.delivery_partner ? "is-invalid" : ""
                                  }`}
                                />
                                <div className="invalid-feedback">
                                  {errors.delivery_partner?.message}
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Label className="col-form-label">
                                  Tracking Id
                                </Label>
                                <input
                                  className="form-control"
                                  {...register("tracking_id")}
                                />
                                <div style={{ color: "red" }}>
                                  {errors.tracking_id?.message}
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Label className="col-form-label">
                                  Tracking Url
                                </Label>
                                <input
                                  className="form-control"
                                  {...register("tracking_url")}
                                />
                                <div style={{ color: "red" }}>
                                  {errors.tracking_url?.message}
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card>
                        <CardBody>
                          <p>Products</p>

                          <SalesOrderProductFulfillmentJqx
                            setValue={setValue}
                            getValues={getValues}
                            trigger={trigger}
                            onChangeDialog={onChangeDialog}
                            isProductValueChange={isProductValueChange}
                            orderedProducts={getValues("ordered_products")}
                          ></SalesOrderProductFulfillmentJqx>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card>
                        <CardBody>
                          <h5>Pick Up Details</h5>
                          <Row className="mt-4">
                            <Col md={4}>
                              <FormGroup>
                                <Label className="col-form-label">
                                  Total No Of Boxes
                                </Label>
                                <input
                                  className="form-control"
                                  {...register("total_no_of_boxes")}
                                />
                                <div style={{ color: "red" }}>
                                  {errors.total_no_of_boxes?.message}
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Label className="col-form-label">
                                  Total Net Weight
                                </Label>
                                <input
                                  className="form-control"
                                  {...register("total_net_weight")}
                                />
                                <div style={{ color: "red" }}>
                                  {errors.total_net_weight?.message}
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Label className="col-form-label">
                                  Total Gross Weight
                                </Label>
                                <input
                                  className="form-control"
                                  {...register("total_gross_weight")}
                                />
                                <div style={{ color: "red" }}>
                                  {errors.total_gross_weight?.message}
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  {getValues("ordered_products").filter(function (v, i) {
                    return v.is_selected;
                  }).length > 0 && (
                    <Row>
                      <Col md={12}>
                        <Card>
                          <CardBody>
                            <div className="pull-right">
                              <ButtonLoader
                                disabled={buttonDisable}
                                color="primary"
                                value="1"
                                type="submit"
                                btntext="Save"
                              ></ButtonLoader>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  )}
                </Form>
              ) : (
                <div className="search-not-found text-center">
                  <div>
                    <img
                      className="img-fluid second-search"
                      src={require("../../../../../assets/images/search-not-found.png")}
                      alt=""
                    />
                    <p> All Ordered items are fulfilled</p>
                  </div>
                </div>
              )}
            </>
          ) : (
            <SalesOrderFulfillmentView fulFillmentData={getValues} />
          )}
        </Col>
        <Col md={3}>
          <BillingOrderDetail></BillingOrderDetail>
        </Col>
        <CloseSalesOrderModal
          orderSetValue={setValue}
          orderTrigger={trigger}
          orderGetValues={getValues}
          handleConfirm={handleConfirm}
          setSelectedReason={setSelectedReason}
          componentName={"Fulfillment"}
        ></CloseSalesOrderModal>
      </Row>
      <Row className="mt-3"></Row>
    </>
  );
};
export default withReducer([
  { salesOrderFulfilment: salesOrderFulfillmentReducer },
])(BillIngFulfillment);
