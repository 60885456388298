import {Card, CardBody, Col, Container, Label, Row} from "reactstrap";
import React, {Fragment, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {isObjectEqual, optionDefaultValue} from "../../../../../utils/common";
import withReducer from "../../../../../store/withReducer";
import salesOrderReducer from "../../../store";
import {getCustomerAddressData, openEditAddressDialog} from "../../../store/draftOrderSlice";
import ModalFormSubmit from "../../../../../share-components/form/modalFormSubmit";
import DraftOrderAddressForm from "../../../draft-order/form/buyerLeadAddressForm";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {setAddressDialog} from "../../../store/salesOrderSlice";
import OrderCustomerAddressForm from "./customerAddressForm";


 const schema = yup.object().shape({
    address1: yup.string().required("Address line 1 is required").typeError('Address line 1 is required'),
    city: yup.string().required("City is required").typeError('City is required'),

    zip: yup.string().required("Zip is required").typeError('Zip is required'),
    country: yup.string().required('Country is required field').typeError('Please select Country'),
    phone: yup.string().nullable().matches(/^.{0}$|^.{9,10}$/, 'Enter a Valid Phone Number '),
});
 const defaultValues = {
    company:'',
    type:'',
    country: '',
    first_name: '',
    last_name:'',
    address1: '',
    address2: '',
    city:"",
    zip:'',
    phone:'',
    province:'',
    order_no:''


};
const OrderCustomerAddress = (props) => {
    const {id,setValue, trigger, getValues, salesOrderState,draftOrderState,addressError} = props;
    const dispatch = useDispatch();
    const [loading, isSetLoading] = useState(false);
    const [addressArray, setAddressArray] = useState([]);
    const [fullAddressArray, setFullAddressArray] = useState([]);
    const [add, setAdd] = useState([]);
    const [type, setType] = useState(1);
    const [isCreate,setIsCreate]=useState(!id);

    const {handleSubmit:addressHandleSubmit,
        formState:addressFormState,
        control:addressControl,
        reset:addressReset,
        register:addressRegister,
        trigger:addressTrigger,
        setValue:addressSetValue,
        getValues:addressRGetValues

    } = useForm({
        mode: 'onChange',
        defaultValues:defaultValues,
        resolver: yupResolver(schema)
    });

    const {errors:addressErrors, addressIsDirty} = addressFormState;
    const getCustomerAddress = (e) => {
        if (e) {
            dispatch(getCustomerAddressData(e.value)).then(res=>{
                if (res.payload) {
                    setFullAddressArray(res.payload)
                    let addressArr = [];
                    if(res.payload.length >0) {
                        res.payload.map(function (value) {
                            if (value.is_default) {
                                setAdd([{id: value.id, name: value.address1}]);
                                // setShippingAddress(value);
                                setValue('shippingAddress', value);
                                setValue('billingAddress', value);
                                // addressTrigger();
                            }

                            // if (res.payload.length > 1) {
                                addressArr.push({'id': value.id, 'name': value.address1})
                            // }

                        });
                        setAddressArray(addressArr)
                    }else{
                        setValue('shippingAddress', {});
                        setValue('billingAddress', {});
                        // addressTrigger();
                    }
                    // setVatForUk();
                };
            });
        }
    }
    useEffect(() => {
        if(isCreate) {
            getCustomerAddress(getValues('customer'))
        }
        setIsCreate(true);
    }, [getValues('customer')]);

    const setAddressData = (e) => {
        fullAddressArray.map(function (value){
            if (value.id===e.id) {
                addressReset(value);
            }
        });
    }
    const closeDialog=()=>{
        const obj = {
            type: 'edit',
            props: {
                isOpen: false
            },
            data: null
        };
        dispatch(setAddressDialog(obj))
    }

    const onSubmitForm = (dialog, data) => {
        if (data.type==="1") {
            // setShippingAddress(data)
            setValue('shippingAddress', data);
            addressTrigger()
            // setShippingAddress(data);
        } else {
            // setBillingAddressStatus(data)
            setValue('billingAddress', data);
            addressTrigger()
            // setBillingAddress(data)
        }
        closeDialog();
    }
    const onEdit = (editId,type) => {
        setType(type)
        const addArray = [...addressArray];
        const dataObj = {...addArray[editId], id: editId};
        const obj = {
            type: 'edit',
            props: {
                isOpen: true
            },
            data: type===1 ? getValues('shippingAddress'): getValues('billingAddress')
        };
        if (getValues('shippingAddress').first_name!== undefined || getValues('shippingAddress').first_name!== '') {
            dispatch(setAddressDialog(obj))
        }

    }
    const sameAsShipping=()=>{
        setValue('billingAddress', getValues('shippingAddress'));
        addressTrigger()
    }

    return (
        <Fragment>


            <Row>
                <Col sm="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm="6">
                                    <Label style={{fontWeight: 'bold'}}>Shipping Address</Label>
                                </Col>
                                <Col sm="6">
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <a onClick={() => {
                                            onEdit(getValues("shippingAddress").id, 1);
                                        }}><i className="fa fa-pencil"
                                              style={{
                                                  width: 25,
                                                  fontSize: 16,
                                                  color: 'rgb(40, 167, 69)'
                                              }}></i></a>
                                    </div>

                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                <label style={{fontWeight: "bolder"}}>Company Name :</label>
                                    <label style={{marginLeft: '5px'}}>{getValues("shippingAddress")?.company}</label>
                                </Col>

                                <Col sm="12">
                                    <label style={{fontWeight: "bolder"}}>Address 1 :</label>
                                    <label style={{marginLeft: '5px',display:'block'}}>{getValues("shippingAddress")?.address1}</label>
                                </Col>
                                <Col sm="12">
                                    <label style={{fontWeight: "bolder"}}>Phone :</label>
                                    <label style={{marginLeft: '5px'}}>{optionDefaultValue(draftOrderState.settings.countries,getValues("shippingAddress")?.country)?.dial_code +""+ getValues("shippingAddress")?.phone}</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                <div style={{color: "red"}}>{addressError.shipping?.message}</div>
                                </Col>
                            </Row>


                            <Row style={{marginTop: '20px'}}>
                            <Col sm="6">
                                    <Label style={{fontWeight: 'bold'}}>Billing Address</Label>
                                </Col>
                                <Col sm="6">
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        {! (isObjectEqual(getValues("shippingAddress"),getValues("billingAddress"))) && <a className="mr-3 btn btn-success btn-xs" onClick={sameAsShipping}>Same As</a>}
                                        <a onClick={() => {
                                           onEdit(getValues("billingAddress") ? getValues("billingAddress").id : 0,2);
                                        }}><i className="fa fa-pencil"
                                              style={{
                                                  width: 25,
                                                  fontSize: 16,
                                                  color: 'rgb(40, 167, 69)'
                                              }}></i></a>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>

                                    {isObjectEqual(getValues("shippingAddress"),getValues("billingAddress")) && <Label>Same As Shipping Address</Label>}

                                </Col>
                            </Row>
                            {!isObjectEqual(getValues("shippingAddress"),getValues("billingAddress"))  && <Row>



                                <Col sm="12">
                                    <label style={{fontWeight: "bolder"}}>Company Name :</label>
                                    <label style={{marginLeft: '5px'}}>{getValues("billingAddress")?.company}</label>
                                </Col>

                                <Col sm="12">
                                    <label style={{fontWeight: "bolder"}}>Address 1 :</label>
                                    <label style={{marginLeft: '5px',display:'block'}}>{getValues("billingAddress")?.address1}</label>
                                </Col>
                                <Col sm="12">
                                    <label style={{fontWeight: "bolder"}}>Phone :</label>
                                    <label style={{marginLeft: '5px'}}>{optionDefaultValue(draftOrderState.settings.countries,getValues("billingAddress")?.country)?.dial_code +""+ getValues("billingAddress")?.phone}</label>
                                </Col>

                                    <Col sm="12">
                                        <div style={{color: "red"}}>{addressError.billing?.message}</div>
                                    </Col>

                            </Row>

                            }

                        </CardBody>
                    </Card>
                </Col>

            </Row>
            <ModalFormSubmit
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={addressReset}
                formState={addressFormState}
                handleSubmit={addressHandleSubmit}
                title=' Order Address'
                selector='BuyerLead'
                dialog={salesOrderState.addressDialogProps}
                closeDialog={closeDialog}
                setValue={addressSetValue}
                getValues={addressRGetValues}
                isUseSelector
                size="xl"
                publish={false}
            >
                <OrderCustomerAddressForm countries={draftOrderState.settings.countries} control={addressControl}
                                       formText="Buyer Lead Address" errors={addressErrors} register={addressRegister}
                                       setValue={addressSetValue}
                                       trigger={addressTrigger} getValues={addressRGetValues} addressArray={addressArray} add={add} setAddressData={setAddressData} type={type}/>
            </ModalFormSubmit>

        </Fragment>
    );
}

function areEqual(prevProps, nextProps) {
    const error=JSON.stringify(prevProps.addressError) == JSON.stringify(nextProps.addressError);
     const isOpen=JSON.stringify(prevProps.salesOrderState.addressDialogProps) == JSON.stringify(nextProps.salesOrderState.addressDialogProps);
    return JSON.stringify(prevProps.customer) == JSON.stringify(nextProps.customer) && isOpen && error;
}

export default React.memo(OrderCustomerAddress, areEqual);