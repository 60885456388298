import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import { CarouseNavigation } from "../../../../../utils/common";
import BillingOrderDetail from "../orderDetail";
import withReducer from "../../../../../store/withReducer";
import salesOrderInvoiceReducer from "../../../store";
import {
  getCountryList,
  getCountryListData,
} from "../../../store/draftOrderSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  addSalesOrderInvoice,
  editSaleOrderInvoice,
  getCreateInvoiceData,
  getInvoiceByOrder,
  getPendingSaleOrderInvoiceItems,
  setInvoiceFromSettings,
  setOrderInvoices,
  updateSalesOrderInvoice,
  downloadSalesOrderXeroInvoicePdf,
  downloadInvoiceRMAPdf,
} from "../../../store/saleOrderInvoiceSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SaleOrderProductInvoiceJqx from "./content/saleOrderProductInvoicejqx";
import OrderDiscount from "../../../draft-order/form/content/orderDiscount";
import OrderShipment from "../../../draft-order/form/content/orderShipment";
import OrderTax from "../../../draft-order/form/content/orderTax";
import SalesOrderDuty from "../../create/content/salesOrderDuty";
import SaleOrderPayment from "../../create/content/saleOrderPayment";
import {
  setOrderData,
  sendSalesOrderInvoiceToXero,
  downloadSalesOrderInvoicePdf,
  getSalesOrderDefaultShipping,
} from "../../../store/salesOrderSlice";
import LogActivity from "../../../../log";
import Select from "react-select";
import SalesOrderInvoiceView from "./view";
import ButtonLoader from "../../../../../share-components/ui/buttonLoader";
import AsyncSelect from "react-select/async";
import { mapSearchPaymentMethodAutoSuggestion } from "../../../../../utils/mapper";
import SweetAlert from "sweetalert2";
import { getPaymentAutoSuggestionApi } from "../../../../../services/manage-order/setting/paymentMethodService";
import PickUpDetail from "./content/pickUpDetail";
import { productTaxAmount, shippingTaxAmount } from "../../setting/calculation";

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  nextArrow: <CarouseNavigation icon="fa-arrow-right" />,
  prevArrow: <CarouseNavigation icon="fa-arrow-left" />,
};
const defaultValues = {
  id: "",
  ordered_products: [],
  due_date: "",
  tags: [],
  duty: "",
  terms: "",
  discount: "",
  shipping: "",
  tax: "",
  xero_id: "",
  total_net_weight: "",
  total_gross_weight: "",
  total_no_of_boxes: "",
  bank: "",
  is_online: "",
  is_weight_added: "",
  trade_option: "",
  net_unit_type: 1,
  gross_unit_type: 1,
  merge_invoices: [],
  is_merge: false,
  is_tax: false,
  status:0,
  return_request_items: [],
  payment_method: "",
  total_tax: 0,
  total_sub_total: 0,
  total_discount: 0,
};

yup.addMethod(yup.array, "invoiceQuantityCheck", function (errorMessage) {
  return this.test(`test-card-length`, errorMessage, function (value) {
    const { path, createError } = this;
    let isValid = true;
    let message = "";
    value.map((item, index) => {
      if (item.is_selected) {
        if (item.quantity < 1) {
          isValid = false;
          message += item.product.sku + ",";
        } else if (Number(item.pending_quantity) < Number(item.quantity)) {
          isValid = false;
          message += item.product.sku + ",";
        }
      }
    });

    message = message.substring(0, message.length - 1);

    return (
      isValid ||
      createError({
        path,
        message: message + " " + " should be less the order Quantity",
      })
    );
  });
});
const schema = yup.object().shape({
  shipping: yup.object().when("is_online", {
    is: (value) => !value, // Change this to the specific value triggering the condition
    then: yup
      .object()
      .shape({ rate_name: yup.string() })
      .required("shipment is required")
      .typeError("shipment is required"),
    otherwise: yup.object().shape({ rate_name: yup.string() }).nullable(),
  }),
  total_gross_weight: yup
    .string()
    .required("You must enter total gross weight"),
  total_net_weight: yup.number().typeError('You must enter total net weight').required("You must enter total net weight").max(yup.ref('total_gross_weight'), ({ max }) => `should be lees than ${max}`),
  total_no_of_boxes: yup.number().typeError('You must enter total no of boxes').required("You must enter total no of boxes"),
  ordered_products: yup
    .array()
    .invoiceQuantityCheck("location Miss match with quantity"),
  // ordered_products: yup
  // .array()
  // .invoiceQuantityCheck("location Miss match with quantity")
  // .of(
  //   yup.object().shape({
  //     // Define schema for each item in the ordered_products array
  //     quantity: yup
  //       .number()
  //       .required("Quantity is required")
  //       .min(1, "Quantity must be greater than 0"),
  //     // Add more validation rules as needed for other properties of the item
  //   })
  // )
  // .required("At least one product must be selected"),
  terms: yup
    .object()
    .shape({ value: yup.string() })
    .nullable()
    .required("You must select a term."),
});

const BillIngInvoice = (props) => {
  const { id, order_id } = props;
  let history = useHistory();
  const dispatch = useDispatch();
  const draftOrderState = useSelector(({ order }) => order.draftOrder);
  const salesOrderInvoiceState = useSelector(
    ({ order }) => order.salesOrderInvoice
  );
  const salesOrderState = useSelector(({ order }) => order.salesOrder);
  const [isEditable, setIsEditable] = useState(!id);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [xeroButtonDisable, setIsXeroButtonDisable] = useState(false);
  const authData = useSelector(({ authReducer }) => authReducer);
  const [dropdownStatusOpen, setDropdownStatusOpen] = useState(false);
  const [dropdownRMAOpen, setDropdownRMAOpen] = useState(false);
  const [isProductValueChange, setIsProductValueChange] = useState(true);
  const [showBankDropdown, setShowBankDropdown] = useState(false);
  const [isDiscountValueChange, setIsDiscountValueChange] = useState(true);

  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [weight, setWeight] = useState("");
  const [dbShipping, setDbShipping] = useState(false);
  const [isLoadingShipping, setIsLoadingShipping] = useState(false);

  const toggleStatusDropdown = () => {
    setDropdownStatusOpen(!dropdownStatusOpen);
  };

  const toggleRMADropdown = () => {
    setDropdownRMAOpen(!dropdownRMAOpen);
  };

  const defaultShipping = useSelector(
    (state) => state.order.salesOrder.settings.defaultShipping
  );

  const {
    handleSubmit,
    formState,
    reset,
    control,
    register,
    setValue,
    trigger,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { errors, isDirty } = formState;
  useEffect(() => {
    if (order_id) {
      const data = {
        id: order_id,
        params: {
          is_check_xero: 0,
        },
      };
      dispatch(getInvoiceByOrder(data)).then((res) => {
        dispatch(setOrderInvoices(res.payload));
      });
    }

    if (order_id && id) {
      salesOrderInvoiceEdit(id);
    } else {
      if (order_id) {
        dispatch(getPendingSaleOrderInvoiceItems(order_id)).then((res) => {
          if (!res.error) {
            dispatch(setOrderData(res.payload.order));
            setValue("ordered_products", res.payload.order_products);
            setValue("is_vat", res.payload.order.is_vat);
            trigger("ordered_products");
            setIsProductValueChange(!isProductValueChange);
            setIsDiscountValueChange(!isDiscountValueChange);
            setCountry(res.payload.order?.shipping_address?.country);
          }
        });
      }
    }
  }, [id, order_id]);
  const salesOrderInvoiceEdit = (id) => {
    dispatch(editSaleOrderInvoice(id)).then((res) => {
      if (!res.error) {
        dispatch(setOrderData(res.payload.order));
        reset(res.payload.invoice);
        setIsProductValueChange(!isProductValueChange);
        setIsDiscountValueChange(!isDiscountValueChange);
        setCountry(res.payload.order?.shipping_address?.country);
      }
    });
  };
  useEffect(() => {
    dispatch(getCreateInvoiceData()).then((res) => {
      dispatch(setInvoiceFromSettings(res.payload));
    });
    dispatch(getCountryList()).then((res) => {
      dispatch(getCountryListData(res.payload));
    });
  }, [dispatch]);

  const onSubmit = (data) => {
    const hasZeroQuantity = data.ordered_products.some(
      (item) => item.quantity === 0 && item.is_selected
    );

    if (hasZeroQuantity) {
      toast.error("Quantity cannot be 0 for selected items");
      return;
    }

    setButtonDisable(true);
    const invoiceData = {
      sales_order_id: order_id,
      ...data,
      terms: data.terms.id,
      bank: data.bank?.id,
      payment_method: data.payment_method?.value,
      trade_option: data.trade_option?.id,
      merge_invoices: data.merge_invoices.map((num) => num.value),
      ordered_products: data.ordered_products
        .filter(function (v, i) {
          return v.is_selected;
        })
        .map(function (item, index) {
          return {
            id: item.id,
            sales_order_line_item_id: item.sales_order_line_item_id,
            quantity: item.quantity,
            price: item.rate,
            sub_total: item.total_price,
            tax_amount: item.tax_amount,
            total_amount: item.gross_price,
            discount: item.discount,
            item_discount: item.item_discount,
          };
        }),
    };

    if (id) {
      invoiceData.id = id;
      dispatch(updateSalesOrderInvoice({ ...invoiceData })).then((res) => {
        setIsEditable(!isEditable);
        // setValue("is_weight_added", true);

        setButtonDisable(false);
        salesOrderInvoiceEdit(id);
      });
    } else {
      dispatch(addSalesOrderInvoice({ ...invoiceData })).then((res) => {
        if (!res.error) {
          setButtonDisable(false);
          history.push(
            `/order/sale/${order_id}/billing/invoice/${res.payload.id}`
          );
        }
      });
    }
  };

  const sendToXero = () => {
    SweetAlert.fire({
      title: "Are you sure want to send this invoice to xero?",
      text: "Once confirmed, no changes will be accepted in this invoice",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Proceed",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsXeroButtonDisable(true);
        const xeroData = {
          invoice_id: id,
        };
        dispatch(sendSalesOrderInvoiceToXero({ ...xeroData })).then((res) => {
          if (!res.error) {
            setValue("xero_id", res.payload.xero_id);
            setIsXeroButtonDisable(false);
          } else {
            console.error("Error updating status:", res.error);
          }
        });
      }
    });
  };

  const processPDFDownload = (name, isCommercial) => {
    const data = {
      id: id,
      params: {
        is_commercial: isCommercial,
      },
    };
    dispatch(downloadSalesOrderInvoicePdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${getValues("invoice_no")}_${name.replace(/\s+/g, "-")}_${new Date()
          .toJSON()
          .slice(0, 10)}.pdf`
      );
      document.body.appendChild(link);
      link.click();
    });
  };

  const processRMAPDFDownload = (name, id) => {
    const data = {
      id: id,
    };
    dispatch(downloadInvoiceRMAPdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${getValues("invoice_no")}_${name.replace(/\s+/g, "-")}_${new Date()
          .toJSON()
          .slice(0, 10)}.pdf`
      );
      document.body.appendChild(link);
      link.click();
    });
  };

  const processXeroPDFDownload = (name) => {
    const orderId = order_id;
    const data = {
      id: id,
      params: {
        name: name,
        seller: authData.defaultSeller?.value,
      },
    };
    dispatch(downloadSalesOrderXeroInvoicePdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${getValues("invoice_no")}_${name.replace(/\s+/g, "-")}_${new Date()
          .toJSON()
          .slice(0, 10)}.pdf`
      );
      document.body.appendChild(link);
      link.click();
    });
  };

  const paymentTypePromiseOption = (inputValue) =>
    new Promise((resolve) => {
      const data = {
        value: inputValue,
      };
      setTimeout(() => {
        resolve(
          mapSearchPaymentMethodAutoSuggestion(
            getPaymentAutoSuggestionApi(data)
          )
        );
      });
    });

  const downloadInvoice = (name, isCommercial) => {
    processPDFDownload(name, isCommercial);
  };

  const downloadRMA = (name, id) => {
    processRMAPDFDownload(name, id);
  };

  const downloadXeroInvoice = () => {
    processXeroPDFDownload("Xero Invoice");
  };

  useEffect(() => {
    if (getValues("shipping")) {
    } else {
      if (defaultShipping?.ship_price) {
        const { name, ship_price } = defaultShipping;
        const price = parseFloat(ship_price);

        const shippingValue = {
          price: price ? price : 0,
          rate_name: name ? name : "",
          shipping_type: "2",
        };
        setValue("shipping", shippingValue);
      } else {
        setValue("shipping", "");
      }

      const updatedShippingValue = getValues("shipping");
    }
  }, [defaultShipping, setValue, getValues]);

  const getSalesOrderDefaultShippingData = (data) => {
    setIsLoadingShipping(true);
    dispatch(getSalesOrderDefaultShipping(data)).then((res) => {
      if (!res.error) {
        const shippingValue = {
          price: res.payload.ship_price ? res.payload.ship_price : 0,
          rate_name: res.payload.name ? res.payload.name : "",
          shipping_type: "2",
        };
        setValue("shipping", shippingValue);

        const productTax = productTaxAmount(getValues);
        if (productTax > 0 || getValues("is_vat")) {
          const totalTax = shippingTaxAmount(getValues);
          setValue("tax", {
            is_charge: true,
            rate_name: "Vat",
            percentage: productTax + totalTax,
          });
        }
      }
      setIsLoadingShipping(false);
      trigger("shipping");
      trigger("ordered_products");
    });
  };

  useEffect(() => {
    if (getValues("currency")) {
      setCurrency(getValues("currency"));
    }

    let subTotal = 0;
    let weight = 0;
    let totalWeightType = 1;
    getValues("ordered_products").forEach((product) => {
      subTotal += product.total_price;
    });
    totalWeightType = getValues("gross_unit_type");
    weight = getValues("total_gross_weight");
    if (totalWeightType == 2) {
      weight = weight / 1000;
    }
  }, [getValues("ordered_products"), getValues("currency")]);

  useEffect(() => {
    trigger("shipping");
  }, [defaultShipping]);

  const onChangeDialog = (isTrigger) => {
    let subTotal = 0;
    let weight = 0;
    let totalWeightType = 1;
    getValues("ordered_products").forEach((product) => {
      subTotal += product.total_price;
    });
    totalWeightType = getValues("gross_unit_type");
    weight = getValues("total_gross_weight");
    if (totalWeightType == 2) {
      weight = weight / 1000;
    }

    const data = {
      country: salesOrderState.orderData.shipping_address.country,
      subTotal,
      currency: salesOrderState.orderData.currency,
      weight,
    };
    getSalesOrderDefaultShippingData(data);

    if (isTrigger) {
      // setIsDiscountValueChange(!isDiscountValueChange);
      setIsProductValueChange(!isProductValueChange);
    }
    trigger("ordered_products");
    trigger("total_net_weight");
  };

  const handleLinkClick = () => {
    setIsEditable(false);
  };
  useEffect(
    function () {
      trigger("ordered_products");
    },
    [isProductValueChange]
  );

  return (
    <>
      <Row className="mt-3">
        <Col
          md={12}
          className={
            salesOrderInvoiceState.orderInvoices.length > 5 ? "slick-arrow" : ""
          }
        >
          <Slider {...settings}>
            {salesOrderInvoiceState.orderInvoices.map((item, i) => (
              <div className="p-1 " key={i}>
                <Card className={id == item.id ? "active-invoice" : ""}>
                  <CardBody>
                    <Link
                      to={`${process.env.PUBLIC_URL}/order/sale/${order_id}/billing/invoice/${item.id}`}
                      onClick={handleLinkClick}
                    >
                      <p className="font-weight-bolder f-14">
                        Invoice ID : {item.invoice_no}
                      </p>
                    </Link>
                    <p>Date : {item.created_at}</p>
                  </CardBody>
                </Card>
              </div>
            ))}
          </Slider>
        </Col>
      </Row>

      {id && (
        <Row>
          <Col md={12}>
            <Card>
              <CardBody style={{ padding: "24px" }}>
                {((!getValues("is_online") || !getValues("is_product_edit_disable")) && (getValues('status') <3 )) && (
                  <Link
                    className="btn btn-primary pull-right ml-2"
                    to={`${process.env.PUBLIC_URL}/order/sale/${order_id}/billing/invoice`}
                  >
                    Generate New Invoice
                  </Link>
                )}

                {getValues("return_request_items")?.length > 0 && (
                  <Dropdown isOpen={dropdownRMAOpen} toggle={toggleRMADropdown}>
                    <DropdownToggle
                      color="warning"
                      className="btn btn-sm btn-warning pull-right ml-2"
                      style={{
                        height: 38,
                        marginRight: 8,
                        fontSize: 14,
                        fontWeight: "bold",
                        letterSpacing: "1.1px",
                        font: "apple-System,Arial,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,STXihei,sans-serif",
                      }}
                    >
                      Download RMA{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      {getValues("return_request_items").map((item) => (
                        <DropdownItem
                          key={item.id}
                          onClick={() => {
                            downloadRMA(`RMA-${item.id}`, item.id);
                          }}
                        >
                          {`RMA-${item.id}`}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                )}

                {getValues("xero_id") && (
                  <Link
                    className={`btn btn-info pull-right ml-2 ${
                      getValues("xero_id") ? "" : "disabled"
                    }`}
                    to={`${process.env.PUBLIC_URL}/order/sale/${order_id}/return/${id}`}
                  >
                    Return Invoice
                  </Link>
                )}

                <Dropdown
                  isOpen={dropdownStatusOpen}
                  toggle={toggleStatusDropdown}
                >
                  <DropdownToggle
                    color="warning"
                    className="btn btn-sm btn-warning pull-right ml-2"
                    style={{
                      height: 38,
                      marginRight: 8,
                      fontSize: 14,
                      fontWeight: "bold",
                      letterSpacing: "1.1px",
                      font: "apple-System,Arial,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,STXihei,sans-serif;",
                    }}
                  >
                    Download{" "}
                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => {
                        downloadInvoice("Invoice", 0);
                      }}
                    >
                      Local Invoice
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        downloadInvoice("Commercial Invoice", 1);
                      }}
                    >
                      Commercial Invoice
                    </DropdownItem>
                    {getValues("xero_id") && (
                      <DropdownItem onClick={downloadXeroInvoice}>
                        Xero Invoice
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown>

                {!getValues("xero_id") && (
                  <div className="pull-right ml-2">
                    <ButtonLoader
                      disabled={xeroButtonDisable}
                      color="info"
                      value="1"
                      type="button"
                      onClick={() => {
                        sendToXero();
                      }}
                      btntext="Send To Xero"
                    ></ButtonLoader>
                  </div>
                )}

                {((!getValues("xero_id") ||
                  (getValues("is_online") &&
                    getValues("is_product_edit_disable"))) && (
                  <a
                    className="btn btn-success pull-right"
                    onClick={() => {
                      setIsEditable(!isEditable);
                    }}
                  >
                    {isEditable ? "View" : "Edit"}
                  </a>
                )) ||
                  ""}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      <Row className="mt-3">
        {id && (
          <div style={{ position: "fixed", top: "50%", zIndex: 10, right: 0 }}>
            <LogActivity log="Sales Order Invoice" id={id}></LogActivity>
          </div>
        )}
        <Col md={9}>
          <Form noValidate="" onSubmit={handleSubmit(onSubmit)}>
            {isEditable && !getValues("is_product_edit_disable") ? (
              <>
                {getValues("ordered_products").length > 0 ? (
                  <>
                    <Row>
                      <Col md={12}>
                        <Card>
                          <CardBody>
                            <Row>
                              <Col md={3}>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    Terms
                                  </Label>
                                  <Select
                                    placeholder="Select Terms"
                                    type="select"
                                    name="term"
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 100,
                                      }),
                                    }}
                                    value={getValues("terms")}
                                    isClearable
                                    options={
                                      salesOrderInvoiceState.formSettings.terms
                                    }
                                    onChange={(e) => {
                                      setValue("terms", e);
                                      const dueDate = moment()
                                        .add(e.no_of_days, "days")
                                        .format("YYYY-MM-DD");
                                      setValue("due_date", dueDate);
                                      trigger("terms");
                                      trigger("due_date");
                                    }}
                                    className={` ${
                                      errors.trackingName ? "is-invalid" : ""
                                    }`}
                                  />
                                  <div style={{ color: "red" }}>
                                    {errors.terms?.message}
                                  </div>
                                </FormGroup>
                              </Col>
                              <Col md={3}>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    Due Date
                                  </Label>
                                  <DatePicker
                                    name="dispatchDate"
                                    className="form-control"
                                    selected={
                                      getValues("due_date")
                                        ? new Date(getValues("due_date"))
                                        : null
                                    }
                                    onChange={(date) => {
                                      setValue("due_date", date);
                                      trigger("due_date");
                                    }}
                                    minDate={moment().toDate()}
                                    style={{ minHeight: "auto" }}
                                    dateFormat="dd-MM-yyyy"
                                  />
                                  <div style={{ color: "red" }}>
                                    {errors.due_date?.message}
                                  </div>
                                </FormGroup>
                              </Col>
                              <Col md={3}>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    Payment Method
                                  </Label>
                                  <AsyncSelect
                                    className={` ${
                                      errors.customer ? "is-invalid" : ""
                                    }`}
                                    isClearable
                                    cacheOptions
                                    defaultOptions
                                    defaultValue={getValues("payment_method")}
                                    loadOptions={paymentTypePromiseOption}
                                    onChange={(e) => {
                                      setValue("payment_method", e);
                                      trigger("payment_method");

                                      // if (e && e.value === 1) {
                                      //   setShowBankDropdown(true);
                                      // } else {
                                      //   setShowBankDropdown(false);
                                      //   setValue("bank", null);
                                      // }
                                    }}
                                  />
                                  <div className="invalid-feedback">
                                    {errors.currency?.message}
                                  </div>
                                </FormGroup>
                              </Col>

                              {getValues("payment_method")?.value == 1 && (
                                <Col md={3}>
                                  <FormGroup>
                                    <Label className="col-form-label">
                                      Bank
                                    </Label>
                                    <Select
                                      placeholder="Select Bank"
                                      type="select"
                                      name="bank"
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.id}
                                      styles={{
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 100,
                                        }),
                                      }}
                                      value={getValues("bank")}
                                      isClearable
                                      options={
                                        salesOrderInvoiceState.formSettings
                                          .banks
                                      }
                                      onChange={(e) => {
                                        setValue("bank", e);
                                        trigger("bank");
                                      }}
                                      className={` ${
                                        errors.bank ? "is-invalid" : ""
                                      }`}
                                    />
                                    <div style={{ color: "red" }}>
                                      {errors.bank?.message}
                                    </div>
                                  </FormGroup>
                                </Col>
                              )}

                              <Col md={3}>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    Trade Option
                                  </Label>
                                  <Select
                                    placeholder="Select Trade Option"
                                    type="select"
                                    name="trade_option"
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 100,
                                      }),
                                    }}
                                    value={getValues("trade_option")}
                                    isClearable
                                    options={
                                      salesOrderInvoiceState.formSettings
                                        .term_trade_options
                                    }
                                    onChange={(e) => {
                                      setValue("trade_option", e);
                                      trigger("trade_option");
                                    }}
                                    className={` ${
                                      errors.trade_option ? "is-invalid" : ""
                                    }`}
                                  />
                                  <div style={{ color: "red" }}>
                                    {errors.trade_option?.message}
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Card>
                          <CardBody>
                            <p>Products</p>
                            <div className="selection-grid">
                              <SaleOrderProductInvoiceJqx
                                setValue={setValue}
                                getValues={getValues}
                                trigger={trigger}
                                orderedProducts={getValues("ordered_products")}
                                isProductValueChange={isProductValueChange}
                                isDiscountValueChange={isDiscountValueChange}
                                // setIsDiscountValueChange={setIsDiscountValueChange}
                                onChangeDialog={onChangeDialog}
                                currency={salesOrderState.orderData.symbol}
                              ></SaleOrderProductInvoiceJqx>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div
                          style={{
                            color: "red",
                            float: "right",
                          }}
                        >
                          {errors.ordered_products?.message}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <PickUpDetail
                          setValue={setValue}
                          getValues={getValues}
                          trigger={trigger}
                          errors={errors}
                          id={id}
                          register={register}
                        ></PickUpDetail>
                      </Col>
                      <Col md={8}>
                        {getValues("ordered_products").length > 0 && (
                          <div>
                            <SaleOrderPayment
                              setValue={setValue}
                              getValues={getValues}
                              trigger={trigger}
                              currency={salesOrderState.orderData.symbol}
                            ></SaleOrderPayment>
                            <div
                              style={{
                                color: "red",
                                float: "right",
                              }}
                            >
                              {errors.shipping?.message}
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <div className="search-not-found text-center">
                    <div>
                      <img
                        className="img-fluid second-search"
                        src={require("../../../../../assets/images/search-not-found.png")}
                        alt=""
                      />
                      <p> All Ordered items are invoiced</p>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <SalesOrderInvoiceView
                isEditable={isEditable}
                invoiceData={getValues}
                setValue={setValue}
                getValues={getValues}
                trigger={trigger}
                errors={errors}
                id={id}
                register={register}
                currency={salesOrderState?.orderData?.symbol}
              />
            )}

            {getValues("ordered_products").filter(function (v, i) {
              return v.is_selected;
            }).length > 0 &&
              isEditable && (
                <Row>
                  <Col md={12}>
                    <Card>
                      <CardBody>
                        <div className="pull-right">
                          <ButtonLoader
                            disabled={buttonDisable}
                            color="primary"
                            value="1"
                            type="submit"
                            btntext="Save"
                          ></ButtonLoader>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
          </Form>
        </Col>
        <Col md={3}>
          <BillingOrderDetail
            orderProducts={getValues("ordered_products").filter(function (
              v,
              i
            ) {
              return v.is_selected;
            })}
          ></BillingOrderDetail>
        </Col>
      </Row>
      <Row className="mt-3"></Row>
      <OrderDiscount
        orderSetValue={setValue}
        orderTrigger={trigger}
        orderGetValues={getValues}
      ></OrderDiscount>
      <OrderShipment
        draftOrderState={draftOrderState}
        orderSetValue={setValue}
        orderTrigger={trigger}
        orderGetValues={getValues}
      ></OrderShipment>
      <OrderTax
        orderSetValue={setValue}
        orderTrigger={trigger}
        orderGetValues={getValues}
      ></OrderTax>
      <SalesOrderDuty
        orderSetValue={setValue}
        orderTrigger={trigger}
        orderGetValues={getValues}
      ></SalesOrderDuty>
    </>
  );
};
export default withReducer([{ salesOrderInvoice: salesOrderInvoiceReducer }])(
  BillIngInvoice
);
