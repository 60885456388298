import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Button,
  Col,
  Card,
  CardBody,
  Row,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Popover,
  PopoverBody,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { setSalesBillingDialog } from "../../../store/salesOrderStatusSlice";
import {
  downloadSalesOrderPickingTickeBinPdf,
  downloadSalesOrderPickingTicketPdf,
  showSalesOrder,
} from "../../../store/salesOrderSlice";
import SaleOrderDiscussion from "../../view/content/saleOrderDiscussion";

const SalesOrderBillingModal = () => {
  const dispatch = useDispatch();
  const [statusName, setStatusName] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, isSetLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState({});
  const [enableDownloadPicking, setEnableDownloadPicking] = useState(false);
  const authData = useSelector(({ authReducer }) => authReducer);
  const salesBillingDialogProps = useSelector(
    (state) => state.order.saleOrderStatus.salesBillingDialogProps
  );
  const order_id = salesBillingDialogProps.data.id;
  const [discussionSection, setDiscussionSection] = useState(false);
  const processPDFDownload = (name) => {
    const orderId = salesBillingDialogProps.data.id;
    const data = {
      id: orderId,
      params: {
        name: name,
        seller: authData.defaultSeller?.value,
      },
    };

    dispatch(downloadSalesOrderPickingTicketPdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${salesBillingDialogProps.data.order_no}_${name.replace(
          /\s+/g,
          "-"
        )}_${new Date().toJSON().slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const processPickingBinPDFDownload = (name) => {
    const orderId = salesBillingDialogProps.data.id;
    const data = {
      id: orderId,
      params: {
        name: name,
        seller: authData.defaultSeller?.value,
      },
    };

    dispatch(downloadSalesOrderPickingTickeBinPdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${salesBillingDialogProps.data.order_no}_${name.replace(
          /\s+/g,
          "-"
        )}_${new Date().toJSON().slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const togglePopover = (order_id) => {
    setPopoverOpen((prevState) => ({
      ...prevState,
      [order_id]: !prevState[order_id],
    }));
  };

  const toggleModal = () => {
    closeDialog();
  };

  const downloadPicking = () => {
    processPDFDownload("Picking Ticket");
  };

  const downloadBinPicking = () => {
    processPickingBinPDFDownload("Picking Ticket By Bin");
  };

  const closeDialog = () => {
    const obj = { props: { isOpen: false }, type: "new", data: "" };
    dispatch(setSalesBillingDialog(obj));
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    if (salesBillingDialogProps.data) {
      setStatusName(salesBillingDialogProps.data.status || "");
      setDiscussionSection(salesBillingDialogProps.data.status_id === 3);
    }
  }, [salesBillingDialogProps.data]);

  useEffect(() => {
    isSetLoading(true);
    const orderId = salesBillingDialogProps.data.id;
    if (orderId) {
      dispatch(showSalesOrder({ id: orderId })).then((res) => {
        if (!res.error) {
          isSetLoading(false);
          setEnableDownloadPicking(res.payload.sum_back_order_quantity == 0);
        }
      });
    } else {
      isSetLoading(false);
    }
  }, [dispatch, order_id]);

  return (
    <Modal
      isOpen={salesBillingDialogProps.props.isOpen}
      toggle={toggleModal}
      size="xl"
    >
      <ModalHeader toggle={toggleModal}>
        <p
          style={{ fontSize: "20px" }}
        >{`Sales Order #${salesBillingDialogProps.data.order_no}`}</p>
        <span
          style={{
            fontWeight: "lighter",
            color: "rgb(115, 115, 115)",
            fontSize: "16px",
          }}
        >
          Status :{" "}
          <span
            style={{
              textTransform: "uppercase",
            }}
          >
            {statusName}
          </span>
        </span>
      </ModalHeader>
      <ModalBody>
        {salesBillingDialogProps.data && (
          <>
            <Container fluid={true}>
              <div className="d-flex mb-2 px-2">
                {enableDownloadPicking ? (
                  <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                    <DropdownToggle
                      color="warning"
                      className="btn btn-sm btn-warning"
                      style={{ height: 38, marginRight: 8 }}
                    >
                      Picking Ticket{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={downloadPicking}>
                        Picking Ticket by Style
                      </DropdownItem>
                      <DropdownItem onClick={downloadBinPicking}>
                        Picking Ticket by Bin
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                ) : (
                  <>
                    <Button
                      color="warning"
                      className="btn btn-sm btn-warning"
                      style={{ height: 38, marginRight: 8 }}
                      id={`pickingTicketPopover-${order_id}`}
                      disabled
                    >
                      Picking Ticket{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </Button>
                    <Popover
                      placement="bottom"
                      isOpen={popoverOpen[order_id] || false}
                      target={`pickingTicketPopover-${order_id}`}
                      toggle={() => togglePopover(order_id)}
                      trigger="hover"
                    >
                      <PopoverBody>
                        This order has back-order quantity.
                      </PopoverBody>
                    </Popover>
                  </>
                )}
              </div>

              <Col sm="12" className="p-2">
                {discussionSection && (
                  <Card
                    style={{
                      border: "1px solid rgb(230, 230, 230)",
                      borderRadius: "8px",
                    }}
                  >
                    <CardBody
                      style={{ paddingTop: "24px", paddingBottom: "24px" }}
                    >
                      <Row>
                        <Col sm="6">
                          <Label
                            style={{
                              fontWeight: "bolder",
                              fontSize: "16px",
                              paddingBottom: "10px",
                            }}
                          >
                            Discussion
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <SaleOrderDiscussion
                            salesOrderId={salesBillingDialogProps.data.id}
                            salesOrderComments={
                              salesBillingDialogProps.data.comments
                            }
                            isInputShow={false}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Container>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default SalesOrderBillingModal;
