import React, {useState} from "react";
import {Card, CardBody, Col, Popover, PopoverBody, Row, Table} from "reactstrap";
import {Link} from "react-router-dom";

const PopoverBasicItem = (props) => {
    const {id, quantity, commitedBins} = props;
    const [popover, setPopover] = useState(false)
    const Toggle = () => setPopover(!popover);
    return (
        <>
            <p style={{cursor: "pointer"}} className="example-popover" id={"Popover-" + id}>{quantity} <i
                className="fa fa-info-circle"></i></p>
            <Popover
                placement="top"
                isOpen={popover}
                target={"Popover-" + id}
                toggle={Toggle}
                trigger="hover"
            >
                <PopoverBody style={{minWidth:175}} >
                  <Row className="mb-3">
                    <Col md={12}>
                      <p>Orders</p>
                    </Col>
                  </Row>
                    {commitedBins.map((item, i) => (
                        <Row className="mb-3" key={i}>
                            <Col md={12}>
                                <Row>
                                  <Col md={6}>
                                     <p className="f-14">{item.bin_number}</p>
                                  </Col>
                                  <Col md={6}>
                                    <p className="f-14 text-right"> {item.quantity}</p>
                                  </Col>
                                </Row>
                                <>
                                  {item.orders.map((orderItem, orderIndex) => (
                                        <Row key={orderIndex}>
                                            <Col md={6}>
                                                <Link  className="f-12" target="_blank" to={`${process.env.PUBLIC_URL}/order/sale/${orderItem.order_id}/view`}>
                                                 <span style={{color:"blue",textDecoration:"underline"}}>{orderItem.order_no}</span>
                                                </Link>
                                            </Col>
                                          <Col md={6}>
                                             <p className="f-12 text-right"> {orderItem.quantity}</p>
                                          </Col>
                                        </Row>
                                    ))}
                                </>
                            </Col>
                        </Row>
                    ))}
                </PopoverBody>
            </Popover>
        </>
    );
};

function InventoryVariantDetail(props) {
    const {inventoryState} = props;

    return (
        <>
            <Card>
                <CardBody>
                    <Row>
                        <Col md={6}>
                            <img
                                src={inventoryState.variant.image}
                                className="img-fluid"
                            ></img>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={12}>
                                    <span className="f-w-600">SKU</span>
                                </Col>
                                <Col md={12}>
                                    <span className="f-w-100">{inventoryState.variant.sku}</span>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col md={12}>
                                    <span className="f-w-600">Title</span>
                                </Col>
                                <Col md={12}>
                  <span className="f-w-100">
                    {inventoryState.variant.product_name}
                  </span>
                                </Col>
                            </Row>
                            {inventoryState.variant.pack &&
                                !inventoryState.settings.is_stock_count_mode && (
                                    <Row className="mt-3">
                                        <Col md={12}>
                                            <span className="f-w-600">Pack Ratio</span>
                                        </Col>
                                        <Col md={12}>
                      <span className="f-w-100">
                        {inventoryState.variant.pack.ratio_name}
                      </span>
                                        </Col>
                                    </Row>
                                )}
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={12}>
                            <Row>
                                <Col md={12}>
                                    <span className="f-w-600">Quantity</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Row>
                                        <Col md={12} className="mt-1">
                                            <span className="f-w-100">Available</span>
                                        </Col>
                                        <Col md={12} className="mt-1">
                      <span className="f-w-100">
                        {inventoryState.variant.available_quantity}
                      </span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={4}>
                                    <Row>
                                        <Col md={12} className="mt-1">
                                            <span className="f-w-100">Committed </span>
                                        </Col>
                                        <Col md={12} className="mt-1">
                      <span className="f-w-100">
                        {/*<Link*/}
                          {/*  to={`${process.env.PUBLIC_URL}/order/inventory-commitment/${inventoryState.variant.sku}`}*/}
                          {/*  target="_blank"*/}
                          {/*>*/}
                          {inventoryState.variant.committed_quantity>0 ?<PopoverBasicItem
                              id={0}
                              quantity={inventoryState.variant.committed_quantity}
                              commitedBins={inventoryState.variant.committed_bins}
                          >

                          </PopoverBasicItem>:<p>{inventoryState.variant.committed_quantity}</p>}
                          {/*</Link>*/}
                          {/* {inventoryState.variant.committed_quantity} */}
                      </span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={4}>
                                    <Row>
                                        <Col md={12} className="mt-1">
                                            <span className="f-w-100">On Hand</span>
                                        </Col>
                                        <Col md={12} className="mt-1">
                      <span className="f-w-100">
                        {inventoryState.variant.on_hand_quantity}
                      </span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-5 d-sm-block">
                        <Col md={12}>
                            <div className="table-responsive w-100">
                                <Table>
                                    <thead className="thead-light">
                                    <tr>
                                        <th style={{width: "50%"}}>Bin</th>
                                        <th style={{width: "25%"}}>Available</th>
                                        <th style={{width: "25%"}}>Committed</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {inventoryState.variant.inventories.map((item, i) => (
                                        <tr key={i}>
                                            <td>{item.bin_number}</td>
                                            <td>{item.available}</td>
                                            <td>            <PopoverBasicItem
                                                id={i+1}
                                                quantity={item.committed_quantity}
                                                commitedBins={inventoryState.variant.committed_bins.filter(function (binItem){
                                                  return binItem.bin_number==item.bin_number;
                                                })}/>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    );
}

export default InventoryVariantDetail;
