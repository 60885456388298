import React, { Fragment, useEffect } from "react";
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { ModalFooter } from "react-bootstrap";
import * as yup from "yup";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setOrderInvoiceItemDiscountDialog } from "../../../store/draftOrderSlice";

const defaultValues = {
  discount_type: 1,
  value: "",
  reason: "",
  total_price: 0,
};
const schema = yup.object().shape({
  discount_type: yup.string().required("You must select type"),
  value: yup.number().when("discount_type", {
    is: (value) => value == 1,
    then: yup
      .number()
      .typeError("must be a number")
      .required("this field is required")
      .min(0.01, "should be grater than 0")
      .max(yup.ref("total_price")),
    otherwise: yup
      .number()
      .typeError("must be a number")
      .required("this field is required")
      .min(0.01, "should be grater than 0")
      .max(100, "Discount percentage cannot be more than 100"),
  }),
});

const OrderInvoiceItemDiscount = (props) => {
  const {
    orderSetValue,
    orderTrigger,
    orderGetValues,
    onChangeDialog,
    rowdata,
    orderedProducts,
    isDiscountValueChange,
    setDataToHookForm,
  } = props;
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState,
    reset,
    control,
    register,
    setValue,
    handleChange,
    trigger,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const orderState = useSelector(
    ({ order }) => order.draftOrder.itemInvoiceDiscountDialogProps
  );
  const { errors, isDirty } = formState;

  const closDialog = () => {
    dispatch(setOrderInvoiceItemDiscountDialog({ props: { isOpen: false } }));
  };

  useEffect(() => {
    if (orderState.props.isOpen) {
      // const discountData = [];
      const discountData = orderState.data.item_discount;
      const data = {
        discount_type: defaultValues.discount_type,
        value: "",
        total_price: orderState.data.total_price,
      };
      if (discountData && discountData.length > 0) {
        const [discount] = discountData;
        data.discount_type = discount.discount_type;
        data.value = discount.value;
      }

      reset(data);
      // setValue("discount_type", defaultValues.discount_type);
    }
  }, [orderState.props.isOpen]);

  const onChildSubmit = (data) => {
    const itemDiscount = [];

    itemDiscount.push({
      discount_type: data.discount_type,
      value: data.value,
      reason: data.reason,
    });

    orderSetValue("item_discount", itemDiscount[0]);
    orderTrigger("item_discount");
    const orderProducts = orderGetValues("ordered_products");
    const orderIndex = orderProducts.findIndex(
      (x) => x.product.variant === orderState.data.variant_id
    );
    const orderData = orderProducts.find(
      (obj) => obj.product.variant === orderState.data.variant_id
    );

    // const orderData = orderProducts[orderIndex];

    let discountedValue = parseFloat(data.value);
    if (data.discount_type === "2") {
      discountedValue = (parseFloat(data.value) / 100) * orderData.total_price;
    }

    const taxAmount = orderData.is_vat
      ? (orderData.total_price - discountedValue) * 0.2
      : 0;

    const grossAmount = orderData.total_price - discountedValue + taxAmount;

    orderProducts[orderIndex] = {
      ...orderData,
      item_discount: itemDiscount,
      quantity: orderData.quantity,
      discount: discountedValue,
      tax_amount: taxAmount,
      gross_price: grossAmount,
      // gross_price: orderData.gross_price - discountedValue,
    };
    orderSetValue("ordered_products", orderProducts);

    onChangeDialog(true);

    closDialog();
  };

  const handleRemoveDiscount = () => {
    const itemDiscount = [];
    orderSetValue("item_discount", itemDiscount);
    orderTrigger("item_discount");

    const orderProducts = orderGetValues("ordered_products");
    const orderIndex = orderProducts.findIndex(
      (x) => x.product.variant == orderState.data.variant_id
    );
    const orderData = orderProducts.find(
      (obj) => obj.product.variant === orderState.data.variant_id
    );

    const taxAmount = orderData.is_vat ? orderData.total_price * 0.2 : 0;

    const grossAmount = orderData.total_price + taxAmount;

    orderProducts[orderIndex] = {
      ...orderData,
      item_discount: itemDiscount,
      discount: 0,
      tax_amount: taxAmount,
      gross_price: grossAmount,
    };

    orderSetValue("ordered_products", orderProducts);
    orderTrigger("ordered_products");

    onChangeDialog(true);
    closDialog();
  };

  const enableButton = () => {
    if (orderState.props.isOpen) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Fragment>
      <div className="form-row mb-3">
        <Modal
          size="md"
          {...orderState.props}
          toggle={() => {
            closDialog();
          }}
        >
          <ModalHeader
            toggle={() => {
              closDialog();
            }}
          >
            {orderState.data?.sku}
          </ModalHeader>
          <Form noValidate="" onSubmit={handleSubmit(onChildSubmit)}>
            <ModalBody>
              {orderState.props.isOpen && (
                <>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label className="col-form-label">Discount Type</Label>
                        <Input
                          type="select"
                          name="select"
                          defaultValue={getValues("discount_type")}
                          onChange={(e) => {
                            setValue("discount_type", e.target.value);
                          }}
                          className={`form-control ${
                            errors.discount_type ? "is-invalid" : ""
                          }`}
                        >
                          <option value="1">Amount</option>
                          <option value="2">Percentage</option>
                        </Input>

                        <div className="invalid-feedback">
                          {errors.discount_type?.message}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label className="col-form-label">Value</Label>
                        <input
                          name="value"
                          type="text"
                          {...register("value")}
                          className={`form-control ${
                            errors.value ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.value?.message}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  {orderState.data?.item_discount?.length > 0 && (
                    <Row>
                      <Col md={12}>
                        <button
                          type="button"
                          className="btn btn-danger btn-sm float-right"
                          onClick={() => handleRemoveDiscount()}
                        >
                          Remove <i className="fa fa-trash"></i>
                        </button>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </ModalBody>
            <ModalFooter>
              {enableButton() && (
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleSubmit(onChildSubmit)}
                >
                  Save
                </button>
              )}
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </Fragment>
  );
};

function areEqual(prevProps, nextProps) {
  return (
    JSON.stringify(prevProps.getValues) == JSON.stringify(nextProps.getValues)
  );
}

export default OrderInvoiceItemDiscount;
// export default React.memo(OrderInvoiceItemDiscount, areEqual);
