import React, { Fragment, useRef } from "react";
import { Card, CardBody } from "reactstrap";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  setSalesDialog,
  setSalesInvoiceDialog,
  setSalesFulfillmentDialog,
  setSalesBillingDialog,
} from "../../../store/salesOrderStatusSlice";

const StatusList = ({ orders, orderIdKey, statusID }) => {
  const dispatch = useDispatch();
  const formatDateString = (dateString) => {
    return format(new Date(dateString), "dd/MM/yyyy");
  };
  return (
    <Fragment>
      <>
        {orders &&
          orders.map((order) => (
            <div
              key={order.id}
              className="text-center"
              style={{
                cursor:
                  order.status_id === 1 ||
                  order.status_id === 2 ||
                  order.status_id === 3 ||
                  order.status_id === 4 ||
                  order.status_id === 5 ||
                  order.status_id === 6
                    ? "pointer"
                    : "auto",
              }}
              data-order-id={order[orderIdKey]}
              onClick={() => {
                if (order.status_id === 1) {
                  const data = {
                    props: { isOpen: true },
                    type: "edit",
                    data: order,
                  };

                  dispatch(setSalesDialog(data));
                } else if (order.status_id === 6) {
                  const data = {
                    props: { isOpen: true },
                    type: "edit",
                    data: order,
                  };

                  dispatch(setSalesDialog(data));
                } else if (order.status_id === 2) {
                  const data = {
                    props: { isOpen: true },
                    type: "edit",
                    data: order,
                  };

                  dispatch(setSalesDialog(data));
                } else if (order.status_id === 4) {
                  const data = {
                    props: { isOpen: true },
                    type: "edit",
                    data: order,
                  };

                  dispatch(setSalesInvoiceDialog(data));
                } else if (order.status_id === 5) {
                  const data = {
                    props: { isOpen: true },
                    type: "edit",
                    data: order,
                  };

                  dispatch(setSalesFulfillmentDialog(data));
                } else if (order.status_id === 3) {
                  const data = {
                    props: { isOpen: true },
                    type: "edit",
                    data: order,
                  };

                  dispatch(setSalesBillingDialog(data));
                }
              }}
            >
              <Card>
                <CardBody style={{ padding: "20px" }}>
                  <h5>{`#${order.order_no}`}</h5>
                  <p>{formatDateString(order.created_at)}</p>
                </CardBody>
              </Card>
            </div>
          ))}
      </>
    </Fragment>
  );
};

export default StatusList;
