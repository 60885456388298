import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { toast } from "react-toastify";

import {
  getCurrencyRateApi,
  addSalesOrderApi,
  getSalesOrderApi,
  updateSalesOrderApi,
  showSalesOrderApi,
  editSaleOrderApi,
  getSalesOrderCommentApi,
  addSalesOrderCommentApi,
  showSalesOrderCommentApi,
  updateSalesOrderCommentApi,
  deleteSalesOrderCommentApi,
  updateSalesOrderStatusApi,
  downloadSalesOrderPickingTicketPdfApi,
  sendSalesOrderInvoiceToXeroApi,
  getSalesOrderFilterDataApi,
  downloadSalesOrderPdfApi,
  closeSalesOrderApi,
  viewSaleOrderPendingPickingApi,
  viewSaleOrderPendingPackingApi,
  getSalesOrderStatusesApi,
  viewSaleOrderItemLocationApi,
  updateSalesOrderItemLocationApi,
  downloadSalesOrderPickingTicketBinPdfApi,
  getSalesOrderDefaultShippingApi,
} from "../../../services/manage-order/saleOrderService";
import { downloadSalesOrderInvoicePdfApi } from "../../../services/manage-order/saleOrderInvoiceService";
import { viewSaleOrderFulfillmentApi } from "../../../services/manage-order/saleOrderFulfillmentService";

export const getSalesOrders = createAsyncThunk(
  "salesOrder/getSalesOrder",
  async (filters, { dispatch }) => {
    const response = await getSalesOrderApi(filters);
    dispatch(setTableRowCount(response.response.count));
    return response.response.data;
  }
);

export const getSalesOrderStatuses = createAsyncThunk(
  "salesOrder/getSalesOrderStatuses",
  async (filters, { dispatch }) => {
    const response = await getSalesOrderStatusesApi(filters);
    return response.response.data;
  }
);

export const getSalesOrderFilters = createAsyncThunk(
  "salesOrder/getFilterData",
  async (filters, { dispatch }) => {
    const response = await getSalesOrderFilterDataApi(filters);
    return response.response;
  }
);

export const getExchangeRate = createAsyncThunk(
  "sales-order/get-exchange-rate",
  async (data, { dispatch }) => {
    const response = await getCurrencyRateApi(data);
    return response.response;
  }
);

export const addSalesOrders = createAsyncThunk(
  "salesOrder/addSalesOrder",
  async (orderData, { dispatch, getState }) => {
    try {
      const response = await addSalesOrderApi(orderData);
      const data = await response.response;
      toast.success("Sales Order Created Successfully");
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const sendSalesOrderInvoiceToXero = createAsyncThunk(
  "salesOrder/sendSalesOrderInvoiceToXero",
  async (orderData, { dispatch, getState }) => {
    try {
      const response = await sendSalesOrderInvoiceToXeroApi(orderData);
      const data = await response.response;
      toast.success("Sales Order Invoice sent to Xero Successfully");
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const editSaleOrder = createAsyncThunk(
  "orders/draft/show",
  async ({ id }, { dispatch }) => {
    const response = await editSaleOrderApi(id);
    return response.response;
  }
);

export const getSalesOrder = createAsyncThunk(
  "salesOrder/showSalesOrder",
  async (id, { dispatch }) => {
    const response = await showSalesOrderApi(id);
    return response.response;
  }
);

export const updateSalesOrder = createAsyncThunk(
  "salesOrder/updateSalesOrder",
  async (orderData, { dispatch }) => {
    const response = await updateSalesOrderApi(orderData, orderData.id);
    toast.success(`Sales Order Updated Successfully`);
    return response.response;
  }
);

export const updateSalesOrderStatus = createAsyncThunk(
  "salesOrder/updateSalesOrderStatus",
  async (orderData, { dispatch }) => {
    const response = await updateSalesOrderStatusApi(orderData, orderData.id);
    toast.success(`Sales Order Status Updated Successfully`);
    return response.response;
  }
);

export const showSalesOrder = createAsyncThunk(
  "salesOrder/showSalesOrder",
  async ({ id }, { dispatch }) => {
    const response = await showSalesOrderApi(id);
    return response.response;
  }
);

// COMMENT
export const addSalesOrderComment = createAsyncThunk(
  "salesOrder/addSalesOrderComment",
  async (formData, { dispatch, getState }) => {
    try {
      const response = await addSalesOrderCommentApi(formData);
      const data = await response.response;
      toast.success("Sales Order Comment Created Successfully");
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const getSalesOrderComment = createAsyncThunk(
  "salesOrder/getSalesOrderComment",
  async (salesOrderId) => {
    const response = await getSalesOrderCommentApi({
      sales_order_id: salesOrderId,
    });
    return response.response.data;
  }
);

export const showSalesOrderComment = createAsyncThunk(
  "salesOrder/showSalesOrderComment",
  async (id, { dispatch }) => {
    const response = await showSalesOrderCommentApi(id);
    return response.response;
  }
);

export const updateSalesOrderComment = createAsyncThunk(
  "salesOrder/updateSalesOrderComment",
  async (orderData, { dispatch }) => {
    const response = await updateSalesOrderCommentApi(orderData, orderData.id);
    toast.success(`Sales Order Updated Successfully`);
    return response.response;
  }
);

export const deleteSalesOrderComment = createAsyncThunk(
  "salesOrder/deleteSalesOrderComment",
  async (id, { dispatch }) => {
    try {
      const response = await deleteSalesOrderCommentApi(id);
      const data = await response.response;
      toast.success("Comment Deleted Successfully");
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const downloadSalesOrderPickingTicketPdf = createAsyncThunk(
  "salesOrder/downloadPdfTicket",
  async (data, { dispatch }) => {
    const response = await downloadSalesOrderPickingTicketPdfApi(
      data.id,
      data.params
    );
    return response.response;
  }
);

export const downloadSalesOrderPickingTickeBinPdf = createAsyncThunk(
  "salesOrder/downloadPdfBinTicket",
  async (data, { dispatch }) => {
    const response = await downloadSalesOrderPickingTicketBinPdfApi(
      data.id,
      data.params
    );
    return response.response;
  }
);

export const downloadSalesOrderInvoicePdf = createAsyncThunk(
  "salesOrder/downloadInvoicePdf",
  async (data, { dispatch }) => {
    const response = await downloadSalesOrderInvoicePdfApi(
      data.id,
      data.params
    );
    return response.response;
  }
);

export const downloadSalesOrderPdf = createAsyncThunk(
  "salesOrder/downloadPdf",
  async (data, { dispatch }) => {
    const response = await downloadSalesOrderPdfApi(data.id, data.params);
    return response.response;
  }
);

export const showSalesOrderFulfillment = createAsyncThunk(
  "sales-order-fulfilment/view",
  async (id, { dispatch }) => {
    const response = await viewSaleOrderFulfillmentApi(id);
    return response.response;
  }
);

export const showSalesOrderPendingPicking = createAsyncThunk(
  "sales-order-pending-picking/view",
  async (id, { dispatch }) => {
    const response = await viewSaleOrderPendingPickingApi(id);
    return response.response;
  }
);

export const showSalesOrderPendingPacking = createAsyncThunk(
  "sales-order-pending-packing/view",
  async (id, { dispatch }) => {
    const response = await viewSaleOrderPendingPackingApi(id);
    return response.response;
  }
);

export const showSalesOrderItemLocation = createAsyncThunk(
  "sales-order-item-location/view",
  async (id, { dispatch }) => {
    const response = await viewSaleOrderItemLocationApi(id);
    return response.response;
  }
);

export const updateSalesOrderItemLocation = createAsyncThunk(
  "salesOrder/updateSalesOrderItemLocation",
  async (orderData, { dispatch }) => {
    const response = await updateSalesOrderItemLocationApi(
      orderData,
      orderData.id
    );
    toast.success(`Sales Order Bin Location Updated Successfully`);
    return response.response;
  }
);

export const closeCurrentSalesOrder = createAsyncThunk(
  "salesOrder/closeSalesOrder",
  async (orderData, { dispatch }) => {
    const response = await closeSalesOrderApi(orderData, orderData.id);
    toast.success(`Sales Order Closed Successfully`);
    return response.response;
  }
);

export const getSalesOrderDefaultShipping = createAsyncThunk(
  "salesOrder/defaultShipping",
  async (data, { dispatch }) => {
    const response = await getSalesOrderDefaultShippingApi(data);
    return response.response;
  }
);

const salesOrderAdapter = createEntityAdapter({});

export const { selectAll: selectSalesOrder, selectById: selectSalesOrderById } =
  salesOrderAdapter.getSelectors((state) => state.order.salesOrder);

const saleOrderSlice = createSlice({
  name: "salesOrder",
  initialState: salesOrderAdapter.getInitialState({
    id: null,
    noOfTableRows: 0,
    searchText: "",
    data: null,
    orderData: null,
    settings: {
      countries: [],
      shippings: [],
      types: [],
      statuses: [],
      customers: [],
      close_reasons: [],
      defaultShipping: null,
    },
    addressDialogProps: {
      props: {
        isOpen: false,
      },
      data: {},
    },
    dutyDialogProps: {
      props: {
        isOpen: false,
      },
      data: {},
    },
    inventoryDialogProps: {
      props: {
        isOpen: false,
      },
      data: {
        inventories: [],
      },
    },
    salesCloseDialogProps: {
      props: {
        isOpen: false,
      },
      data: {},
    },
    salesOrderPendingPickingDialogProps: {
      props: {
        isOpen: false,
      },
      data: {},
    },
    salesOrderPendingPackingDialogProps: {
      props: {
        isOpen: false,
      },
      data: {},
    },
    salesOrderBinLocationDialogProps: {
      props: {
        isOpen: false,
      },
      data: {},
    },
    products: {
      data: [],
      pagination: {
        count: 0,
        next_page: 1,
        total: 0,
        has_more: true,
      },
    },
    comments: [],
  }),
  reducers: {
    setSalesOrderFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTableRowCount: (state, action) => {
      state.noOfTableRows = action.payload;
    },
    setAddressDialog: (state, action) => {
      state.addressDialogProps = action.payload;
    },
    setDutyDialog: (state, action) => {
      state.dutyDialogProps = action.payload;
    },
    setOrderData: (state, action) => {
      state.orderData = action.payload;
    },
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    setInventoryDialog: (state, action) => {
      state.inventoryDialogProps = action.payload;
    },
    setSalesCloseDialog: (state, action) => {
      state.salesCloseDialogProps = action.payload;
    },
    setSalesOrderPendingPickingDialogProps: (state, action) => {
      state.salesOrderPendingPickingDialogProps = action.payload;
    },
    setSalesOrderPendingPackingDialogProps: (state, action) => {
      state.salesOrderPendingPackingDialogProps = action.payload;
    },
    setSalesOrderBinLocationDialogProps: (state, action) => {
      state.salesOrderBinLocationDialogProps = action.payload;
    },
  },
  extraReducers: {
    [getSalesOrders.fulfilled]: salesOrderAdapter.setAll,
    [getSalesOrderComment.fulfilled]: (state, action) => {
      state.comments = action.payload;
    },
    [getSalesOrderDefaultShipping.fulfilled]: (state, action) => {
      state.settings.defaultShipping = action.payload;
    },
  },
});
export const {
  setAddressDialog,
  setTableRowCount,
  setDutyDialog,
  setOrderData,
  setSettings,
  setInventoryDialog,
  setSalesCloseDialog,
  setSalesOrderPendingPickingDialogProps,
  setSalesOrderPendingPackingDialogProps,
  setSalesOrderBinLocationDialogProps,
} = saleOrderSlice.actions;

export default saleOrderSlice.reducer;
