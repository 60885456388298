import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { isObjectEqual } from "../../../../utils/common";
import { orderCommodities } from "../setting/calculation";

const BillingOrderDetail = (props) => {
  const { orderProducts = [] } = props;
  const dispatch = useDispatch();
  const salesOrderState = useSelector(({ order }) => order.salesOrder);

  return (
    <>
      {salesOrderState.orderData && (
        <div>
          <Card>
            <CardBody>
              <Row>
                <Col md={6}>
                  <strong>Order ID</strong>
                </Col>
                <Col md={6}>
                  <span>{salesOrderState.orderData.order_no}</span>
                </Col>
              </Row>
              {salesOrderState.orderData.is_online == 1 && (
                <Row className="mt-2">
                  <Col md={6}>
                    <strong>Shopify Order ID</strong>
                  </Col>
                  <Col md={6}>
                    <span>{salesOrderState.orderData.shopify_order_no}</span>
                  </Col>
                </Row>
              )}
              <Row className="mt-2">
                <Col md={6}>
                  <strong>Customer</strong>
                </Col>
                <Col md={6}>
                  <span>{salesOrderState.orderData.customer}</span>
                </Col>
              </Row>
              {salesOrderState.orderData.is_online !== 1 && (
                <Row className="mt-2">
                  <Col md={6}>
                    <strong>Customer PO #</strong>
                  </Col>
                  <Col md={6}>
                    <span>
                      {salesOrderState.orderData.customer_purchase_order_no}
                    </span>
                  </Col>
                </Row>
              )}
              <Row className="mt-2">
                <Col md={6}>
                  <strong>Currency</strong>
                </Col>
                <Col md={6}>
                  <span>{salesOrderState.orderData.currency}</span>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row>
                <Col md={12}>
                  <h6>Shipping Address</h6>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={4}>
                  <strong>Company</strong>
                </Col>
                <Col md={8}>
                  <span>
                    {salesOrderState.orderData.shipping_address.company}
                  </span>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={4}>
                  <strong>Address 1</strong>
                </Col>
                <Col md={8}>
                  <span>
                    {salesOrderState.orderData.shipping_address.address1}
                  </span>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={4}>
                  <strong>Address 2</strong>
                </Col>
                <Col md={8}>
                  <span>
                    {salesOrderState.orderData.shipping_address.address2}
                  </span>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={4}>
                  <strong>City</strong>
                </Col>
                <Col md={8}>
                  <span>{salesOrderState.orderData.shipping_address.city}</span>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={4}>
                  <strong>Zip</strong>
                </Col>
                <Col md={8}>
                  <span>{salesOrderState.orderData.shipping_address.zip}</span>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={4}>
                  <strong>Country</strong>
                </Col>
                <Col md={8}>
                  <span>
                    {salesOrderState.orderData.shipping_address.country}
                  </span>
                </Col>
              </Row>

              <Row className="mt-1">
                <Col md={4}>
                  <strong>Contact</strong>
                </Col>
                <Col md={8}>
                  <span>
                    {salesOrderState.orderData.shipping_address.phone}
                  </span>
                </Col>
              </Row>

              <Row className="mt-5">
                <Col md={12}>
                  <h6>Billing Address</h6>
                </Col>
              </Row>
              {isObjectEqual(
                salesOrderState.orderData.shipping_address,
                salesOrderState.orderData.billing_address
              ) ? (
                <>
                  <Row>
                    <Col md={12}>
                      <span>Same as Shipping Address</span>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  {" "}
                  <Row className="mt-2">
                    <Col md={4}>
                      <strong>Company</strong>
                    </Col>
                    <Col md={8}>
                      <span>
                        {salesOrderState.orderData.billing_address.company}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col md={4}>
                      <strong>Address 1</strong>
                    </Col>
                    <Col md={8}>
                      <span>
                        {salesOrderState.orderData.billing_address.address1}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col md={4}>
                      <strong>Address 2</strong>
                    </Col>
                    <Col md={8}>
                      <span>
                        {salesOrderState.orderData.billing_address.address2}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col md={4}>
                      <strong>City</strong>
                    </Col>
                    <Col md={8}>
                      <span>
                        {salesOrderState.orderData.billing_address.city}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col md={4}>
                      <strong>Zip</strong>
                    </Col>
                    <Col md={8}>
                      <span>
                        {salesOrderState.orderData.billing_address.zip}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col md={4}>
                      <strong>Country</strong>
                    </Col>
                    <Col md={8}>
                      <span>
                        {salesOrderState.orderData.billing_address.country}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col md={4}>
                      <strong>Contact</strong>
                    </Col>
                    <Col md={8}>
                      <span>
                        {salesOrderState.orderData.billing_address.phone}
                      </span>
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
          {orderProducts && orderProducts.length > 0 && (
            <Card>
              <CardBody>
                <h6 className="mb-3">Commodity Information</h6>
                {/* eslint-disable-next-line no-undef */}
                {orderCommodities(
                  orderProducts,
                  salesOrderState.orderData.symbol
                ).map((item, i) => (
                  <p key={i}>
                    {item.commodity} {item.quantity} Cost {item.amount}
                  </p>
                ))}
              </CardBody>
            </Card>
          )}
          <Card>
            <CardBody>
              <h6 className="mb-3">Special Instructions</h6>
              <span>{salesOrderState.orderData.memo}</span>
            </CardBody>
          </Card>
        </div>
      )}
    </>
  );
};

export default BillingOrderDetail;
