import React, {Fragment, useState} from 'react';
import {Card, CardBody, Col, FormGroup, Input, Label, Row, Table} from "reactstrap";
import ReactPaginate from "react-paginate";
import SearchVariantTable from "./searchVariantTable";
import {useFieldArray} from "react-hook-form";


function SearchProductTable(props) {
    const {getValues,item, index,productAppend,productFields,productRemove,trigger,setValue,control,selectedIndex} = props;

    const { fields, remove, append } = useFieldArray({
        control,
        name: `products.${selectedIndex}.variants`
    });

    const productChange=(e,item)=>{
        if(e.target.checked){
            productAppend(item)
        }else{
            productRemove(selectedIndex);
        }
    }
    const findVariantIndex = (variant) => {
        if( selectedIndex !==-1) {
            const findIndex = getValues(`products.${selectedIndex}.variants`).findIndex(item => item.id === variant.id);
            return findIndex;
        }else{
            return  -1;
        }
    }
    return (
        <Fragment key={index}>
            <tr>
                <td style={{width: '5%', height: 1}}><input type={"checkbox"}
                                                             checked={selectedIndex!==-1}
                                                            onChange={(e) => {
                                                                productChange(e,item);
                                                            }}
                /></td>
                <td style={{width: '15%', textAlign: 'center'}}><img width={20}
                                                                     src={item.url}/>
                </td>
                <td style={{textAlign: 'left'}}>{item.name}</td>
            </tr>
            <tr>
                <td colSpan={3} style={{
                    paddingTop: 0,
                    paddingRight: 25,
                    paddingLeft: 25,
                    border: "none"
                }}>
                    <table className="table" style={{width: '100%', marginTop: 0}}>
                        <tbody>
                        {item.variants.map((itemVariant, v) =>
                           <SearchVariantTable
                             item={itemVariant}
                             product={item}
                             key={v}
                             index={v}
                             selectedProductIndex={selectedIndex}
                             selectedVariantIndex={findVariantIndex(itemVariant)}
                             remove={remove}
                             append={append}
                             getValues={getValues}
                             trigger={trigger}
                             setValue={setValue}
                             productAppend={productAppend}
                           />
                        )}
                        </tbody>

                    </table>
                </td>
            </tr>

        </Fragment>
    );
}

export default SearchProductTable;