import React, { Fragment, useRef } from "react";
import JqxTable from "../../../../../../share-components/table/JqxTable";
import * as ReactDOMServer from "react-dom/server";
import { jqx } from "../../../../../../custom_modules/jqx/jqwidgets-react-tsx/jqxgrid";
import { useDispatch } from "react-redux";
import { findIndexes } from "../../../../../../utils/common";
const SaleOrderReturnProductJqx = (props) => {
  const { setValue, trigger, getValues, returnState, orderedProducts } = props;
  const dispatch = useDispatch();
  let gridRef = useRef(null);
  let selectRef = useRef(null);

  const reasonSources = (reasonData) => {
    return {
      datatype: "array",
      datafields: [
        { name: "id", type: "string" },
        { name: "name", type: "string" },
        { name: "is_restock", type: "string" },
      ],
      localdata: reasonData,
    };
  };

  const reasonAdapter: any = new jqx.dataAdapter(
    reasonSources(returnState.settings.return_reasons),
    { autoBind: true }
  );

  const datafields = [
    { name: "order_quantity", type: "number" },
    { name: "product", type: "array" },
    { name: "return_quantity", type: "number" },
    { name: "invoice_quantity", type: "number" },
    { name: "original_price", type: "number" },
    { name: "total_tax", type: "number" },
    { name: "sub_total", type: "number" },
    { name: "total_price", type: "number" },
    { name: "invoice_line_item_id", type: "number" },
    { name: "available_quantity", type: "number" },
    {
      name: "reason",
      value: "reasonID",
      values: { source: reasonAdapter.records, value: "id", name: "name" },
    },
    { name: "is_stock", type: "boolean" },
    { name: "is_selected", type: "boolean" },
  ];

  const columns = [
    { datafield: "invoice_line_item_id", hidden: true },
    { datafield: "tax_amount", hidden: true },
    {
      text: "Product",
      datafield: "product",
      editable: false,
      columntype: "text",
      align: "center",
      width: "35.6%",
      cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
        const productDetails = getValues("ordered_products")[row].product;
        const html = ReactDOMServer.renderToString(
          <div style={{ textAlign: "center", padding: 5 }}>
            {productDetails && (
              <div className="row">
                <div className="col-md-3">
                  <img
                    className="img-fluid"
                    src={productDetails.image}
                    width="40"
                    alt=""
                  />
                </div>
                <div className="col-md-9">
                  <div className="row">
                    <p className={"font-primary"}>{productDetails.name}</p>
                  </div>
                  <div className="row">
                    <p>{productDetails.option}</p>
                  </div>
                  <div className="row">
                    <p>{productDetails.sku}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
        return html;
      },
    },
    {
      text: "Order Qty",
      datafield: "order_quantity",
      editable: false,
      columntype: "text",
      width: "10%",
      cellsalign: "right",
      align: "center",
      cellsrenderer(
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) {
        const productDetails = getValues("ordered_products")[row];
        const html = ReactDOMServer.renderToString(
          <div style={{ textAlign: "center", padding: 5 }}>
            <div className="row mt-2">
              <div className="col-12">
                <div className="col-md-12 p-1">
                  <div className="row">
                    <div className="col-4">
                      <p className="font-primary text-left font-weight-bolder">
                        Order Qty
                      </p>
                    </div>
                    <div className="col-8">
                      <p className="text-right">{rowdata.order_quantity}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <p className="font-primary text-left font-weight-bolder">
                        Invoice Qty
                      </p>
                    </div>
                    <div className="col-8">
                      <p className="text-right">{rowdata.invoice_quantity}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        return html;
      },
      // cellsformat: 'c2',
    },
    {
      text: "Available Qty",
      datafield: "available_quantity",
      editable: false,
      columntype: "text",
      width: "8%",
      cellsalign: "right",
      align: "center",
    },
    {
      text: "Return Qty",
      datafield: "return_quantity",
      editable: true,
      columntype: "text",
      width: "8%",
      cellsalign: "right",
      align: "center",
      cellbeginedit(row, datafield, columntype, val) {
        let found = 0;
        gridRef
          .getselectedrowindexes()
          .forEach((element) => (element === row ? found++ : ""));
        return found > 0;
      },
      cellclassname: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) {
        return "editable-column";
      },
      validation: function (cell, value) {
        const stock = gridRef.getcellvalue(cell.row, "available_quantity");
        const selected = gridRef.getcellvalue(cell.row, "is_selected");

        const isDecimal = value % 1 !== 0;
        const isNegative = value < 0;

        if (value < 1 && selected) {
          return {
            result: false,
            message: "should be greater than 0",
          };
        } else if (Number(stock) < Number(value) && selected) {
          return {
            result: false,
            message: "Should be less than the Ordered Quantity",
          };
        } else if (isDecimal || isNegative) {
          return {
            result: false,
            message: "Must be a non-negative integer.",
          };
        } else {
          return true;
        }
        // if (Number(stock) < Number(value)) {
        //   return {
        //     result: false,
        //     message: "should be less than invoiced Quantity",
        //   };
        // } else {
        //   return true;
        // }
      },
    },
    {
      text: "Rate",
      datafield: "original_price",
      editable: false,
      columntype: "text",
      width: "8%",
      cellsalign: "right",
      align: "center",
      cellsformat: "c2",
    },
    {
      text: "Amount",
      datafield: "total_price",
      editable: false,
      columntype: "text",
      width: "8%",
      cellsalign: "right",
      align: "center",
      cellsformat: "c2",
    },
    {
      text: "Request Reason",
      datafield: "reasonID",
      displayfield: "reason",
      width: "20.5%",
      align: "center",
      columntype: "dropdownlist",
      editable: true,
      cellclassname: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) {
        return "editable-column";
      },
      createeditor: (row: number, value: any, editor: any): void => {
        editor.jqxDropDownList({
          height: 75,
          source: reasonAdapter,
          displayMember: "name",
          valueMember: "id",
        });
      },
    },
  ];
  const getJqxRef = (jqx) => {
    if (jqx) {
      gridRef = jqx;

      setTimeout(() => {
        if (gridRef) {
          const myEle = document.getElementById(gridRef._id);
          if (myEle) {
            gridRef.updatebounddata();
          }
        }
      }, 1000);
    }
  };

  const cellEdit = (event) => {
    const rowArgs = event.args;
    let value;
    if (rowArgs.datafield == "return_quantity") {


      const singlePrice = gridRef.getcellvalue(
        rowArgs.rowindex,
        "original_price"
      );

      const availableQuantity = gridRef.getcellvalue(
        rowArgs.rowindex,
        "available_quantity"
      );
      if (rowArgs.value > availableQuantity || rowArgs.value < 1) {
        const quantity = gridRef.getcellvalue(
          rowArgs.rowindex,
          "return_quantity"
        );
        rowArgs.value = quantity;
      }

      value = rowArgs.value;
      value = parseInt(value, 10);
      if (isNaN(value) || value < 0) {
        value = 0;
      }

      if (value > availableQuantity) {
        value = availableQuantity;
      }
      // setTotalAmount(rowArgs.rowindex, rowArgs.value, singlePrice);
      setTotalAmount(rowArgs.rowindex, value, singlePrice);
    }
    gridRef.setcellvalue(rowArgs.rowindex, rowArgs.datafield, value);
    // gridRef.setcellvalue(rowArgs.rowindex, rowArgs.datafield, rowArgs.value);
    setDataToHookForm();
  };

  const setTotalAmount = (index, quantity, singlePrice) => {
    const totalPrice = quantity * singlePrice;
    gridRef.setcellvalue(index, "total_price", totalPrice);
  };
  const setDataToHookForm = () => {
    let orderData = [];

    const gridInformation = gridRef.getdatainformation();
    for (let i = 0; i < gridInformation.rowscount; i++) {
      const rowData = gridRef.getrowdata(i);
      orderData.push(rowData);
    }
    setValue("ordered_products", orderData);
  };

  const handelRowSelect = (event) => {
    const orderProducts = getValues("ordered_products");
    const rowIndexes = gridRef.getselectedrowindexes();

    const orderData = orderProducts.map((item, index) => {
      const taxAmount = item.is_tax ? item.rate * 0.2 : 0;
      const isSelected = rowIndexes.includes(index);

      return {
        ...item,
        return_quantity:isSelected? 1:0,
        total_price: item.original_price,
        is_selected: isSelected,
      };
    });

    const selectedData = orderData
      .filter((item, index) => rowIndexes.includes(index))
      .map((item) => ({ ...item, is_selected: true }));

    setValue("ordered_products", orderData);
    trigger("ordered_products");
  };

  return (
    <Fragment>
      <div style={{ width: "100%" }}>
        {getValues("ordered_products").length > 0 && (
          <JqxTable
            rowsheight={75}
            datafields={datafields}
            columns={columns}
            data={getValues("ordered_products")}
            myGrid={gridRef}
            getJqxRef={getJqxRef}
            autoheight={getValues("ordered_products").length < 7}
            scrollerbar
            height="500"
            onCellendedit={cellEdit}
            disableCreateRow
            onRowselect={handelRowSelect}
            onRowunselect={handelRowSelect}
            selectionmode={"checkbox"}
            selectedrowindexes={findIndexes(
              getValues("ordered_products"),
              (item) => item.is_selected
            )}
          />
        )}
      </div>
    </Fragment>
  );
};

function areEqual(prevProps, nextProps) {
  return (
    JSON.stringify(prevProps.orderedProducts) !=
    JSON.stringify(nextProps.orderedProducts)
  );
}

export default SaleOrderReturnProductJqx;
