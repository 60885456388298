import React, { Fragment, useEffect, useRef, useState } from "react";
import JqxTable from "../../../../../../share-components/table/JqxTable";
import { toast } from "react-toastify";
import * as ReactDOMServer from "react-dom/server";
import { findIndexes } from "../../../../../../utils/common";

const SalesOrderProductFulfillmentJqx = (props) => {
  const { setValue, trigger, getValues, orderedProducts, onChangeDialog } =
    props;
  let gridRef = useRef(null);
  let selectRef = useRef(null);
  const [orderData, setOrderData] = useState([]);
  const datafields = [
    { name: "id", type: "number" },
    { name: "sales_order_line_item_id", type: "number" },
    { name: "order_quantity", type: "number" },
    { name: "product", type: "array" },
    { name: "pending_quantity", type: "number" },
    { name: "return_quantity", type: "number" },
    { name: "fulfillment_quantity", type: "number" },
    { name: "is_selected", type: "boolean" },
  ];

  const columns = [
    { datafield: "sales_order_line_item_id", hidden: true },
    { datafield: "is_selected", hidden: true },
    {
      text: "Product",
      datafield: "product",
      editable: false,
      columntype: "text",
      width: "57%",
      cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
        const productDetails = getValues("ordered_products")[row].product;
        const html = ReactDOMServer.renderToString(
          <div style={{ textAlign: "center", padding: 5 }}>
            {productDetails && (
              <div className="row">
                <div className="col-md-2">
                  <img
                    className="img-fluid"
                    src={productDetails.image}
                    width="40"
                    alt=""
                  />
                </div>
                <div className="col-md-10">
                  <div className="row">
                    <p className={"font-primary"}>{productDetails.name}</p>
                  </div>
                  <div className="row">
                    <p>{productDetails.option}</p>
                  </div>
                  <div className="row">
                    <p>{productDetails.sku}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
        return html;
      },
    },
    {
      text: "Order Qty",
      datafield: "order_quantity",
      editable: false,
      columntype: "text",
      width: "20%",
      cellsalign: "right",
      align: "right",
      cellsrenderer(
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) {
        const productDetails = getValues("ordered_products")[row];
        const html = ReactDOMServer.renderToString(
          <div style={{ textAlign: "center", padding: 1 }}>
            <div className="row mt-1">
              <div className="col-12">
                <div className="col-md-12 p-1">
                  <div className="row">
                    <div className="col-4">
                      <p className="font-primary text-left font-weight-bolder">
                        Order Qty
                      </p>
                    </div>
                    <div className="col-8">
                      <p className="text-right">{rowdata.order_quantity}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <p className="font-primary text-left font-weight-bolder">
                        Return Qty
                      </p>
                    </div>
                    <div className="col-8">
                      <p className="text-right">{rowdata.return_quantity}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <p className="font-primary text-left font-weight-bolder">
                        Pending Qty
                      </p>
                    </div>
                    <div className="col-8">
                      <p className="text-right">{rowdata.pending_quantity}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        return html;
      },
    },
    {
      text: "Fulfillment Qty",
      datafield: "fulfillment_quantity",
      editable: true,
      columntype: "text",
      width: "20%",
      cellsalign: "right",
      align: "right",
      cellbeginedit(row, datafield, columntype, val) {
        let found = 0;
        gridRef
            .getselectedrowindexes()
          .forEach((element) => (element === row ? found++ : ""));
        return found > 0;
      },
      cellclassname: function (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) {
        return "editable-column";
      },
      validation: function (cell, value) {
        const stock = gridRef.getcellvalue(cell.row, "pending_quantity");
        const selected = gridRef.getcellvalue(cell.row, "is_selected");

        const isDecimal = value % 1 !== 0;
        const isNegative = value < 0;
        if (value < 1 && selected) {
          toast.error("should be greater than 0");
          return {
            result: false,
            message: "should be greater than 0",
          };
        } else if (Number(stock) < Number(value) && selected) {
          toast.error("Should be less the Pending Quantity");
          return {
            result: false,
            message: "Should be less the Pending Quantity",
          };
        } else if (isDecimal || isNegative) {
          return {
            result: false,
            message: "Must be a non-negative integer.",
          };
        } else {
          return true;
        }
      },
    },
  ];
  const getJqxRef = (jqx) => {
    if (jqx) {
      gridRef = jqx;

      setTimeout(() => {
        if (gridRef) {
          const myEle = document.getElementById(gridRef._id);
          if (myEle) {
            gridRef.updatebounddata();
          }
        }

        // myGrid.updatebounddata();
      }, 1000);
    }
  };
  const onDelete = (data) => {
    setDataToHookForm();
  };

  const cellEdit = (event) => {
    const rowArgs = event.args;

    if (rowArgs.datafield == "fulfillment_quantity") {
      const pendingQuantity = gridRef.getcellvalue(
        rowArgs.rowindex,
        "pending_quantity"
      );

      if (rowArgs.value > pendingQuantity) {
        const quantity = gridRef.getcellvalue(rowArgs.rowindex, "fulfillment_quantity");
        rowArgs.value = quantity;
      }
    }

    gridRef.setcellvalue(rowArgs.rowindex, rowArgs.datafield, rowArgs.value);
    setDataToHookForm();
  };

  const setDataToHookForm = () => {
    let orderData = [];

    const gridInformation = gridRef.getdatainformation();
    for (let i = 0; i < gridInformation.rowscount; i++) {
      const rowData = gridRef.getrowdata(i);
      orderData.push(rowData);
    }
    setValue("ordered_products", orderData);
    onChangeDialog(true);
  };

  const handelRowSelect = (event) => {
    const orderProducts = getValues("ordered_products");
    const orderData = [];
    const rowIndexes = gridRef.getselectedrowindexes();
    orderProducts.map(function (item, index) {
      const isSelected = rowIndexes.includes(index);
      const quantity = isSelected
        ? item.fulfillment_quantity
          ? item.fulfillment_quantity
          : item.pending_quantity
        : 0;
      orderData.push({
        ...item,
        fulfillment_quantity: quantity,
        is_selected: rowIndexes.includes(index),
      });
      gridRef.setcellvalue(index, "fulfillment_quantity", quantity);
      gridRef.setcellvalue(index, "is_selected", true);
    });
    setValue("ordered_products", orderData);
    onChangeDialog(true);
  };

  return (
    <Fragment>
      <div style={{ width: "100%" }}>
        {getValues("ordered_products").length > 0 && (
          <JqxTable
            deleteAll
            rowsheight={75}
            datafields={datafields}
            columns={columns}
            data={getValues("ordered_products")}
            myGrid={gridRef}
            getJqxRef={getJqxRef}
            autoheight={getValues("ordered_products").length < 7}
            scrollerbar
            height="500"
            onCellendedit={cellEdit}
            disableCreateRow
            onDelete={onDelete}
            onRowselect={handelRowSelect}
            onRowunselect={handelRowSelect}
            selectionmode={"checkbox"}
            selectedrowindexes={findIndexes(
              getValues("ordered_products"),
              (item) => item.is_selected
            )}
          />
        )}
      </div>
    </Fragment>
  );
};

function areEqual(prevProps, nextProps) {
  return (
    JSON.stringify(prevProps.isProductValueChange) ==
    JSON.stringify(nextProps.isProductValueChange)
  );
}

export default React.memo(SalesOrderProductFulfillmentJqx, areEqual);
