import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Breadcrumb from '../../../../layout/breadcrumb'
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody, FormGroup, Label, Form, Button,

} from 'reactstrap'
import withReducer from "../../../../store/withReducer";
import productReducer from "../../../manage-product/store";
import * as yup from "yup";
import {
    createDuplicateProduct,
    getProduct,
    getProductCreateSettings,
    getProductSettings,
    setProduct,
    setProductSettings, setProductSubCategory, updateProduct, createSentImage
} from "../../store/prodcutSlice";
import {
    getProductCreateSetting,
    sellerBrandSuggestionApi,
    taxonomySuggestionApi
} from "../../../../services/manage-product/productService";
import Select from "react-select";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import UserForm from "../../../manage-user/user/userForm";
import General from "./content/general";
import Composition from "./content/composition";
import ProductImage from "./content/productImage";
import Variation from "./content/variation";
import moment from "moment";
import {useHistory, useParams} from "react-router-dom";
import {updateUser} from "../../../manage-user/store/userSlice";
import ProductSeo from "./content/seo";
import ButtonLoader from "../../../../share-components/ui/buttonLoader";
import PreLoader from "../../../../share-components/ui/preLoader";
import {mapSearchAutoSuggestion} from "../../../../utils/mapper";
import SweetAlert from "sweetalert2";
import AdditionalDetails from './content/additionalDetails';
import LogActivity from "../../../log";
import {toggleQuickPanel} from "../../../log/store/logSlice";

const defaultValues = {
    id: '',
    brand: '',
    code: 3343,
    name: null,
    description: null,
    summary: null,
    measurements: '',
    product_type: '',
    product_category: '',
    product_sub_category: '',
    country: '',
    tag_type: '',
    season: '',
    taxonomy: '',
    tags: [],
    styles: [],
    custom_tags: [],
    is_pre_order: false,
    is_dispatch_date: false,
    no_of_days: null,
    dispatch_date: null,
    commodity_code: null,
    weight: null,
    unit_type: 1,
    compositions: [{}],
    selling_type: [],
    color: [],
    size_configuration: null,
    size: [],
    price: null,
    sale_price: null,
    cost_per_item_price: null,
    rrp: null,
    images: [],
    variation_configurations: [],
    meta_title: null,
    meta_description: null,
    shopify_product_id: null,
    is_personalisation: null,
    personalisation_text: null,
    buyer_instruction: null,
    fit_size_condition: [],
    fit_type: [],
    model_height: null,
    model_ware_size: null,
    is_holiday_mode: false,
    holiday_end_date: '',
    bin_numbers: [],
    is_has_draft_product:false,
    is_third_party_seller:false,
    committed_quantity:[]
};

/**
 * Form Validation Schema
 */
yup.addMethod(yup.mixed, "holidayModeRequired", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        const {path, createError} = this;
        let isValid = true;
        if (this.parent.is_holiday_mode) {
            isValid = value ? true : false;
        }
        return (
            (isValid) ||
            createError({path, message: errorMessage})
        );
    });
});
yup.addMethod(yup.array, "colourTag", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        const {path, createError} = this;
        let isValidTag = true;
        let isValidColour = false;
        value.map((item, index) => {
            if (item.name) {
                isValidColour = true;
                if (!item.tag_name) {
                    isValidTag = false;
                }
            }
        });
        return (
            (isValidTag && isValidColour) ||
            createError({path, message: errorMessage})
        );
    });
});
yup.addMethod(yup.array, "packRatio", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        const {path, createError} = this;
        let isValid = true
        const sellingType = this.parent.selling_type.map(function (item) {
            return item.id
        })
        if (sellingType.includes(2)) {
            value.map((item, index) => {
                if (item.ratio < 1) {
                    isValid = false;
                }
            });
        }
        return (
            (isValid) ||
            createError({path, message: errorMessage})
        );
    });
});

const schema = yup.object().shape({
    brand: yup.object().shape({id: yup.string(),}).nullable().required('You must select a brand.'),
    name: yup.string().required('You must enter Product Name'),
    code: yup.string().required('You must enter style/sku code'),
    tag_type: yup.object().shape({id: yup.string(),}).nullable().required('You must select a  Tag type.'),
    product_type: yup.object().shape({id: yup.string(),}).nullable().required('You must select a  type.'),
    product_category: yup.object().shape({id: yup.string(),}).nullable().required('You must select a category.'),
    product_sub_category: yup.object().shape({id: yup.string(),}).nullable().required('You must select a sub category.'),
    country: yup.object().shape({id: yup.string(),}).nullable().required('You must select a  country.'),
    season: yup.object().shape({id: yup.string(),}).nullable().required('You must select a season.'),
    price: yup.number().transform(value => (isNaN(value) ? undefined : value)).required('You must enter price'),
    hs_code: yup.number().transform(value => (isNaN(value) ? undefined : value)).typeError('should be a number').test(
        "len",
        "can be empty or should be greater than 5",
        (val) => {
            if (val === undefined) {
                return true;
            }
            return val.toString().length === 0 || (val.toString().length >= 6);
        }
    ),
    // sale_price: yup.number().transform(value => (isNaN(value) ? undefined : value)).required('You must enter sale price').moreThan(yup.ref('cost_per_item_price'), "sale price should be grater than  cost price"),
    cost_per_item_price: yup.number().transform(value => (isNaN(value) ? undefined : value)).required('You must enter cost Price'),
    color: yup.array().min(1, "you must select a colour"),
    size: yup.array().packRatio('you must fill all size ratio'),
    selling_type: yup.array().min(1, "you must select a selling type"),
    size_configuration: yup.object().shape({id: yup.string(),}).nullable().required('You must select a size Configuration'),
    holiday_end_date: yup.mixed().holidayModeRequired('you must enter Holiday Mode')
});
const dataMenu = [
    {
        type: 1,
        text: 'Products',
        link: '/product'
    },
    {
        type: 0,
        text: "Edit Product"
    }
];

const EditProduct = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [isButtonVisible, setIsButtonVisible] = useState(false);

    const productState = useSelector(({product}) => product.product.productSettings);
    const productStates = useSelector(({product}) => product.product);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    const {handleSubmit, formState, reset, control, register, setValue, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const {errors, isDirty} = formState;
    useEffect(() => {
        if (id) {
            // history.goBack();
            window.scrollTo(0, 0)
            dispatch(getProductCreateSettings({id: id})).then(res => {
                if (!res.error) {
                    dispatch(setProductSettings(res.payload))
                    dispatch(getProduct(id)).then(res => {
                        if (!res.error) {
                            dispatch(setProductSubCategory(res.payload.sub_categories))
                            reset(res.payload.product);
                            setLoading(false);
                            setTimeout(function () {
                                setIsButtonVisible(true);
                            }, 2000)
                        }
                    });}
                ;
            });
            dispatch(toggleQuickPanel({isOpen:false}))
        }

    }, [dispatch, id]);

    const onSubmit = (data) => {
        const buttonType = window.event.submitter.value
        data.brand = data.brand.id;
        data.country = data.country.id;
        data.product_type = data.product_type.id;
        data.product_category = data.product_category.id;
        data.product_sub_category = data.product_sub_category.id;
        data.taxonomy = data.taxonomy ? data.taxonomy.id : "";
        data.tag_type = data.tag_type.id;
        data.model_ware_size = data.model_ware_size.id;
        if (data.size_configuration) {
            data.size_configuration = data.size_configuration.id;
        }
        if (data.commodity) {
            data.commodity = data.commodity.id;
        }
        data.season = data.season.id;
        let tags = [];
        (data.tags).map((item, i) => {
            tags.push(item.id);
        });
        data.tags = tags;
        let styles = [];
        (data.styles).map((item, i) => {
            styles.push(item.id)
        });
        data.styles = styles;
        data.dispatch_date = data.dispatch_date ? moment(data.dispatch_date).format('YYYY-MM-DD') : null;
        data.holiday_end_date = data.holiday_end_date ? moment(data.holiday_end_date).format('YYYY-MM-DD') : null;

        let selling_type = [];
        (data.selling_type).map((item, i) => {
            selling_type.push(item.id)
        });
        data.selling_type = selling_type;

        let colors = [];
        (data.color).map((item, i) => {
            if (item.id) {

                colors.push({
                    colour_id: item.id,
                    tag_name: item.tag_name,
                    is_published: item.is_published,
                    product_image_id: item.product_image_id,
                    pack_for_single: item.pack_for_single,
                    making_video_url: item.making_video
                });
            }
        });
        data.color = colors;

        let size = [];
        (data.size).map((item, i) => {
            size.push({
                size_matrix_id: item.id,
                ratio: (data.selling_type.length > 1 || (data.selling_type).includes(2)) ? item.ratio : 0
            })
        });
        data.size = size;

        let composition = [];
        (data.compositions).map((item, i) => {
            if (item.composition_id) {
                composition.push({composition_id: Number(item.composition_id), percentage: item.percentage});
            }
        });
        let fit_type = [];
        (data.fit_type).map((item, i) => {
            fit_type.push(item.id)
        });
        data.fit_type = fit_type;

        let fit_size_condition = [];
        (data.fit_size_condition).map((item, i) => {
            fit_size_condition.push(item.id)
        });
        data.fit_size_condition = fit_size_condition;
        data.compositions = composition;
        data.isPublish = buttonType;
        setButtonDisable(true);
        dispatch(updateProduct({...data})).then(res => {
            setButtonDisable(false);
            if (!res.error) {
                history.push({
                    pathname: '/product',
                    state: {from: "submit"}
                })
            }
        });

    }
    const onConfirmDuplicate = (row) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Do you want to create duplicate product?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Create',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) {
                    dispatch(createDuplicateProduct({id})).then(res => {
                        console.log("id", id);
                        setButtonDisable(false);
                        if (!res.error) {
                            history.push({
                                pathname: `/product/${res.payload}/edit`,
                            })
                        }
                    });
                }
            })
    }

    const onConfirmSendImage = (row) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Do you want to Send Image",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Send',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) {
                    dispatch(createSentImage({id})).then(res => {
                        setButtonDisable(false);
                        console.log("image1", res);
                        if (!res.error) {

                        }
                    });
                }
            })
    }

    return (
        <Fragment>

            <Container fluid={true}>
                {loading ? <>
                    <PreLoader></PreLoader>
                </> : <Row>
                    <Breadcrumb title={getValues('name')} data={dataMenu}/>
                    {(currentUser.modules).includes('create-duplicate') && <Col sm="12">
                        <Button onClick={onConfirmDuplicate} className="pull-right  font-weight-bold" color="success"
                                type="button">Generate Duplicate
                        </Button>

                    </Col>}
                    <div style={{position: 'fixed', top: '50%', zIndex: 10,right:0}}>


                        <LogActivity log="product" id={id}></LogActivity>

                    </div>

                    <AdditionalDetails productStates={productStates}/>
                    <Col sm="12">
                        <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                            <General control={control} errors={errors} register={register} setValue={setValue}
                                     currentUser={currentUser}
                                     trigger={trigger} productState={productState} getValues={getValues}
                                     productStates={productStates}></General>
                            <Composition control={control} errors={errors} register={register} setValue={setValue}
                                         trigger={trigger} productState={productState}
                                         getValues={getValues}></Composition>
                            <ProductImage productState={productState} setValue={setValue} getValues={getValues}
                                          onConfirmSendImage={onConfirmSendImage} currentUser={currentUser}
                                          trigger={trigger}></ProductImage>
                            <div style={{marginBottom: 180}}>
                                <Variation control={control} errors={errors} register={register} setValue={setValue}
                                           setIsButtonVisible={setIsButtonVisible} productStates={productStates} currentUser={currentUser}
                                           trigger={trigger} productState={productState} modules={currentUser.modules}
                                           getValues={getValues}></Variation>
                                <ProductSeo control={control} errors={errors} register={register} setValue={setValue}
                                            trigger={trigger} getValues={getValues}></ProductSeo>
                            </div>
                            <div id="submit-div">
                                {isButtonVisible && <div className="w-100">
                                    <div style={{display: "flex", justifyContent: 'flex-end'}}>
                                        <Card style={{position: 'fixed', bottom: 35, zIndex: 2}}>
                                            <CardBody>
                                                <div className="form-row">
                                                    <Col md={12}>
                                                        <div className="float-sm-right">
                                                            {!getValues('shopify_product_id') &&
                                                                <ButtonLoader color="success" value="0" className="mr-2"
                                                                              type="submit" disabled={buttonDisable}
                                                                              btntext="Save"></ButtonLoader>}
                                                            {((currentUser.permission).includes('Publish Product') || getValues('shopify_product_id')) &&
                                                                <ButtonLoader disabled={buttonDisable} color="primary"
                                                                              value="1"
                                                                              type="submit"
                                                                              btntext="Save & Publish"></ButtonLoader>}
                                                        </div>
                                                    </Col>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>}
                            </div>
                        </Form>
                    </Col>
                </Row>}
            </Container>


        </Fragment>
    );

}

export default withReducer([{product: productReducer}])(EditProduct);