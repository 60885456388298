import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
    getSalesOrderReturnReasonsApi,
    addSalesOrderReturnApi,
    getSalesOrderPendingReturnApi,
    editSalesOrderPendingReturnApi,
     editSalesOrderReturnApi, updateSalesOrderPendingReturnApi,
} from "../../../services/manage-order/saleOrderReturnService";

export const getAllReturnReasons = createAsyncThunk(
  "sales-order/getRerurnReasons",
  async (filters, { dispatch }) => {
    const response = await getSalesOrderReturnReasonsApi(filters);
    return response.response;
  }
);

export const getPendingReturnRequest = createAsyncThunk(
    "sales-order/getRerurnReasons",
    async (filters, { dispatch }) => {
      const response = await getSalesOrderPendingReturnApi(filters);
      dispatch(setTableRowCount(response.response.count));
      return response.response.data;
    }
);
export const editPendingReturnRequest = createAsyncThunk(
    "sales-order/getRerurnReasons",
    async (id, { dispatch }) => {
      const response = await editSalesOrderPendingReturnApi(id);
      return response.response;
    }
);




export const addSalesOrderReturn = createAsyncThunk(
  "sales-order/addReturn",
  async (orderData, { dispatch, getState }) => {
    try {
      const response = await addSalesOrderReturnApi(orderData);
      const data = await response.response;
      toast.success("Sales Order Return Added Successfully");

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);
export const updateSalesOrderReturn = createAsyncThunk(
    "sales-order/addReturn",
    async (orderData, { dispatch, getState }) => {
        try {
            const response = await updateSalesOrderPendingReturnApi(orderData,orderData.id);
            const data = await response.response;
            toast.success("Sales Order Return Added Successfully");

            return data;
        } catch (e) {
            return Promise.reject(e);
        }
    }
);

const salesOrderReturnAdapter = createEntityAdapter({});

export const { selectAll: selectAllPendingReturns, selectById: selectPendingReturnById } = salesOrderReturnAdapter.getSelectors((state) => state.order.salesOrderReturn);

const saleOrderReturnSlice = createSlice({
  name: "saleOrderReturn",
  initialState: salesOrderReturnAdapter.getInitialState({
    id: null,
    noOfTableRows: 0,
    searchText: "",
    data: null,
    settings: {
      return_reasons: [],
    },
    filter: [],
  }),
  reducers: {
    setSalesOrderReturnFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTableRowCount: (state, action) => {
      state.noOfTableRows = action.payload;
    },
    setReturnSettings: (state, action) => {
      state.settings = action.payload;
    },
  },
  extraReducers: {
   [getPendingReturnRequest.fulfilled]: salesOrderReturnAdapter.setAll,
  },
});

export const {
  setTableRowCount,
  setOrderReturn,
  setFromSettings,
  setReturnSettings,
} = saleOrderReturnSlice.actions;

export default saleOrderReturnSlice.reducer;
